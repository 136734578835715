import React from 'react'
import Navigation from '../../components/navigation'
import Footer from '../../components/footer'
import { THEMES } from 'src/constatnts'

import './style.css'
import { Button } from 'src/components/button'
import { useNavigate } from 'react-router-dom'
import PromoBlock from 'src/components/PromoBlock'
import { useTranslation } from 'react-i18next'

export const BehavioralChangePage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <>
      <Navigation style={{ borderBottom: '1px solid #2F2F2F' }} theme={THEMES.dark} />
      <section className='dark heading-section gradient5 brain-bcg'>
        <div className='container'>
          <div className='row mob-column mob-column-center'>
            <div className='column column column-start mob-column-center'>
              {/* <p className='secondary-heading mob-text-center mob-font-14 mob-word-space-0'>Behavioral Change and Employee <br className='hide mob-show' /> Engagement</p> */}
              <p className='secondary-heading mob-text-center mob-font-14 mob-word-space-0'>{t("Behavioral Change and Employee Engagement")}</p>
              <h1 style={{ maxWidth: 390 }} className='mb40 mob-text-center'>{t("Transform your cybersecurity culture")}</h1>
              <p className='mb50 heading-description mob-text-center' style={{ maxWidth: 410 }}>{t("Empower your workforce to become your strongest defense line by fostering a culture of cybersecurity awareness and engagement.")}</p>

              <Button onClick={() => navigate('/pricing')} variant={'gradient-button'} >{t('Get started')} <svg style={{ marginLeft: '20px' }} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill="#E3E3FF" />
              </svg>
              </Button>
            </div>
          </div>
        </div>
      </section>
      <section className='darker pt140 pb150 mob-pb-94'>
        <div className='container1300'>
          <div className='column column-center'>
            <h2 className='mob-font-26 mb140 mob-mb-94 mob-text-center mob-font500'>{t("Reduce human cyber risks in a single platform")}</h2>
          </div>
          <div className='row space-between mob-column'>
            <div className='dark-box br20 column one-of-3 mob-fullwidth padding-40-60 mob-mb-20'>
              <div className='row row-center img-container-bc' style={{ height: '200px' }}>
                <img src="/images/gamification-platform2.png" width={180} alt="" />

              </div>
              <p className='font18'>{t("Gamification platform for engaging cybersecurity training")}</p>
            </div>
            <div className='dark-box br20 column one-of-3 mob-fullwidth padding-40-60 mob-mb-20'>
              <div className='row row-center img-container-bc' style={{ height: '200px' }}>
                <img src="/images/max-employee-involve.png" width={165} alt="" />

              </div>
              <p className='font18'>{t("Maximizes employee involvement for complete training participation")}</p>
            </div>
            <div className='dark-box br20 column one-of-3 mob-fullwidth padding-40-60 mob-mb-20'>
              <div className='row row-center img-container-bc' style={{ height: '200px' }}>
                <img src="/images/org-shield.png" width={148} alt="" />

              </div>
              <p className='font18'>{t("Organizations boosting security culture experience a significant reduction in cyber incidents")}</p>
            </div>
          </div>
        </div>
      </section>


      <section className='darker pt130 mob-pt-70'>
        <div className='container1200'>
          <div className='container1020'>
            <div className='row space-between mob-column pb100'>
              <div className='column one-of-2 mob-fullwidth mart146 mob-mart-0'>
                <p className='font46 mb20 font600 mob-font-26 '>{t("Foster a proactive cybersecurity culture")}</p>
                <p className='font20 mob-font-14 mob-text-center mob-mb-40' style={{ maxWidth: 410 }}>{t("Our programs are designed to foster an active security culture, encouraging employees to take responsibility for their own and the organization's cybersecurity.")}</p>
              </div>
              <div className='img-container-33'>
                <img className='mob-img' src="/images/foster-proactive.png" width={'580'} alt="" />

              </div>
            </div>
          </div>

          <div className='br30 mob-br20 dark-box mb40' style={{
            background: '#141416 radial-gradient(46.08% 102.18% at 74.79% 50%, rgba(212, 89, 255, 0.4) 0%, rgba(109, 74, 255, 0) 69.56%)'
          }}>
            <div className='row row-center mob-column padding-136-120-166 mob-padding-46-43'>
              <div>
                <h3 className='font46 mb20 mob-font-26'>{t("Gamified learning experience")}</h3>
                <p className='font20 mob-font-14'>{t("Our gamified learning experiences make cybersecurity education engaging and fun, improving knowledge retention and fostering positive behavior change.")}</p>
              </div>

              <div className='mob-hide' style={{ margin: '-380px -220px -380px -20px' }}>
                <img className='mob-hide' width="808" src="/images/achievements.png" alt="" />
              </div>
              <div className='hide mob-show' style={{ margin: '-130px 0px -78px' }}>
                <img width="340" src="/images/achievements-mob.png" alt="" />
              </div>

            </div>
          </div>

          <div className='row row-center space-between mob-column-reverse pt120 pb170'>
            <img src="/images/users-list.png" width={'629'} style={{ margin: '0px 40px 0px -10px' }} className='mob-img mob-hide' alt="" />
            <img src="/images/users-list-mob.png" width={375} style={{ margin: '0px -20px 0px -20px', width: 375 }} className='mob-img hide mob-show' alt="" />
            <div className='column column-center one-of-2 mob-fullwidth'>
              <p className='font46 mb20 mob-font-26 mob-text-center'>{t("Personalized learning paths")}</p>
              <p className='font20 mob-font-14 mob-mb-40 mob-text-center mob-mw-285' style={{ maxWidth: 448 }}>{t("CyberLift provides personalized learning paths for each employee, ensuring that each person's unique learning needs are met.")}</p>
            </div>
          </div>


        </div>
      </section>

      <section className='darker pt150 mob-pt-0 pb180 mob-pb-100'>

        <PromoBlock>
          <p className='mb36 sato36 font500 mob-font-24'>{t("An all-new platform.")}<br />
            {t("An all-new world for security teams.")}</p>
        </PromoBlock>
      </section>
      <Footer theme={THEMES.dark} />
    </>
  )
}