import React from 'react'
import Navigation from '../../components/navigation'
import Footer from '../../components/footer'
import { THEMES } from 'src/constatnts'

import './style.css'
import { Button } from 'src/components/button'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const AiPoweredCyberAttackSimulationPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <>
      <Navigation theme={THEMES.dark} />
      <section className='dark heading-section gradient5'>
        <div className='container'>
          <div className='row row-center mob-column-reverse mob-column-center'>
            <div className='column column-start one-of-2 mob-fullwidth mob-column-center' style={{ maxWidth: '470px' }}>
              <p className='secondary-heading mob-text-center mob-font14 mob-word-space-0'>{t('Automated Phishing simulation')}</p>
              {/* <h1 className='mb40 mob-text-center'>Make employees <br className='hide mob-show' /> Smarter than Attackers</h1> */}
              <h1 className='mb40 mob-text-center'>{t('Make employees Smarter than Attackers')}</h1>
              <p className='mb50 heading-description mob-text-center' style={{ maxWidth: '400px' }}>{t('The smartest way to secure your organization from social engineering attacks is to train employees with real life exercises powered with behavioral science and AI.')}</p>
              {/* <p className='mb50 heading-description mob-text-center' style={{ maxWidth: '400px' }}>The smartest way to secure your organization from social engineering attacks is to train employees with real life exercises powered<br className='hide mob-show' /> with behavioral science and AI.</p> */}

              <Button onClick={() => navigate('/pricing')} variant={'gradient-button'} >{t('Request a demo')} <svg style={{ marginLeft: '20px' }} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill="#E3E3FF" />
              </svg>
              </Button>
            </div>
            <img className='mob-hide' style={{ margin: '-150px -116px -110px -23px' }} src="/images/ai-powered-sim.png" width="717" alt="" />
            <img className='hide mob-show' style={{ marginTop: '-80px' }} src="/images/mob-ai-powered-sim.png" width="375" alt="" />
          </div>
        </div>
      </section>


      <section className='darker'>
        <div className='container'>
          <div className='column column-center pt143 mob-pt-90 mb100'>
            <h2 className='text-center'>{t('93% of all cyber incidents start with an email')}</h2>

          </div>
          <div className='row  mob-column space-between pb143'>
            <div className='dark-box br20 column one-of-3 mob-fullwidth padding-71-36 column-center box-shadow mob-mb-20'>
              <div style={{ height: '128px' }} className='row '>
                <svg className='mb50' width="99" height="78" viewBox="0 0 99 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 15L43.4563 41.3023C46.8153 43.5415 51.1912 43.5415 54.5502 41.3023L94.0065 15M14.0003 74H84.0061C89.5292 74 94.0065 69.5227 94.0065 63.9996V14.0004C94.0065 8.47731 89.5292 4 84.0062 4H14.0004C8.47731 4 4 8.47732 4 14.0004V63.9996C4 69.5227 8.4773 74 14.0003 74Z" stroke="url(#paint0_linear_3923_6126)" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
                  <defs>
                    <linearGradient id="paint0_linear_3923_6126" x1="-13" y1="-21" x2="143.5" y2="102" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#6D4AFF" />
                      <stop offset="0.586414" stopColor="#D459FF" />
                      <stop offset="1" stopColor="#FA145F" />
                    </linearGradient>
                  </defs>
                </svg>

              </div>
              <p className='mb20 font25-500 text-center mob-font22 mob-font500'>{t('Spam doesn’t work')}</p>
              <p className='font16-400-center mob-font14 mob-padding-0' style={{ padding: '0px 26px' }}>{t('Phishing attacks employ advanced tactics, encryption, and personalization, making it difficult for traditional spam filters to effectively detect and block them.')}</p>

            </div>
            <div className='dark-box br20 column one-of-3 mob-fullwidth padding-71-36 column-center box-shadow mob-mb-20'>
              <div style={{ height: '128px' }} className='row '>

                <p className='big-num gradient-text'>6 sec.</p>
              </div>
              <p className='mb20 font25-500 text-center mob-font22 mob-font500'>{t('Frequent')}</p>
              <p className='font16-400-center mob-font14 mob-padding-0' style={{ padding: '0px 23px' }}>{t('Each 6 second business faces an email based attack. It takes an average of 308 days to identify and contain a phishing.')}</p>

            </div>
            <div className='dark-box br20 column one-of-3 mob-fullwidth padding-71-36 column-center box-shadow'>
              <div style={{ height: '128px' }} className='row '>

                <p className='big-num gradient-text'>$4,3 M</p>
              </div>
              <p className='mb20 font25-500 text-center mob-font22 mob-font500'>{t('Costly')}</p>
              <p className='font16-400-center mob-font14 mob-padding-0' style={{ padding: '0px 13px' }}>{t('The average business email attack costs organizations $4,3M and reputational damages.')}</p>

            </div>
          </div>

        </div>
      </section>
      <section className='darker'>
        <div className='container860 column column-center'>
          {/* <h2 className='text-center mb40'>Cyberlift trains employees with email attacks <br className='mob-hide' /> simulations</h2> */}
          <h2 className='text-center mb40'>{t('Cyberlift trains employees with email attacks simulations')}</h2>
          <p className='text-center font22 mb100 mob-font14' style={{ maxWidth: 800 }}>{t('Real-life exercises enable individual learning path to identify & report real attacks.')}</p>

        </div>
        <div className='container1300'>
          <div style={{ background: '#141417 radial-gradient(50% 50% at 50% 50%, rgba(159, 136, 255, 0.12) 0%, rgba(238, 238, 255, 0) 100%)' }} className='br30 content-box mb40 mob-mb-20 padding-86-90 mob-padding-46-43'>
            <div className='row mob-column'>
              <div className='column'>
                <p className='mb80 mob-mb-40 promo-heading mob-font12'>{t('Evaluate the vulnerability with anonymous audit')}</p>
                <p className='font22-500 mob-font14-500 ml20'>{t('Cyberlift sends first simulations anonymously within the organization, providing a comprehensive evaluation of each individual`s strengths and weaknesses. Discover how your team will react facing a phishing attack.')}</p>
              </div>
              <div className='img-container-10'>
                <img width="649" src="/images/cards.png" className='mob-img mob-hide' alt="" />
                <img width="340" src="/images/cards-mob.png" className='mob-img hide mob-show' alt="" />
              </div>
            </div>
          </div>

          <div className='row mob-column space-between mb40 mob-mb-20'>
            <div className='br30 mob-mb-20 dark-box-gradient box-shadow one-of-2 mob-fullwidth padding-86-90 mob-padding-46-43'>
              <p className='promo-heading mob-font12 mb40'>{t('100% automated simulations')}</p>
              <div className='img-container-20'>

                <img className='mob-img' src="/images/automated-sims.png" width={454} alt="" />
              </div>
              <p className='font22-500 mob-font14-500'>{t('Boost security awareness in to next level with fully automation. Cyberlift launches automatically personalized simulation for each inidividual employee. Training individuals, not groups Cyberlift automatically selects type of attack based on weakest risks.')}</p>
            </div>

            <div className='br30 dark-box box-shadow one-of-2 mob-fullwidth padding-86-90 mob-padding-46-43'>
              <p className='promo-heading mob-font12 mb40'>{t('personalized for each person, not group')}</p>
              <div className='column column-center'>

                <div className='img-container-21'>

                  <img src="/images/users-list-2.png" className='mb80 mob-hide' width={545} alt="" />
                  <img src="/images/users-list-2-mob.png" className='mb80 mob-img hide mob-show mob-mb-20' style={{ width: '100%', maxWidth: '375px' }} alt="" />
                </div>
              </div>
              <p className='font22-500 mob-font14-500'>{t('Our model designs & launches automatically individualized training plans, based on their evaluation results, location, department, role, language and many other factors. This level of personalization ensures that each employee receives the most relevant and effective training')}</p>
            </div>

          </div>

          <div style={{ background: '#141417' }} className='br30 content-box mb40 mob-mb-10'>
            <div className='row mob-column padding-86-90 mob-padding-46-43'>
              <div className='column'>
                <p className='mb80 promo-heading mob-font12 mob-mb-30'>{t('Localized templates')}</p>
                <p className='font22-500 mob-font14-500 ml20' style={{ maxWidth: 570 }}>{t('Because attackers use AI/ML technologies to launch targeted attacks, Cyberlift AI designed several steps ahead. Cyberlift in real-time analyzes all new incidents in different regions and automatically adds new scenarios to the training database to stay educated with all current trends.')}</p>
              </div>
              <div className='img-container-11'>
                <img width="417" className='mob-img' src="/images/earth.png" alt="" />
              </div>
            </div>
          </div>

          <div style={{ background: '#1D1D29 radial-gradient(50% 50% at 71% 44%, rgba(159, 136, 255, 0.12) 0%, rgba(238, 238, 255, 0) 100%)' }} className='br30 content-box mb40 mob-mb-20'>
            <div className='row mob-column padding-86-90 mob-padding-46-43'>
              <div className='column'>
                <p className='mb80 promo-heading mob-font12 mob-mb-30'>{t('educate with bite-sized microtrainings')}</p>
                <p className='font22-500 mob-font14-500 mob-mb-20 ml20' style={{ maxWidth: 520 }}>{t('Cyberlift enhances individual capabilities through prompt micro-training sessions tailored to real-time responses, promoting sustained engagement and optimal email practices. Microtrainings boost security awareness faster than other types of content.')}</p>
              </div>
              <div className='img-container-12'>
                <img width="499" className='mob-img' src="/images/modals.png" alt="" />
              </div>
            </div>
          </div>


          <div className='row mob-column space-between mb'>
            <div className='mb40 br30 one-of-2 mob-fullwidth padding-86-90 mob-padding-46-43 mob-mb-20 mob-pr-0 dark-box box-shadow'>
              <p className='promo-heading mb40 mob-font12 mob-mb-30'>{t('adaptive learning path')}</p>
              <img src="" alt="" />
              <p className='gradient-text font42-600 mob-font-28' style={{ maxWidth: 400 }}>{t('Cyberlift core system integrates behavioral science principles into creation and implementation of personalized simulations based on risk.')}</p>
            </div>

            <div className='mb40 br30 one-of-2 mob-fullwidth padding-86-90 mob-padding-46-43 dark-box box-shadow'>
              <div className='img-container-9'>
                <img src="/images/adaptive-learning.png" width={'514'} className='mob-img' alt="" />

              </div>
              <p className='font20-500 mob-font-14 '>{t('Our method is based on Adaptive learning and Motivation, where system dynamically adjust the difficulty and complexity of simulations based on each user`s skill level and progress. This adaptive learning approach ensures that users are consistently challenged, helping them improve their security awareness and response to phishing attacks faster than ever before.')}</p>
            </div>

          </div>


        </div>
      </section>
      <section className='darker pt180 mob-pt-60'>
        <div className='container1300 column column-center'>
          <h2 className='text-center mb40'>{t('Improve the reporting rate from day one')}</h2>
          {/* <h2 className='text-center mb40'>Improve the reporting<br className='hide mob-show' /> rate from day one</h2> */}
          <p className='text-center font22 mb80 mob-mb-40 mob-font14' style={{ maxWidth: 860 }}>{t('Reporting rate is the most important and fundamental metric. When technologies fail-people should react. Cyberlift attack simulations improve effectively the way how people fast can identify and report threats inside their inboxes.')}</p>
          {/* <p className='text-center font22 mb80 mob-mb-40 mob-font14' style={{ maxWidth: 860 }}>Reporting rate is the most important and fundamental metric. When technologies<br className='hide mob-show' /> fail- people should react. Cyberlift attack simulations improve effectively the way<br className='hide mob-show' /> how people fast can identify and report<br className='hide mob-show' /> threats inside their inboxes.</p> */}

          <p className='text-center mb60'>
            <img src="/images/reporting-rate.png" className='mob-img' width="1020" alt="" />
          </p>

          <div className='br30 column column-center padding-67-90 mob-padding-46-26 mb150 mob-mb100' style={{ background: '#6D4AFF', color: '#fff', width: '100%', }}>
            <div className='container860 mob-fullwidth mob-padding-0'>
              <p className='text-center sato34 mob-font-24 mb30'>{t('Start boosting your security!')}</p>
              <p className='font16-400 mob-font14 mb60 text-center'>{t('Move beyond traditional phishing tests and manual tools. Because phishing is an ever-changing threat, educating your team can’t be a one-time exercise.')}</p>

            </div>

            <Button onClick={() => navigate('/pricing')} variant={'black-button'}>{t('Request a demo')} <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill="white" />
            </svg>
            </Button>
          </div>
        </div>
      </section>
      <Footer theme={THEMES.dark} />
    </>
  )
}