import React, { useEffect } from 'react';

import { Navigate, Route, Routes, useLocation, withRouter } from 'react-router-dom';
import './App.css';

import { HomePage } from './pages/home';
import { PricingPage } from './pages/pricing';
import { ROUTES } from './components/navigation/nav';
import { AiPoweredCyberAttackSimulationPage } from './pages/aiPoweredCyberAttackSimulation';
import { SecurityAwarenessTrainingsPage } from './pages/securityAwarenessTrainings';
import { EmailThreatReportButtonPage } from './pages/emailThreatReportButton';
import { IntelligentThreatResponsePage } from './pages/intelligentThreatResponse';
import { GamificationPlatformPage } from './pages/gamificationPlatform';
import { SocialEngineeringPreventionPage } from './pages/socialEngineeringPrevention';
import { BehavioralChangePage } from './pages/behavioralChange';
import { ComprehensiveCybersecurityPage } from './pages/comprehensiveCybersecurity';
import { ProactiveRiskAssessmentPage } from './pages/proactiveRiskAssessment';
import { GlobalWorkforceDefensePage } from './pages/globalWorkforceDefense';
import { AchieveAndMaintainRobustPage } from './pages/achieveAndMaintainRobust';
import { WhyCyberliftPage } from './pages/why-cyberlift';
import { AboutPage } from './pages/about';
import { TermsPage } from './pages/terms';
import { LandingPage } from './pages/landing';
import { PrivacyPage } from './pages/privacy-policy';
import { DataProcessingAgreementPage } from './pages/dpa';

import './i18n'

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// export default withRouter(ScrollToTop);


function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route index element={<HomePage />} />
        <Route path='/pricing' element={<PricingPage />} />

        <Route path={ROUTES.aiPoweredCyberAttackSimulation} element={<AiPoweredCyberAttackSimulationPage />} />
        <Route path={ROUTES.securityAwarenessTrainings} element={<SecurityAwarenessTrainingsPage />} />
        <Route path={ROUTES.emailThreatReportButton} element={<EmailThreatReportButtonPage />} />
        <Route path={ROUTES.intelligentThreatResponse} element={<IntelligentThreatResponsePage />} />
        <Route path={ROUTES.gamificationPlatform} element={<GamificationPlatformPage />} />
        <Route path={ROUTES.socialEngineeringPrevention} element={<SocialEngineeringPreventionPage />} />
        <Route path={ROUTES.behavioralChange} element={<BehavioralChangePage />} />
        <Route path={ROUTES.comprehensiveCybersecurity} element={<ComprehensiveCybersecurityPage />} />
        <Route path={ROUTES.proactiveRiskAssessment} element={<ProactiveRiskAssessmentPage />} />
        <Route path={ROUTES.globalWorkforceDefense} element={<GlobalWorkforceDefensePage />} />
        <Route path={ROUTES.appfraud} element={<LandingPage />} />
        <Route path={ROUTES.achieveAndMaintainRobust} element={<AchieveAndMaintainRobustPage />} />

        <Route path={ROUTES.whyCyberlift} element={<WhyCyberliftPage />} />
        <Route path={ROUTES.aboutUs} element={<AboutPage />} />

        <Route path={ROUTES.terms} element={<TermsPage />} />
        <Route path={ROUTES.privacyPolicy} element={<PrivacyPage />} />
        <Route path={ROUTES.dpa} element={<DataProcessingAgreementPage />} />

        {/* <Route path={ROUTES.whyCyberlift} element={<PricingPage />} />
        <Route path={ROUTES.aiPoweredPlatform} element={<PricingPage />} />
        <Route path={ROUTES.cyberliftVsCompetitors} element={<PricingPage />} />
        <Route path={ROUTES.aboutUs} element={<PricingPage />} />
        <Route path={ROUTES.newsroom} element={<PricingPage />} />
        <Route path={ROUTES.partners} element={<PricingPage />} />
        <Route path={ROUTES.careers} element={<PricingPage />} />
        <Route path={ROUTES.securityCompliance} element={<PricingPage />} />
        <Route path={ROUTES.technologies} element={<PricingPage />} />
        <Route path={ROUTES.contacts} element={<PricingPage />} />
        <Route path={ROUTES.privacyPolicy} element={<PricingPage />} />
        <Route path={ROUTES.termsOfUse} element={<PricingPage />} /> */}


        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}

export default App;
