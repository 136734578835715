import React from 'react'
import Navigation from '../../components/navigation'
import Footer from '../../components/footer'
import { THEMES } from 'src/constatnts'

import './style.css'
import Divider from 'src/components/Divider'
import { useTranslation } from 'react-i18next'

const Positive = () => (
  <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.92969 11.1429L8.81978 16.5L21.2135 1.5" stroke="#1ECD1A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
const Negative = () => (
  <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.74219 7.25439L20.2422 20.7544" stroke="#FF0053" strokeWidth="2" strokeLinecap="round" />
    <path d="M6.74219 20.7456L20.2422 7.24561" stroke="#FF0053" strokeWidth="2" strokeLinecap="round" />
  </svg>

)

const getCompareData = (t) => ([
  { name: t('Fully Automated Creation and Sending of Simulations'), cyberlift: true, others: false },
  { name: t('Social engineering simulations'), cyberlift: true, others: false },
  { name: t('Customized approach per each individual user'), cyberlift: true, others: false },
  { name: t('AI-powered voice phishing simulations'), cyberlift: true, others: false },
  { name: t('Global coverage with multilingual content'), cyberlift: true, others: true },
  { name: t('Trainings modules for Various Security Certifications(ISO / IEC 27001, SOC 2, HIPAA, GDPR, PCI DSS)'), cyberlift: true, others: true },
  { name: t('AI-powered security co-pilot for social engineering analysis'), cyberlift: true, others: false },
  { name: t('Automated Threat Risk Evaluation and Escalation'), cyberlift: true, others: true },
  { name: t('Gamification platform for users'), cyberlift: true, others: false },
  { name: t('Rewards & achievements'), cyberlift: true, others: true },
  { name: t('Easy LDAP Integration for Automatic Team Synchronization'), cyberlift: true, others: true },
  { name: t('Reporting Employee Performance Progress to Relevant Team Members'), cyberlift: true, others: true },
  { name: t('Dedicated API for reported threats management'), cyberlift: true, others: false },
  { name: t('Dynamic rules set-up for trainings packages'), cyberlift: true, others: false },
  { name: t('Seamless Adaptation to Company’s security policy'), cyberlift: true, others: false },
])




export const WhyCyberliftPage = () => {
  const { t } = useTranslation()

  const compareData = getCompareData(t)
  return (
    <>
      <Navigation theme={THEMES.dark} />
      <section className='darker heading-section'>
        <div className='container860'>
          {/* <h1 className='text-center mb40 mob-font700'>Switch to Cyberlift<br className='hide mob-show' /> today</h1> */}
          <h1 className='text-center mb40 mob-font700'>{t("Switch to Cyberlift today")}</h1>
          <p className='text-center mb20 mb100 font18 mob-font-16'>{t("Cyberlift offers more than just a security platform. It's a game-changing combination of advanced AI, deep learning, and human behavior change solutions.")}</p>
          {/* <p className='text-center mb100'>With thousands of realistic social engineering scenarios and machine learning threats analysis, 7+ seamless integrations, 10+ languages, and 24/7 support, Cyberlift fortifies your global operations, providing unmatched cybersecurity compliance and potency.</p> */}
        </div>

        <div className='container1300'>
          <div className='dark-box box-shadow br30 mb-br20 padding-90-100 mob-padding-30-20'>
            <div className='column'>
              <div className='row row-center'>
                <div style={{ width: '60%' }}><p className='table-header'>Features</p></div>
                <div style={{ width: '20%' }}><p className='table-header text-center column column-center'>{t('Cyberlift')}</p></div>
                <div style={{ width: '20%' }}><p className='table-header text-center column column-center'>{t('Others')}</p></div>
              </div>
              <Divider style={{ background: '#2C2C36', marginTop: '17px', marginBottom: '17px' }} />

              {compareData.map((el, index) => (
                <div key={el.name.replaceAll(' ', '')} >
                  <div className='row row-center table-element'>
                    <div style={{ width: '60%' }}><p className='table-text'>{el.name}</p></div>
                    <div style={{ width: '20%' }}><p className='column column-center'><Positive /></p></div>
                    <div style={{ width: '20%' }}><p className='column column-center'>{el.others ? <Positive /> : <Negative />}</p></div>
                  </div>
                  {
                    index !== compareData?.length - 1 &&
                    <Divider style={{ background: '#2C2C36', marginTop: '17px', marginBottom: '17px' }} />
                  }
                </div>

              ))}


            </div>
          </div>

        </div>
      </section>
      <Footer theme={THEMES.dark} />
    </>
  )
}
