export const pl = {
  "Welcome": "Witaj",


  'Products': 'Produkty',
  'Solutions': 'Rozwiązania',
  'Why Cyberlift': 'Dlaczego Cyberlift?',
  'Pricing': 'Ceny',

  'AI-powered cyber attack simulation': 'Symulacja ataków cybernetycznych zasilanych sztuczną inteligencją',
  'Make employees Smarter than Attackers': 'Spraw, by Twoi pracownicy byli sprytniejsi od hakerów!',
  'Security awareness trainings': 'Szkolenie w zakresie świadomości bezpieczeństwa',
  'Hundreds of security awareness trainings in one place': 'Setki szkoleń z bezpieczeństwa w jednym miejscu',
  'Email threat report button': 'Przycisk zgłaszania zagrożeń e-mailowych',
  'Introducing Phishing email reporting button': 'Alert phishingowy',
  'Intelligent threat response': 'Inteligentna odpowiedź na zagrożenia',
  'Gamification platform': 'Platforma gamifikacji',
  'Engage employees in security with a gamification experience': 'Zaangażuj pracowników w temat bezpieczeństwa dzięki gamifikacji',

  'Social engineering prevention': 'Zapobieganie inżynierii społecznej',
  'Shield your organization from social engineering attacks': 'Chroń swoją firmę przed atakami socjotechnicznymi',
  'Behavioral change and employee engagement': 'Zmiana zachowania i zaangażowanie pracowników',
  'Transform your cybersecurity culture': 'Przekształć swoją kulturę cyberbezpieczeństwa',
  'Comprehensive cybersecurity education': 'Kompleksowe szkolenie z cyberbezpieczeństwa',
  'Stay ahead with comprehensive cybersecurity education': 'Bądź na bieżąco dzięki kompleksowemu szkoleniu z cyberbezpieczeństwa',
  'Proactive risk assessment and management': 'Proaktywna ocena i zarządzanie ryzykiem',
  'Anticipate and mitigate cyber risks before they become threats': 'Przewiduj i minimalizuj ryzyka cybernetyczne zanim staną się zagrożeniami',
  'Global workforce defense': 'Ochrona globalnej siły roboczej',
  'Defend your global workforce from cyber threats': 'Chroń swoją globalną siłę roboczą przed atakami cybernetycznymi',
  'Achieve and maintain robust compliance with ISO 27001, PCI, HIPPA, GDPR, SOC 2, NIS 2 regulations': 'Osiągnij i utrzymaj zgodność z regulacjami ISO 27001, PCI, HIPPA, GDPR, SOC 2 oraz NIS 2',

  'Sign in': 'Zaloguj się',
  'Sign up': 'Zarejestruj się',

  'Company': 'Firma',
  'About us': 'O nas',
  'Contacts': 'Kontakt',
  'Privacy policy': 'Polityka prywatności',
  'Terms of Use': 'Warunki użytkowania',
  'Data Processing Agreement': 'Umowa przetwarzania danych',

  'Security Awareness Trainings': 'Szkolenie z zakresu Świadomości Bezpieczeństwa',
  'AI Assistant for Security Awareness': 'Asystent AI',
  'Email Threat Report Button': 'Przycisk zgłaszania potencjalnie niebezpiecznych wiadomości e-mail',
  'Intelligent Threat Response': 'Inteligentna odpowiedź na zagrożenia',

  'Get started': 'Rozpocznij',
  'Learn More': 'Dowiedz się więcej',
  'Request a demo': 'Poproś o demo',

  home: {
    title: 'Minimalizuj Ryzyko Cybernetyczne związane z człowiekiem',
    subtitle: 'Cyberlift to zautomatyzowana platforma zarządzania ryzykiem ludzkim, która wykorzystuje sztuczną inteligencję i nauki behawioralne do zwalczania ataków socjotechnicznych.',
    setction2title: 'Cyberlift pomoże Ci walczyć z rosnącym krajobrazem zagrożeń',
    setction2text1: 'incydentów inicjowanych jest przez email',
    setction2text2: 'wycieku danych związanych jest z błędem ludzkim',
    setction2text3: 'roczny wzrost cyberataków z zastosowaniem sztucznej inteligencji',
    setction2text4: 'dolarów to średni koszt jednorazowego wycieku danych',

    section3title: 'Tradycyjne szkolenie ze świadomości bezpieczeństwa nie spełnia już swojej roli. Dzięki zastosowaniu Cyberlift, Twój zespół ds. bezpieczeństwa jest bogatszy o nowe spostrzeżenia oparte na sztucznej inteligencji, co umożliwia przewidywanie i ograniczanie ryzyka na poziomie indywidualnym.',


    'Access to sensitive systems': 'Dostęp do wrażliwych systemów',
    'Analyzing Adam co-workers': 'Analiza współpracowników Adama',
    'Matchmaking phishing trends to personalize simulations': 'Dopasowanie trendów phishingowych w celu personalizacji symulacji',
    'Launching anonymous simulations for audit': 'Uruchamianie anonimowych symulacji na potrzeby audytu',
    'Detect Adam vulnerability patterns, risks #passwords, #servers, #bitbucket': 'Wykrywanie wzorców podatności Adama, ryzyka #hasła, #serwery, #bitbucket',
    'spoofing simulation test #bitbucket, #passwords, #co-worker request': 'test symulacji spoofingu #bitbucket, #hasła, #prośba współpracownika',
    'Intention to report simulation': 'Intencja zgłoszenia symulacji',
    'Microtraining engagement': 'Zaangażowanie w mikro trening',
    'Knowledge test': 'Test wiedzy',

  },
  'Engineering team, Australia remote': 'Zespół inżynierów, zdalnie z Australii',
  'Automated and personalized attack simulation': 'Zautomatyzowana i spersonalizowana symulacja ataku',
  'Our AI generates multi-step simulation scenarios and templates, delivering targeted attacks to each user via email, Slack, or Teams, effectively reducing individual human risk.': 'Nasz algorytm oparty na sztucznej inteligencji generuje wieloetapowe scenariusze symulacji i szablony, wysyłając ukierunkowane ataki każdemu użytkownikowi za pośrednictwem poczty e-mail, Slack lub Teams, co skutecznie zmniejsza indywidualne ryzyko ludzkie.',
  'Cyberlift\'s AI assistant creates unique, multilingual training content tailored to the specific context of your organization and employees.It automatically identifies training needs, delivers training via Slack/Teams, and helps reduce risks.': 'Setki modułów szkoleniach skrojonych na miarę Twojej firmy i pracowników.Zarządzaj zespołami, twórz grupy i otrzymuj raporty',
  'GDPR, NIS2, ISO27001, HIPAA, SOC2, PCI DSS.': 'GDPR, NIS2, ISO27001, HIPAA, SOC2, PCI DSS',
  'Email threat reporting button': 'Przycisk zgłaszania zagrożeń e-mailowych',
  'Boost employee reporting rates with the Cyberlift plugin button in Outlook and Gmail. This AI assistant actively displays phishing indicators for real threats, enabling prompt identification and reporting for further investigation.': 'Zwiększ wskaźniki zgłaszania zagrożeń przez pracowników dzięki wtyczce Cyberlift w Outlooku i Gmailu. Ten asystent AI aktywnie wyświetla wskaźniki phishingu dla rzeczywistych zagrożeń, umożliwiając ich szybkie rozpoznanie i zgłoszenie do dalszego zbadania.',
  'Get faster view of reported threats and resolve them automatically. Identify and manage human-related threats through already made integrations with other working and security tools.': 'Uzyskaj szybszy podgląd zgłoszonych zagrożeń i rozwiąż je automatycznie.  Wykrywaj i zarządzaj zagrożeniami związanymi z czynnikiem ludzkim poprzez integracje z innymi narzędziami roboczymi i zabezpieczającymi.',
  'Gamification platform for employees': 'Platforma gamifikacji dla Twoich pracowników',
  'Supercharge your employees with a gamification platform that changes human behavior through an interactive security journey.': 'Wykorzystaj platformę gamifikacji, która zmienia ludzkie zachowanie poprzez interaktywną podróż w dziedzinie bezpieczeństwa.',
  'Combining human intelligence and training performance, Cyberlift empowers security teams with data that helps to predict and prevent cyber incidents.': 'Łącząc inteligencję ludzką i wydajność szkoleń, Cyberlift zapewnia zespołom ds. bezpieczeństwa dane, które pomagają przewidywać i zapobiegać incydentom cybernetycznym.',

  'Overall company': 'Ogólny wynik firmy',
  'Human Risk Score': 'Wskaźnik ryzyka ludzkiego',
  'Risk Category': 'Kategoria ryzyka',
  'Moderate risk': 'Ryzyko umiarkowane',
  'Risk Rating By Dept': 'Wskaźnik ryzyka według działów',

  'Transform your employees into Human firewall today': 'Zamień swoich pracowników w ludzką zaporę już dziś',
  'Get started with Cyberlift.': 'Rozpocznij z Cyberlift',

  'Automated Phishing simulation': 'Zautomatyzowana symulacja phishingowa',
  'The smartest way to secure your organization from social engineering attacks is to train employees with real life exercises powered with behavioral science and AI.': 'Najmądrzejszym sposobem zabezpieczenia swojej organizacji przed atakami socjotechnicznymi jest szkolenie pracowników przy użyciu realistycznych ćwiczeń opartych na naukach behawioralnych i sztucznej inteligencji.',
  '93% of all cyber incidents start with an email': '93% incydentów cybernetycznych zaczyna się od e-maila',
  'Spam doesn’t work': 'Spam nie działa',
  'Phishing attacks employ advanced tactics, encryption, and personalization, making it difficult for traditional spam filters to effectively detect and block them.': 'Ataki phishingowe wykorzystują zaawansowane taktyki, szyfrowanie i personalizację, co utrudnia tradycyjnym filtrom spamowym skuteczne wykrywanie i blokowanie tych ataków.',
  'Frequent': 'Często',
  'Each 6 second business faces an email based attack. It takes an average of 308 days to identify and contain a phishing.': 'Co 6 sekund każda firma staje w obliczu ataku opartego na wiadomości e-mail. Zidentyfikowanie i powstrzymanie ataku phishingowego zajmuje około 308 dni.',
  'Costly': 'Kosztowne',
  'The average business email attack costs organizations $4,3M and reputational damages.': 'Średni koszt ataku e-mailowego na firmę wynosi 4,3 mln USD oraz szkody reputacyjne.',
  'Cyberlift trains employees with email attacks simulations': 'Cyberlift szkoli pracowników przy użyciu symulacji ataków e-mailowych',
  'Real-life exercises enable individual learning path to identify & report real attacks.': 'Realistyczne ćwiczenia umożliwiają indywidualną ścieżkę nauki w celu identyfikacji i zgłaszania rzeczywistych ataków.',
  'Evaluate the vulnerability with anonymous audit': 'OCENA PODATNOŚCI PRZY POMOCY ANONIMOWEGO AUDYTU',
  'Cyberlift sends first simulations anonymously within the organization, providing a comprehensive evaluation of each individual`s strengths and weaknesses. Discover how your team will react facing a phishing attack.': 'Cyberlift wysyła pierwsze symulacje anonimowo w ramach firmy, dostarczając kompleksową ocenę mocnych i słabych stron każdego pracownika. Odkryj, jak Twój zespół zareaguje w obliczu ataku phishingowego.',
  '100% automated simulations': 'SYMULACJE ZAUTOMATYZOWANE W 100%',
  'Boost security awareness in to next level with fully automation. Cyberlift launches automatically personalized simulation for each inidividual employee. Training individuals, not groups Cyberlift automatically selects type of attack based on weakest risks.': 'Wynieś świadomość bezpieczeństwa na wyższy poziom dzięki pełnej automatyzacji. Cyberlift automatycznie uruchamia spersonalizowane symulacje dla każdego pracownika. Szkolenie są indywidualne, nie grupowe. Cyberlift automatycznie wybiera typ ataku w oparciu o najsłabsze ogniwo.',
  'personalized for each person, not group': 'PERSONALIZOWANIE DLA KAŻDEJ OSOBY, NIE GRUPY',
  'Our model designs & launches automatically individualized training plans, based on their evaluation results, location, department, role, language and many other factors. This level of personalization ensures that each employee receives the most relevant and effective training': 'Nasz model automatycznie projektuje i uruchamia spersonalizowane plany szkoleń oparte na wynikach oceny, lokalizacji, dziale, roli, języku i wielu innych czynnikach. Taki poziom personalizacji zapewnia, że każdy pracownik otrzymuje najbardziej odpowiednie i skuteczne szkolenia.',
  'localized templates': 'SZABLONY',
  'Because attackers use AI/ML technologies to launch targeted attacks, Cyberlift AI designed several steps ahead. Cyberlift in real-time analyzes all new incidents in different regions and automatically adds new scenarios to the training database to stay educated with all current trends.': 'Ponieważ atakujący używają technologii AI/ML do przeprowadzania ukierunkowanych ataków, algorytm Cyberlift oparty na sztucznej inteligencji jest zawsze o kilka kroków do przodu. Cyberlift w czasie rzeczywistym analizuje wszystkie nowe incydenty w różnych obszarach i automatycznie dodaje nowe scenariusze do bazy danych szkoleń, aby być na bieżąco ze wszystkimi aktualnymi trendami.',
  'educate with bite-sized microtrainings': 'ZDOBYWAJ WIEDZĘ ZA POMOCĄ MIKROSZKOLEŃ',
  'Cyberlift enhances individual capabilities through prompt micro-training sessions tailored to real-time responses, promoting sustained engagement and optimal email practices. Microtrainings boost security awareness faster than other types of content.': 'Z Cyberlift możesz zwiększyć swoje umiejętności dzięki szybkim mikroszkoleniom dostosowanym do reakcji w czasie rzeczywistym. Mikroszkolenia promują trwałe zaangażowanie i optymalne praktyki e-mailowe. Mikroszkolenia podnoszą świadomość bezpieczeństwa w cyberprzestrzeni szybciej niż inne treści.',
  'adaptive learning path': 'ADAPTACYJNA ŚCIEŻKA NAUKI',
  'Cyberlift core system integrates behavioral science principles into creation and implementation of personalized simulations based on risk.': 'Podstawą systemu Cyberlift jest integracja nauk behawioralnych w tworzeniu i wdrażaniu spersonalizowanych symulacji ryzyka.',
  'Our method is based on Adaptive learning and Motivation, where system dynamically adjust the difficulty and complexity of simulations based on each user`s skill level and progress. This adaptive learning approach ensures that users are consistently challenged, helping them improve their security awareness and response to phishing attacks faster than ever before.': 'Nasza metoda opiera się nauczaniu adaptacyjnym i motywacji, polegającym na dynamicznym dopasowaniu trudności i złożoności symulacji do poziomu umiejętności i postępów każdego użytkownika. Taka metoda nauczania sprawia, że użytkownicy stale mierzą się z wyzwaniami, co pomaga zwiększyć ich świadomość bezpieczeństwa i wcześniej reagować na ataki phishingowe.',
  'Improve the reporting rate from day one': 'Poprawa wskaźnika raportowania od pierwszego dnia',
  'Reporting rate is the most important and fundamental metric. When technologies fail-people should react. Cyberlift attack simulations improve effectively the way how people fast can identify and report threats inside their inboxes.': 'Wskaźnik raportowania jest najważniejszym i podstawowym wskaźnikiem. Kiedy technologie zawodzą-ludzie powinni reagować. Dzięki symulacjom ataków skontruowanych przez Cyberlift, użytkownicy szybciej identyfikują i zgłaszają zagrożenia pochodzące z wiadomości e-mailowych.',
  'Start boosting your security!': 'Podnieś swoje bezpieczeństwo!',
  'Move beyond traditional phishing tests and manual tools. Because phishing is an ever-changing threat, educating your team can’t be a one-time exercise.': 'Zapomnij o tradycyjnych testach phishingowych i manualnych narzędziach! Z uwagi na to, że krajobraz zagrożeń phishingowych ciągle się zmienia, szkolenia zespołu w dziedzinie cyberbezpieczeństwa powinny odbywać się regularnie.',

  'Essential content platform covering all security aspects': 'Niezbędna platforma treści obejmująca wszystkie aspekty bezpieczeństwa',

  'Security content library': 'Biblioteka treści dotyczących bezpieczeństwa',
  'Tons of security topics that cover all your bases.  Privacy, Device security, Working remotely, Malware.': 'Mnóstwo tematów z dziedziny bezpieczeństwa. Prywatność, bezpieczeństwo urządzeń, praca zdalna, złośliwe oprogramowanie.',
  'Compliance content library': 'Biblioteka treści dotyczących zgodności',
  'Automated security awareness tailored to your compliance needs.': 'Zautomatyzowana świadomość bezpieczeństwa skrojona na miarę Twoich potrzeb w zakresie zgodności.',
























  "Here’s how simple it works": "Zobacz jakie to proste",
  "Explore and prioritize topics": "Przeglądaj i szereguj obszary.",
  "Set up the most relevant awareness topics by your rules. Courses customized for each individual employee and sent during all work periods.": "Skonfiguruj najbardziej odpowiednie kwestie na temat świadomości bezpieczeństwa według własnych zasad. Kursy są dostosowane do każdego pracownika i wysyłane w trakcie całego okresu pracy.",
  "Create in seconds training packages": "Twórz pakiety szkoleniowe w kilka sekund",
  "Choose training modules, set up rules and launch trainings across all your company departments.": "Wybierz moduły szkoleniowe, ustal reguły i uruchom szkolenia we wszystkich działach Twojej firmy.",
  "Track the progress with smart reporting": "Śledź postępy za pomocą inteligentnego raportowania",
  "Follow in real time employees performance. Know which business unit perform better across your organization with our data driven reporting.": "Śledź wyniki pracowników w czasie rzeczywistym. Dowiedz się, który dział osiąga lepsze wyniki za pomocą naszego raportowania opartego na danych.",
  "Reasons to use Cyberlift for your trainings": "Dlaczego warto korzystać z Cyberlift w swoich szkoleniach ?",
  "Automation": "Automatyzacja",
  "Trainings are delivered automatically based on your preferences and needs of employee lifetime.": "Szkolenia są dostarczane automatycznie na podstawie Twoich preferencji i potrzeb pracownika na przestrzeni całego życia zawodowego.",
  "Personalized by your rules": "Personalizacja",
  "Launch training packages based on your preferences. Set-up specific rules, create groups and track the progress.": "Uruchamiaj pakiety szkoleniowe na podstawie własnych preferencji. Ustal reguły, twórz grupy i śledź postępy.",
  "Built in microlearning framework": "Wbudowana platforma microlearningowa",
  "Forget about sending long- read pdf’s or videos that employees ignore. Our method is to educate employees with bite-sized information with several cards & quiz.": "Zapomnij o wysyłaniu długich plików PDF lub wideo, które pracownicy ignorują. Nasza metoda polega na edukacji pracowników za pomocą przystępnych informacji w postaci kilku kart i quizów.",
  "Magical experience with gamification platform": "Magiczne doświadczenie na platformie gamifikacji",
  "Save up 90 % of your time with our engagement platform. We want employees to stay motivated across their journey in Cyberlift gamified platform. Employees are earning stars and get beautiful achievements at different levels.": "Zaoszczędź 90 % czasu dzięki naszej platformie promującej zaangażowanie. Na platformie gamifikacji Cyberlift, pracownicy będą zmotywowani do poszerzania wiedzy, ponieważ zdobywają gwiazdki i osiągnięcia na różnych poziomach.",
  "Optimize compliance procedures": "Optymalizacja procedur zgodności",
  "Cyberlift security trainings modules cover compliance needs for modern business. Set up trainings based on your certifications and regulatory requirements across different countries and regulations.": "Moduły szkoleniowe Cyberlift dotyczące cyberbezpieczeństwa odpowiadają potrzebom współczesnym firm w zakresie zgodności. Skonfiguruj szkolenia na podstawie swoich certyfikatów i wymogów regulacyjnych.",
  "Fully integrated platform": "W pełni zintegrowana platforma",
  "Experience a streamlined user and admin interface that consolidates all your data into a single, centralized place.": "Poznaj usprawniony interfejs użytkownika i administratora, który konsoliduje wszystkie Twoje dane w jednym, scentralizowanym miejscu.",
  "The knowledge you need to get to stay tuned with the new era of security.": "Wiedza, którą musisz zdobyć, aby być na bieżąco w dziedzinie cyberbezpieczeństwa.",
  "Introducing Phishing email": "Alert",
  "reporting button": "phishingowy",
  "Cyberlift plugin button empowers employees to report phishing emails and other suspicious messages with one click directly to the security admin.": "Przycisk wtyczki Cyberlift umożliwia pracownikom zgłaszanie e-maili phishingowych i innych podejrzanych wiadomości za pomocą jednego kliknięcia bezpośrednio do administratora bezpieczeństwa.",
  "Powerful Action button inside your inbox": "Przycisk akcji w twojej skrzynce",
  "For Google": "Dla użytkowników",
  "Workspace users": "Google Workspace",
  "For Microsoft": "Dla użytkowników",
  "365 users": "Microsoft 365",
  "Faster Email reporting": "Szybsze raportowanie wiadomości e-mail",
  "It’s important to identify and stop phishing attacks inside the inbox. Cyberlift products educate employees to report suspicious emails faster than ever before.": "Ważne jest, aby identyfikować i zatrzymywać ataki phishingowe wewnątrz skrzynki odbiorczej. Produkty Cyberlift uczą pracowników szybkiego zgłaszania podejrzanych e-maili.",
  "308 days": "308 dni",
  "Without Cyberlift": "Bez Cyberlift",
  "With Cyberlift": "Z Cyberlift",
  "Here’s how fast it works": "Zobacz jak szybko to działa",
  "Identify": "Identyfikuj",
  "a suspicious email Based on our automated & personalized attack simulations employees increase security awareness with a goal to identify potential email based threats.": "podejrzanego e-maila. Na podstawie naszych zautomatyzowanych i spersonalizowanych symulacji ataków pracownicy podnoszą swoją świadomość bezpieczeństwa i identyfikują potencjalne zagrożenia zawarte w e-mailach.",
  "Mark": "Oznacz",
  "as Spam or Suspicious email In a case if an employee clicks Spam email automatically removes to spam. In a case if an employee clicks a Suspicious email then it will be reported to the security team for further investigation.": "jako spam lub podejrzany e-mail. W przypadku, gdy pracownik e-mail oznaczony jako spam, wiadomość zostanie automatycznie przeniesiona do folderu spamu. W przypadku, gdy pracownik kliknie e-mail oznaczony jako podejrzany, zostanie on zgłoszony do zespołu ds.bezpieczeństwa w celu dalszego zbadania sprawy.",
  "Report": "Zgłoś",
  "and inform security team with action made before When an employee wants to report this email they can choose the action that has been made. If it was Cyberlift’s simulation employee will get a reward. If it wasn’t the Security team will get this email into the Cyberlift’d Admin panel for further threat investigation.": "i poinformuj zespół ds.bezpieczeństwa o podjętym działaniu. Kiedy pracownik chce zgłosić ten e-mail, może wybrać podjęte działanie. Jeśli była to symulacja Cyberlift, pracownik otrzyma nagrodę. Jeśli nie, zespół ds.bezpieczeństwa przekaże ten e-mail do panelu administracyjnego Cyberlift w celu dalszego zbadania zagrożenia.",
  "Revolutionary performance.": "Rewolucyjna wydajność.",
  "Increase employee threat response from day one": "Szybsza reakcja pracowników na zagrożenia od pierwszego dnia",
  "Cyberlift helps organizations and their employees to build a stable security culture. When technologies fail-people should act with a simple scenario to stay protected from the incident.": "Cyberlift pomaga organizacjom i ich pracownikom budować stabilną kulturę bezpieczeństwa. Kiedy technologie zawodzą, ludzie powinni działać zgodnie z prostym scenariuszem i chronić się przed incydentami cybernetycznymi.",
  "It’s important to understand which reported user emails are malicious. And it’s the key to quickly removing active phishing and spear phishing attacks. Get reported emails from employees directly in your admin panel.": "Ważne jest zrozumienie, które wiadomości e-mail zgłaszane przez użytkowników są złośliwe. To klucz do szybkiego usuwania aktywnych ataków phishingowych i spear phishingowych. Otrzymuj zgłoszone e-maile od pracowników bezpośrednio w swoim panelu administracyjnym.",
  "Detect on real-time critical incident": "Wykrywaj krytyczne incydenty w czasie rzeczywistym",
  "Centralize threat reporting from all organization in one place.": "Scentralizuj zgłaszanie zagrożeń z całej organizacji w jednym miejscu.",
  "Metrics": "Wskaźniki",
  "With Cyberlift security awareness your employees' security actions performance will grow. Improve detection rates metrics and reporting metrics from day one.": "Dzięki treningom Cyberlift, świadomość bezpieczeństwa Twoich pracowników wzrośnie. Popraw wskaźniki wykrywalności i raportowania już od pierwszego dnia.",
  "+ detect rate": "wskaźnik wykrywania",
  "reporting rate": "wskaźnik raportowania",
  "Gamification Platform": "Platforma gamifikacji",
  "A gamification platform designed to supercharge employees < users > with interactive content and actions by collecting beautiful achievements and showing long-term impact of the learning security.": "Platforma gamifikacji została zaprojektowana w celu wyposażenia Twoich pracowników < użytkowników > w interaktywne treści i działania polegające na gromadzeniu osiągnięć i pokazywaniu długoterminowego wpływu szkolenia z dziedziny cyberbezpieczeństwa.",
  "Get Started": "Rozpocznij",
  "For collecting": "Aby zbierać",
  "bonuses & achievements": "bonusy & osiągnięcia",
  "For security awareness trainings": "Szkolenie w zakresie świadomości bezpieczeństwa",
  "Increase employee engagement in to security": "Zwiększ zaangażowanie pracowników w bezpieczeństwo",
  "Move beyond traditional security tests and trainings with personalized security awareness trainings powered by behavioral science and AI.": "Zapomnij o tradycyjnych testach i szkoleniach dzięki spersonalizowanym szkoleniom świadomości bezpieczeństwa opartym na nauce behawioralnej i sztucznej inteligencji.",
  "Gamified experience": "Doświadczenie gamifikacji",
  "Cyberlift motivates employees to learn security using gamification principles. For each action the user collects rewards and tracks his personal security impact.": "Cyberlift motywuje pracowników do nauki o bezpieczeństwie, korzystając z zasad gamifikacji. Za każde działanie użytkownik zbiera nagrody i śledzi swój osobisty wpływ na bezpieczeństwo.",
  "Bahaviour change": "Zmiana zachowania",
  "Cyberlift gamification platform changes employees behaviour with a solution that reward person to identify & report phishing emails. While hackers use fear for social engineering, cyberlift make the process positive and impactful through collecting rewards.": "Platforma gamifikacyjna Cyberlift zmienia zachowania pracowników dzięki rozwiązaniu, które nagradza za identyfikowanie i zgłaszanie e-maili phishingowych. Podczas gdy hakerzy używają strachu do ataków socjotechnicznych, Cyberlift czyni ten proces pozytywnym i skutecznym poprzez zbieranie nagród.",
  "Smart reporting": "Inteligentne raportowanie",
  "Cyberlift plugin button empowers employees to report phishing emails and other suspicious messages faster to the security team with one click.": "Przycisk wtyczki Cyberlift umożliwia pracownikom szybsze zgłaszanie e-maili phishingowych i innych podejrzanych wiadomości do zespołu ds.bezpieczeństwa.",
  "of security professionals feel inadequately prepared to defend their oraganization": "specjalistów ds.bezpieczeństwa czuje się niewystarczająco przygotowanych do obrony swojej firmy przed atakami hakerskimi",
  "of security professionals don’t believe their organizations provides the right training": "specjalistów ds.bezpieczeństwa specjalistów jest zdania że ich firma nie zapewnia odpowiedniego szkolenia",
  "of professionals claim their organization is impacted by the security skills shortage": "specjalistów twierdzi, że ich firma odczuwa wpływ niedoboru umiejętności z zakresu bezpieczeństwa",
  "Social Engineering Prevention": "Zapobieganie inżynierii społecznej",
  "Stay ahead of social engineering attempts and protect your sensitive data by enabling your team with the right knowledge and training.": "Wyprzedź próby ataków oparte na inżynierii społecznej i chroń swoje wrażliwe dane, zapewniając swojemu zespołowi odpowiednią wiedzę i szkolenia.",
  "of Cyber Attacks Are Targeting Your Employees Instead of Your Tech": "cyberataków jest wymierzanych w pracowników, a nie w technologię.",
  "Today hackers use AI / ML for much complexed, personalized attacks %": "Obecnie hakerzy wykorzystują AI / ML do znacznie bardziej złożonych, spersonalizowanych ataków.",
  "$4.1M": "4,1 mln USD",
  "Million The Price of the Average Social Engineering-Related Breach": "Cena przeciętnego naruszenia wywołanego atakiem socjotechnicznym",
  "Thousands of Simulation Scenarios": "Setki Scenariuszy Symulacji",
  "From spear phishing emails to voice and text messages, and deceptive web pages, CyberLift covers all types of social engineering attacks in its simulations, ensuring your team is prepared for any threat.": "Od wiadomości e-mail typu spear phishing po wiadomości głosowe i tekstowe oraz zwodnicze strony internetowe, symulacje CyberLift obejmują wszystkie rodzaje ataków socjotechnicznych, dzięki czemu Twój zespół jest przygotowany na każde zagrożenie.",
  "Skills Development for Threat Detection": "Rozwój umiejętności wykrywania zagrożeń",
  "As social engineering schemes evolve in complexity, we empower your team with the essential skills to swiftly identify and neutralize these potential threats.": "Ponieważ schematy inżynierii społecznej ewoluują pod względem złożoności, zapewniamy Twojemu zespołowi niezbędne umiejętności umożliwiające szybką identyfikację i neutralizację potencjalnych zagrożeń.",
  "Real-Time Feedback for Improvement": "Informacje zwrotne w czasie rzeczywistym",
  "Benefit from immediate, detailed feedback on every interaction within our social-engineering simulations, fostering a cycle of continuous learning and enhancement in your team's security competencies.": "Korzystaj z natychmiastowej, szczegółowej informacji zwrotnej dotyczącej każdej interakcji w ramach naszych symulacji inżynierii społecznej, wspierając cykl ciągłego uczenia się i doskonalenia kompetencji z zakresu bezpieczeństwa w Twoim zespole.",
  "AI-Assisted Vulnerability Assessment": "Ocena podatności wspomagana przez AI",
  "Leverage our AI-powered risk analysis to pinpoint your organization's vulnerabilities and devise effective mitigation strategies.": "Wykorzystaj nasze narzędzie analizy ryzyka oparte na sztucznej inteligencji, aby zidentyfikować słabe ogniwo w Twojej firmie i opracować skuteczne strategie łagodzenia ryzyka.",
  "An all-new platform.": "Zupełnie nowa platforma",
  "An all-new world for security teams.": "Zupełnie nowy świat dla zespołów ds.bezpieczeństwa.",
  "Behavioral Change and Employee Engagement": "Zmiana zachowania i zaangażowanie pracowników",
  "Empower your workforce to become your strongest defense line by fostering a culture of cybersecurity awareness and engagement.": "Wzmocnij swoich pracowników, aby stali się Twoją najsilniejszą linią obrony, wspierając kulturę świadomości i zaangażowania w zakresie cyberbezpieczeństwa.",
  "Get started ": "Rozpocznij ",
  "Reduce human cyber risks in a single platform": "Zmniejsz ryzyko cybernetyczne na jednej platformie",
  "Gamification platform for engaging cybersecurity training": "Platforma gamifikacji do angażujących szkoleń z zakresu cyberbezpieczeństwa",
  "Maximizes employee involvement for complete training participation": "Maksymalizuje zaangażowanie pracowników w celu pełnego uczestnictwa w szkoleniu",
  "Organizations boosting security culture experience a significant reduction in cyber incidents": "Firmy wzmacniające kulturę bezpieczeństwa doświadczają znacznego zmniejszenia liczby incydentów cybernetycznych.",
  "Foster a proactive cybersecurity culture": "Wspieraj proaktywną kulturę cyberbezpieczeństwa",
  "Our programs are designed to foster an active security culture, encouraging employees to take responsibility for their own and the organization's cybersecurity.": "Nasze programy mają na celu wspieranie aktywnej kultury bezpieczeństwa, zachęcając pracowników do wzięcia odpowiedzialności za cyberbezpieczeństwo własne i firmy, w której pracują.",
  "Gamified learning experience": "Doświadczenie edukacyjne oparte na gamifikacji",
  "Our gamified learning experiences make cybersecurity education engaging and fun, improving knowledge retention and fostering positive behavior change.": "Nasze doświadczenia edukacyjne oparte na gamifikacji sprawiają, że edukacja w zakresie cyberbezpieczeństwa jest angażująca i zabawna, co poprawia retencję wiedzy i wspiera pozytywną zmianę zachowań.",
  "Personalized learning paths": "Spersonalizowane ścieżki nauki",
  "CyberLift provides personalized learning paths for each employee, ensuring that each person's unique learning needs are met.": "CyberLift zapewnia spersonalizowane ścieżki edukacyjne dla każdego pracownika, zapewniając zaspokojenie unikalnych potrzeb edukacyjnych każdej osoby.",
  "Comprehensive Cybersecurity Education": "Kompleksowe szkolenie z cyberbezpieczeństwa",
  "Equip your team with the knowledge they need to spot, report, and prevent cyber threats.": "Wyposaż swój zespół w wiedzę potrzebną do wykrywania, raportowania i zapobiegania cyberzagrożeniom.",
  "hours of cybersecurity education available": "godzin edukacji w zakresie cyberbezpieczeństwa",
  "of employees report improved understanding of cyber threats after completing the program": "pracowników odnotowuje lepsze rozumienie zagrożeń cybernetycznych",
  "up to": "do",
  "reducing average threat report time": "zredukowania średniego czasu zgłaszania zagrożeń",
  "Tailored cybersecurity curriculum": "Szyty na miarę program nauczania w zakresie cyberbezpieczeństwa",
  "Our AI-powered platform designs a tailored cybersecurity curriculum for each employee based on their role, knowledge level, and learning style.": "Nasza platforma oparta na sztucznej inteligencji opracowuje program cyberbezpieczeństwa dostosowany dla każdego pracownika w oparciu o jego rolę, poziom wiedzy i styl uczenia się.",
  "On-demand learning": "Nauka na żądanie",
  "Access a rich library of cybersecurity resources, available to fit the busy schedules of your employees.": "Uzyskaj dostęp do bogatej biblioteki zasobów cyberbezpieczeństwa, dopasowanej do napiętego grafiku Twoich pracowników.",
  "Track progress and measure impact": "Śledź postęp i mierz wpływ",
  "Track your team's progress and measure the impact of your cybersecurity training programs with our detailed analytics and reports.": "Śledź postępy swojego zespołu i mierz wpływ programów szkoleniowych z zakresu cyberbezpieczeństwa dzięki naszym szczegółowym analizom i raportom.",
  "Stay updated on latest threats": "Bądź na bieżąco z najnowszymi zagrożeniami",
  "Our curriculum is constantly updated to include the latest cyber threats and prevention strategies, ensuring your team is always equipped with the most up-to-date knowledge.": "Nasz program nauczania jest stale aktualizowany w celu uwzględnienia najnowszych zagrożeń cybernetycznych i strategii zapobiegania im, dzięki czemu Twój zespół jest zawsze wyposażony w najbardziej aktualną wiedzę.",
  "Proactive Risk Assessment and Management": "Proaktywna ocena i zarządzanie ryzyka",
  "AI-powered risk assessment": "Ocena ryzyka oparta na sztucznej inteligencji",
  "Our AI-powered technology helps you identify potential vulnerabilities and assess the level of risk associated with each one.": "Nasza technologia oparta na sztucznej inteligencji pomaga zidentyfikować potencjalne luki w zabezpieczeniach i ocenić poziom ryzyka z nimi związany.",
  "True risk": "Realne ryzyko",
  "Continual monitoring and updates": "Ciągłe monitorowanie i aktualizacje",
  "We provide continuous monitoring and risk updates, enabling you to take immediate action on emerging threats.": "Zapewniamy ciągłe monitorowanie i aktualizację ryzyka, umożliwiając podejmowanie natychmiastowych działań w przypadku pojawiających się zagrożeń.",
  "Comprehensive risk reports": "Kompleksowe raporty oceny ryzyka",
  "Get detailed risk reports with actionable insights to help you prioritize and mitigate your organization's cybersecurity risks.": "Uzyskaj szczegółowe raporty oceny ryzyku z przydatnymi informacjami, które pomogą Ci ustalić priorytety i złagodzić ryzyko cyberbezpieczeństwa Twojej firmy.",
  "Phishing Simulation": "Symulacja phishingu",
  "Stay compliant with regulations": "Zachowaj zgodność z przepisami",
  "Our platform helps you stay compliant with international cybersecurity regulations by ensuring your risk assessment processes are in line with global standards.": "Nasza platforma pomaga zachować zgodność z międzynarodowymi przepisami w zakresie cyberbezpieczeństwa, zapewniając zgodność procesów oceny ryzyka z globalnymi standardami.",
  "Global Workforce Defense": "Ochrona globalnej siły roboczej",
  "Secure your organization's sensitive data no matter": "Zabezpiecz wrażliwe dane swojej firmy bez względu na to",
  "where your employees are located with our global": "gdzie znajdują się Twoi pracownicy, dzięki naszemu globalnemu",
  "workforce defense solution.": "rozwiązaniu służącemu ochronie pracowników.",
  "Global": "Globalny",
  "Coverage": "Zasięg",
  "With coverage in over 100 countries, we've got your global workforce covered against cyber threats.": "Chronimy Twoich pracowników przed zagrożeniami cybernetycznymi w ponad 100 krajach",
  "Global threat": "Analiza",
  "intelligence": "globalnego zagrożenia",
  "Stay informed about the latest global cyber threats and use these insights to protect your organization.": "Bądź na bieżąco z najnowszymi globalnymi zagrożeniami cybernetycznymi i wykorzystaj tę wiedzę do ochrony swojej firmy.",
  "Scalability": "Skalowalność",
  "Our solutions support a wide range of business sizes, from SMEs to multinational": "Nasze rozwiązania wspierają firmy różnej wielkości, od MŚP po międzynarodowe korporacje,",
  "corporations, ensuring adaptable and scalable cyber defense strategies.": "zapewniając adaptowalne i skalowalne strategie cyberobrony.",
  "Training in local languages": "Szkolenia w językach lokalnych",
  "Provide cybersecurity training in your employees' native languages, improving their understanding and application of key concepts.": "Szkolenia z zakresu cyberbezpieczeństwa w lokalnych językach poprawiające zrozumienie i zastosowanie kluczowych pojęć.",
  "Privacy and Compliance Across Borders": "Prywatność i przestrzeganie przepisów transgranicznych",
  "We navigate the complexities of cross-border data privacy laws and regulations, ensuring your global workforce operates within the necessary legal frameworks.": "Znamy się na zawiłościach przepisów i regulacji dotyczących prywatności danych na całym świecie, zapewniając globalnym pracownikom działanie w niezbędnych ramach prawnych.",
  "Navigate the complex landscape of global data protection and privacy regulations confidently, ensuring your business remains compliant while prioritizing data security.": "Poruszaj się pewnie w globalnych przepisach dotyczących ochrony danych i prywatności, zachowaj zgodność z przepisami a bezpieczeństwo danych traktuj jak priorytet",
  "Comprehensive Compliance Training": "Kompleksowe szkolenie w zakresie zgodności",
  "Equip your team with the knowledge and tools they need to understand and adhere to global compliance standards, ensuring a culture of proactive compliance within your organization.": "Wyposaż swój zespół w wiedzę i narzędzia potrzebne do zrozumienia i przestrzegania globalnych standardów zgodności, zapewniając kulturę proaktywnej zgodności w Twojej firmie.",
  "Automated Compliance Reporting": "Zautomatyzowane raportowanie zgodności",
  "Save time and resources with automated compliance reports that ensure you're always audit-ready and help you identify areas for improvement.": "Oszczędzaj czas i zasoby dzięki zautomatyzowanym raportom zgodności dzięki którym zawsze jesteś gotowy do audytu i pomagają zidentyfikować obszary wymagające poprawy.",
  "Real-time Compliance Monitoring": "Monitorowanie zgodności w czasie rzeczywistym",
  "With continuous monitoring, get alerted to potential non-compliance issues in real-time and take immediate action to stay compliant.": "Dzięki ciągłemu monitorowaniu otrzymujesz ostrzeżenia o potencjalnych niezgodnościach w czasie rzeczywistym i podejmujesz natychmiastowe działania w celu zachowania zgodności.",
  "AI-Powered Compliance Risk Assessment": "Ocena ryzyka zgodności oparta na sztucznej inteligencji",
  "Use our AI-powered risk assessment to identify compliance vulnerabilities and devise effective strategies to address them.": "Skorzystaj z naszej oceny ryzyka opartej na sztucznej inteligencji, aby zidentyfikować słabe punkty zgodności i opracować skuteczne strategie ich wyeliminowania.",
  "Regulatory Updates": "Aktualizacje przepisów",
  "Stay abreast of updates and changes with regular notifications and resources to help you understand their impact.": "Bądź na bieżąco z aktualizacjami i zmianami dzięki regularnym powiadomieniom i zasobom, które pomogą Ci zrozumieć ich wpływ.",
  "Behavioral Scoring for Compliance": "Ocena behawioralna pod kątem zgodności z przepisami",
  "Leverage our platform's individual behavioral compliance scoring system, enabling you to identify employees who might need additional training or resources to ensure compliance.": "Wykorzystaj indywidualny system oceny zgodności behawioralnej naszej platformy, umożliwiający identyfikację pracowników, którzy mogą potrzebować dodatkowych szkoleń lub zasobów w celu zapewnienia zgodności.",
  "Switch to Cyberlift today": "Przejdź do Cyberlift już dziś!",
  "Cyberlift offers more than just a security platform. It's a game-changing combination of advanced AI, deep learning, and human behavior change solutions.": "Cyberlift to więcej niż tylko platforma bezpieczeństwa. To przełomowe połączenie zaawansowanej sztucznej inteligencji, głębokiego uczenia(tzw. Deep learning) i rozwiązań zmieniających ludzkie zachowania.",
  "Features": "Cechy",
  "Cyberlift": "Cyberlift",
  "Others": "Inne",
  "Fully Automated Creation and Sending of Simulations": "W pełni zautomatyzowane tworzenie i wysyłanie symulacji",
  "Social engineering simulations": "Symulacje oparte na inżynierii społecznej",
  "Customized approach per each individual user": "Indywidualne podejście do każdego użytkownika",
  "AI-powered voice phishing simulations": "Symulacje phishingu głosowego oparte na sztucznej inteligencji",
  "Global coverage with multilingual content": "Globalny zasięg z wielojęzyczną zawartością",
  "Global Coverage": "Globalny Zasięg",
  "Trainings modules for Various Security Certifications(ISO / IEC 27001, SOC 2, HIPAA, GDPR, PCI DSS)": "Moduły szkoleniowe dla różnych certyfikatów bezpieczeństwa(ISO / IEC 27001, SOC 2, HIPAA, RODO, PCI DSS)",
  "AI-powered security co-pilot for social engineering analysis": "Oparty na sztucznej inteligencji program pilotażowy do analizy inżynierii społecznej",
  "Automated Threat Risk Evaluation and Escalation": "Zautomatyzowana ocena zagrożeń i eskalacji ryzyka",
  "Gamification platform for users": "Platforma gamifikacji dla użytkowników",
  "Rewards & achievements": "Bonusy & osiągnięcia",
  "Easy LDAP Integration for Automatic Team Synchronization": "Łatwa integracja LDAP dla automatycznej synchronizacji zespołów",
  "Reporting Employee Performance Progress to Relevant Team Members": "Raportowanie postępów pracowników do odpowiednich członków zespołu",
  "Dedicated API for reported threats management": "Dedykowany interfejs API do zarządzania zgłoszonymi zagrożeniami",
  "Dynamic rules set-up for trainings packages": "Dynamiczny zestaw zasad dla pakietów szkoleniowych",
  "Seamless Adaptation to Company’s security policy": "Płynna adaptacja do polityki bezpieczeństwa firmy",
  "It’s time to rethink how to": "Nadszedł czas, aby przemyśleć, jak",
  "mitigate your biggest": "złagodzić największe",
  "security risks.": "zagrożenia bezpieczeństwa.",
  "Predict and prevent email": "Przewiduj i zapobiegaj",
  "based attacks with AI": "atakom e-mailowym",
  "- powered solutions.": "za pomocą rozwiązań opartych na sztucznej inteligencji.",
  "Let’s set up a meeting to choose a pricing plan tailored to your business needs.": "Umówmy się na spotkanie, aby wybrać plan cenowy dostosowany do potrzeb Twojej firmy.",
  "Localized platform with 10+ languages": "Platforma dostępna w ponad 10 językach",
  "AI-powered engine for automated & personalized experience": "Silnik oparty na sztucznej inteligencji zapewniający zautomatyzowane i spersonalizowane doświadczenie ",
  "24/7 customer support": "Obsługa klienta 24 / 7 ",
  "Unlimited users on platform": "Nieograniczona liczba użytkowników na platformie",
  "Auto-synchronized with your security policies": "Automatyczna synchronizacja z zasadami bezpieczeństwa ",
  "Easy user management by department, groups and roles": "Łatwe zarządzanie użytkownikami według działów, grup i ról",
  "First name": "Imię",
  "Last name": "Nazwisko",
  "Work email": "Służbowy adres e-mail",
  "Company name": "Nazwa firmy",
  "Number of employees": "Liczba pracowników",
  "By clicking the button you agree": "Klikając przycisk,",
  "with our": "zgadzasz się z naszą",
  "Privacy Policy": "Polityką prywatności",
  "Request quote": "Poproś o wycenę",
  "APP prevention": "Zapobieganie APP",
  "Protect Your Organization Against Authorized Push Payment Fraud.": "Chroń swoją firmę przed autoryzowanymi oszustwami związanymi z płatnościami push",
  "Cyberlift helps to reduce Authorized Push Payment(APP) fraud by up to 50 % of potential losses through automatic and engaging security awareness training for your end customers": "Cyberlift pomaga ograniczyć autoryzowane oszustwa związane z płatnościami push(APP) nawet o 50 % potencjalnych strat dzięki automatycznym i angażującym szkoleniom w zakresie świadomości bezpieczeństwa dla klientów końcowych",
  "APP risk prevention should be the highest priority for executives": "Zapobieganie ryzyku APP powinno być najwyższym priorytetem dla kadry kierowniczej",
  "of all reported crimes in England and Wales are accounted for by fraud": "wszystkich zgłoszonych przestępstw w Anglii i Walii stanowią oszustwa",
  "£485.2 m": "485, 2 mln GBP",
  "represents the total amount lost to APP fraud in the UK in 2022.": "to łączna kwota utracona w wyniku oszustw APP w Wielkiej Brytanii w 2022 r.",
  "of fraud losses are expected to be covered by financial institutions under new PSR reimbursement requirements.": "strat związanych z oszustwami będzie pokrywanych przez instytucje finansowe w ramach nowych wymogów PSR dotyczących zwrotu kosztów.",
  "Security Awareness Training for your end customers is an essential part of reducing potential losses in the near term.": "Szkolenie w zakresie świadomości bezpieczeństwa dla klientów końcowych jest istotnym elementem ograniczania potencjalnych strat w najbliższym czasie.",
  "Mitigate APP Fraud Risks": "Ograniczenie ryzyka oszustw APP",
  "Equip your customers with the necessary knowledge and skills to significantly reduce the likelihood of falling victim to APP fraud with Cyberlift.": "Wyposaż swoich klientów w niezbędną wiedzę i umiejętności, aby zapobiec padnięciu ofiarą oszustwa APP dzięki Cyberlift.",
  "Build Trust and Loyalty": "Buduj zaufanie i lojalność",
  "Demonstrate your commitment to customer security, enhancing trust and reinforcing your reputation as a safe and responsible financial institution through Cyberlift's solutions.": "Zademonstruj swoje zaangażowanie w bezpieczeństwo klientów, zwiększając zaufanie i wzmacniając swoją reputację jako bezpiecznej i odpowiedzialnej firmy dzięki rozwiązaniom Cyberlift.",
  "Ensure Regulatory Compliance": "Zapewnienie zgodności z przepisami",
  "With Cyberlift, meet and exceed the requirements set forth for APP fraud prevention and customer reimbursement, avoiding penalties and reinforcing customer confidence.": "Z Cyberlift spełnisz i przewyższysz wymagania dotyczące zapobiegania oszustwom APP oraz zwrotu kosztów klientom, unikając przy tym kar i wzmacniając zaufanie klientów.",
  "Adaptable and Scalable Solutions": "Rozwiązania adaptowalne i skalowalne",
  "Cyberlift offers adaptable and scalable solutions that cater to both small local banks and large multinational institutions, meeting diverse needs in the evolving landscape of online fraud prevention.": "Cyberlift oferuje adaptowalne i skalowalne rozwiązania, które są odpowiednie zarówno dla małych lokalnych banków, jak i dużych międzynarodowych instytucji, spełniając zróżnicowane potrzeby w zmieniającym się krajobrazie zapobiegania oszustwom internetowym.",

  "How it works ?": "Jak to działa ?",
  "Change consumers security Behavior and reduce Risk": "Zmiana zachowań konsumentów w zakresie bezpieczeństwa i zmniejszenie ryzyka",
  "Cyberlift assigns short, gamified security training modules to increase engagement and build long-term security behavior. Our AI-powered engine personalizes and delivers frequent security training and simulations to educate your end consumers on the most advanced payment fraud tactics": "Cyberlift przypisuje krótkie, gamifikowane moduły szkoleniowe w zakresie bezpieczeństwa, aby zwiększyć zaangażowanie i budować długoterminowe zachowania w zakresie bezpieczeństwa. Nasz silnik oparty na sztucznej inteligencji personalizuje i zapewnia częste szkolenia i symulacje w zakresie bezpieczeństwa, aby edukować klientów końcowych w zakresie najbardziej zaawansowanych taktyk oszustw płatniczych.",
  "Personalize trainings with AI": "Personalizacja szkoleń dzięki sztucznej inteligencji",
  "Cyberlift's AI engine generates a unique profile for every user and automatically delivers the most relevant training based on language, location, and current fraud trends. Training modules are crafted by experts in 30+ languages and can be customized according to your specific rules. Our content library consists of 170 training modules covering the latest trends, such as Smishing, Vishing, Payment fraud, Social engineering, etc.": "Silnik AI Cyberlift generuje unikalny profil dla każdego użytkownika i automatycznie dostarcza najbardziej odpowiednie szkolenia w oparciu o język, lokalizację i aktualne trendy w zakresie oszustw. Moduły szkoleniowe są tworzone przez ekspertów w ponad 30 językach i mogą być dostosowywane do określonych zasad. Nasza biblioteka treści składa się ze 170 modułów szkoleniowych obejmujących najnowsze trendy, takie jak Smishing, Vishing, Oszustwa płatnicze, Inżynieria społeczna, itp.",
  "Increase engagement with gamification": "Zwiększ zaangażowanie dzięki gamifikacji",
  "Cyberlift rewards users with stars and levels to enhance engagement. You can customize it to integrate with your existing rewards and loyalty programs, such as cashback, points, or discounts.": "Cyberlift nagradza użytkowników, aby zwiększyć ich zaangażowanie. Możliwa jest integracja z istniejącymi programami lojalnościowymi, takimi jak cashback, punkty lub rabaty.",
  "Train consumers with microlearning framework": "Wbudowana platforma mikrolearningowa",
  "Cyberlift delivers short, bite-sized micro-trainings that reinforce good behavior and transform risks into engaging learning processes. Our method educates users through brief cards and quizzes.": "Cyberlift wzmacnia dobre zachowania i przekształca ryzyko w angażujące procesy uczenia się dzięki krótkim i łatwym do przyswojenia mikroszkoleniom. Nasza metoda opiera się na szkoleniu użytkowników za pomocą kart i quizów.",
  "Orchestrate consumers vulnerabilities data with your Anti-fraud": "Zarządzaj danymi o podatnościach konsumentów za pomocą swojego systemu przeciwdziałania oszustwom",
  "Cyberlift leverages real-time analytics to help you quickly understand the impact of the Cyberlift platform, enabling you to promptly reduce risk. Automate and enhance your anti-fraud operations by managing data through the Cyberlift API. Transform your end consumers into a human firewall today.": "Cyberlift wykorzystuje analizy w czasie rzeczywistym, aby pomóc Ci szybko zrozumieć wpływ platformy Cyberlift, umożliwiając szybkie zmniejszenie ryzyka. Zautomatyzuj i usprawnij swoje działania antyfraudowe, zarządzając danymi za pośrednictwem interfejsu API Cyberlift. Przekształć swoich klientów końcowych w ludzką zaporę już dziś.",
  "Integration": "Integracja",
  "Easy integration is available for both small businesses and large enterprises, leveraging advanced API integration and a sophisticated cloud infrastructure. Our system operates without collecting any personal information about your customers, utilizing anonymized identifiers for all processes.": "Łatwa integracja jest dostępna zarówno dla małych firm, jak i dużych przedsiębiorstw, wykorzystując zaawansowaną integrację API i zaawansowaną infrastrukturę chmury. Nasz system nie gromadzi danych osobowych klientów, wykorzystując anonimowe identyfikatory we wszystkich procesach.",
  "Implement Cyberlift and Save on your potential losses.": "Wdroż Cyberlift i zaoszczędź na potencjalnych stratach.",

























  "end": "koniec"
}