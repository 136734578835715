import React from 'react'
import Navigation from '../../components/navigation'
import Footer from '../../components/footer'
import { THEMES } from 'src/constatnts'

import './style.css'
import { Button } from 'src/components/button'
import { Divider } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import PromoBlock from 'src/components/PromoBlock'
import { useTranslation } from 'react-i18next'

export const ProactiveRiskAssessmentPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <>
      <Navigation style={{ borderBottom: '1px solid #4F4F4F80' }} theme={THEMES.dark} />
      <section className='dark pb150 pt110 mob-pb-20'>
        <div className='container1300'>
          <div className='row br30 eye-bcg padding-112-110 mob-padding-46-43'>

            <div className='row mob-column mob-column-center'>
              <div className='column column column-start mob-column-center'>
                <p className='secondary-heading mob-text-center word-space-0 mob-font-14 mob-mw-203'>{t("Proactive Risk Assessment and Management")}</p>
                <h1 style={{ maxWidth: '520px' }} className='mb30 mob-text-center'>{t("Anticipate and mitigate cyber risks before they become threats")}</h1>
                <p style={{ maxWidth: '400px' }} className='mb50 heading-description mb40 mob-text-center'>{t("Anticipate and mitigate cyber risks before they become threats")}</p>

                <Button onClick={() => navigate('/pricing')} variant={'gradient-button'} >{t('Get started')} <svg style={{ marginLeft: '20px' }} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill="#E3E3FF" />
                </svg>
                </Button>
              </div>
            </div>




            {/* <img style={{ margin: 0 }} src="/images/atom-cc.png" width="559" alt="" /> */}
          </div>
        </div>
      </section>
      <section className='dark'>
        <div className='container1300'>

          <div className='row space-between mob-column br30 padding-20-110 mob-padding-46-43' style={{ background: 'radial-gradient(227.63% 227.63% at 36.38% -53.15%, #1D1D29 0%, rgba(29, 29, 41, 0) 100%)' }}>
            <div className='column mart70'>
              <p className='font46 mb20 mob-font-26' style={{ maxWidth: '450px' }}>{t("AI-powered risk assessment")}</p>
              <p className='font20 mob-font-14 mob-mb-40' style={{ maxWidth: '450px' }}>{t("Our AI-powered technology helps you identify potential vulnerabilities and assess the level of risk associated with each one.")}</p>
            </div>
            <div className='column img-container-32'>
              <p className='mb40 sato32 mob-sato20 font700'>{t("True risk")}</p>
              <img className='mob-img' src="/images/true-risk-1.png" style={{ marginLeft: -10 }} width={'456'} alt="" />
              <img className='mob-img' src="/images/true-risk-2.png" style={{ marginLeft: -10 }} width={'456'} alt="" />
              <img className='mob-img' src="/images/true-risk-3.png" style={{ marginLeft: -10 }} width={'456'} alt="" />
            </div>
          </div>

          <div className='row row-center space-between mob-column-reverse br30 padding-160-90 mob-padding-46-43 '>
            <img className='mob-img' src="/images/continual-monitoring.png" width={'584'} alt="" />
            <div className='column'>
              <p className='font46 mb20 mob-sato-26 mob-text-center ' style={{ maxWidth: '477px' }}>{t("Continual monitoring and updates")}</p>
              <p className='font20 mob-font-14 mob-mb-40 mob-text-center' style={{ maxWidth: '450px' }}>{t("We provide continuous monitoring and risk updates, enabling you to take immediate action on emerging threats.")}</p>
            </div>
          </div>

          <div className='row space-between mob-column br30 padding-60-110 mob-padding-46-43 mb140' style={{ background: 'radial-gradient(227.63% 227.63% at 36.38% -53.15%, #1D1D29 0%, rgba(29, 29, 41, 0) 100%)' }}>
            <div className='column mt30 mob-mt-0'>
              <p className='font46 mb20 mob-font-26' style={{ maxWidth: '450px' }}>{t("Comprehensive risk reports")}</p>
              <p className='font20 mob-font-14 mob-mb-40' style={{ maxWidth: '450px' }}>{t("Get detailed risk reports with actionable insights to help you prioritize and mitigate your organization&apos;s cybersecurity risks.")}</p>
            </div>
            <div className='column'>
              <p className='mb40'>{t("Phishing Simulation")} </p>
              <img className='mob-hide' src="/images/comperhensive-risk-report.png" width={'530'} alt="" />
              <div className='hide mob-show column'>
                <div className='row'>
                  <p style={{
                    fontFamily: 'Satoshi',
                    fontSize: '76px',
                    fontWeight: '900',
                    lineHeight: '76px',
                    letterSpacing: '0.9px',
                    textAlign: 'left',
                    color: '#04DA00'
                  }}>35</p>

                  <div className='column column-center' style={{ marginLeft: 33 }}>
                    <svg width="124" height="63" viewBox="0 0 124 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M63.1774 1.2376C63.3847 1.03315 63.6556 0.921875 63.943 0.921875C63.9528 0.921875 63.9627 0.921875 63.9726 0.922245C79.4396 1.33225 92.973 7.00128 104.197 17.7719C104.414 17.9793 104.535 18.2606 104.539 18.563C104.543 18.8662 104.428 19.1505 104.217 19.3642L89.5263 34.2211C89.1117 34.6407 88.4405 34.6492 87.9978 34.2429C81.3856 28.1502 72.8295 24.5667 63.906 24.1522C63.3112 24.1245 62.8448 23.6383 62.8448 23.0453V2.03357C62.8448 1.73152 62.9628 1.4487 63.1774 1.2376Z" fill="#CECEFF" fillOpacity="0.2" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M106.025 20.0879C106.03 20.0879 106.035 20.0879 106.04 20.0879C106.339 20.0916 106.617 20.2143 106.823 20.4336C117.473 31.7838 123.079 45.4699 123.484 61.1113C123.492 61.4134 123.381 61.6988 123.172 61.9154C122.963 62.1325 122.684 62.2519 122.385 62.2519H101.608C101.021 62.2519 100.541 61.7805 100.513 61.1786C100.104 52.1549 96.56 43.5023 90.5353 36.8155C90.1328 36.3682 90.1419 35.6894 90.5568 35.2698L105.248 20.4129C105.456 20.2029 105.731 20.0879 106.025 20.0879Z" fill="#CECEFF" fillOpacity="0.2" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M36.2219 34.2429C35.7796 34.6492 35.1091 34.6407 34.6938 34.2211L20.0026 19.3642C19.7913 19.1505 19.6772 18.8662 19.6808 18.563C19.6849 18.2606 19.8063 17.9793 20.0231 17.7719C31.2468 7.00128 44.7802 1.33225 60.2472 0.922245C60.2571 0.921875 60.2669 0.921875 60.2768 0.921875C60.5641 0.921875 60.835 1.03315 61.0423 1.2376C61.2569 1.4487 61.375 1.73152 61.375 2.03357V23.0453C61.375 23.6383 60.9089 24.1245 60.3137 24.1522C51.3906 24.5663 42.8342 28.1498 36.2219 34.2429Z" fill="#CECEFF" fillOpacity="0.2" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M18.184 20.0879C18.1888 20.0879 18.1935 20.0879 18.1987 20.0879C18.4926 20.0879 18.7682 20.2029 18.9759 20.4129L33.6671 35.2698C34.0817 35.6894 34.0912 36.3682 33.6883 36.8155C27.6636 43.5023 24.1196 52.1549 23.7102 61.1786C23.6831 61.7805 23.2024 62.2519 22.616 62.2519H1.83855C1.53987 62.2519 1.26021 62.1325 1.05146 61.9154C0.842716 61.6988 0.731941 61.4134 0.739254 61.1113C1.14505 45.4703 6.75086 31.7838 17.401 20.4336C17.6064 20.2143 17.8846 20.0916 18.184 20.0879Z" fill="#FA145F" />
                    </svg>

                    <p style={{ fontSize: '23px', fontFamily: 'Satoshi', fontWeight: 500, marginTop: '-10px' }}>24%</p>
                    <p style={{ color: '#CECEFF66', fontSize: 13, marginTop: 6 }}>{t("Success")}</p>


                  </div>
                </div>


                <div className='row' style={{ marginTop: 13 }}>
                  <div className='row row-bottom' style={{ marginRight: 30 }}>
                    <p style={{
                      fontFamily: 'Satoshi',
                      fontSize: '22px',
                      fontWeight: '500',
                      marginRight: 8
                    }}>13.304</p>
                    <span style={{ fontSize: 12, color: '#CECEFF66', whiteSpace: 'nowrap' }}>{t("Total sent")}</span>
                  </div>
                  <div className='row row-bottom' >
                    <p style={{
                      fontFamily: 'Satoshi',
                      fontSize: '22px',
                      fontWeight: '500',
                      marginRight: 8
                    }}>15%</p>
                    <span style={{ fontSize: 12, color: '#CECEFF66', whiteSpace: 'nowrap' }}>1.995 {t("Fail")}</span>
                  </div>
                </div>

                <div className='row' style={{ marginTop: 21 }}>
                  <div className='row row-bottom' style={{ marginRight: 30 }}>
                    <p style={{
                      fontFamily: 'Satoshi',
                      fontSize: '22px',
                      fontWeight: '500',
                      marginRight: 8
                    }}>5%</p>
                    <span style={{ fontSize: 12, color: '#CECEFF66' }}>665 {t("Miss")}</span>
                  </div>
                </div>


                <Divider style={{ background: '#CECEFF33', margin: '30px 0px' }} />


                <p style={{ fontWeight: 700, fontSize: 14, marginBottom: 23 }}>Top Phishing Themes</p>

                <p style={{ color: '#CECEFF99', fontSize: 14, marginBottom: 16, lineHeight: '17px' }}>
                  <span style={{ fontWeight: 700 }}>01.</span>
                  {t("Slack invitation")}
                </p>
                <p style={{ color: '#CECEFF99', fontSize: 14, marginBottom: 16, lineHeight: '17px' }}>
                  <span style={{ fontWeight: 700 }}>02.</span>
                  {t("Renew your Confluence Account password")}
                </p>
                <p style={{ color: '#CECEFF99', fontSize: 14, marginBottom: 16, lineHeight: '17px' }}>
                  <span style={{ fontWeight: 700 }}>03.</span>
                  {t("Sign the Document sent by &lt;colleague&#62;")}
                </p>
                <p style={{ color: '#CECEFF99', fontSize: 14, marginBottom: 16, lineHeight: '17px' }}>
                  <span style={{ fontWeight: 700 }}>04.</span>
                  {t("Account Lockout Notice- suspicious activity on your account")}
                </p>
                <p style={{ color: '#CECEFF99', fontSize: 14, marginBottom: 16, lineHeight: '17px' }}>
                  <span style={{ fontWeight: 700 }}>05.</span>
                  {t("Re-verify your email for Bitbucket, account security")}
                </p>
              </div>
            </div>
          </div>

          <div className='row row-center space-between mob-column-reverse br30 padding-60-110 mob-padding-46-43'>
            <img className='mob-img' src="/images/compliant-with-regulations.png" width={'570'} alt="" />
            <div className='column'>
              <p className='font46 mb20 mob-font-26 mob-sato-26 mob-text-center' style={{ maxWidth: '450px' }}>{t("Stay compliant with regulations")}</p>
              <p className='font20 mob-font-14 mob-mb-40 mob-text-center' style={{ maxWidth: '450px' }}>{t("Our platform helps you stay compliant with international cybersecurity regulations by ensuring your risk assessment processes are in line with global standards.")}</p>
            </div>
          </div>


        </div>
      </section>

      <section className='dark pb80 pt160 mob-pt-40'>
        <PromoBlock>
          <p className='mb36 sato36 font500 mob-font-24'>{t("An all-new platform.")}<br />
            {t("An all-new world for security teams.")}</p>
        </PromoBlock>
      </section>
      <Divider style={{ margin: 0, background: '#2F2F2F' }}></Divider >
      <Footer theme={THEMES.dark} />
    </>
  )
}