import React from 'react'
import Navigation from '../../components/navigation'
import Footer from '../../components/footer'
import { THEMES } from 'src/constatnts'

import './style.css'
import { Button } from 'src/components/button'
import { useNavigate } from 'react-router-dom'
import PromoBlock from 'src/components/PromoBlock'
import { useTranslation } from 'react-i18next'

export const ComprehensiveCybersecurityPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <>
      <Navigation style={{ borderBottom: '1px solid #4F4F4F80' }} theme={THEMES.dark} />
      <section className='dark heading-section-compact'>
        <div className='container'>
          <div className='row row-center space-between mob-column-reverse'>
            <div className='column column column-start mob-column-center'>
              {/* <p className='secondary-heading mob-text-center mob-font-14'>Comprehensive Cybersecurity <br className='hide mob-show' /> Education</p> */}
              <p className='secondary-heading mob-text-center mob-font-14'>{t("Comprehensive Cybersecurity Education")}</p>
              <h1 style={{ maxWidth: '580px' }} className='mb40 mob-text-center'>{t("Stay ahead with comprehensive cybersecurity education")}</h1>
              <p style={{ maxWidth: '470px' }} className='mb50 heading-description mob-text-center'>{t("Equip your team with the knowledge they need to spot, report, and prevent cyber threats.")}</p>

              <Button onClick={() => navigate('/pricing')} variant={'gradient-button'} >{t('Get started')} <svg style={{ marginLeft: '20px' }} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill="#E3E3FF" />
              </svg>
              </Button>
            </div>
            <div className='img-container-24'>
              <img src="/images/atom-cc.png" className='mob-img' width="559" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className='darker pt230 mob-pt-30 pb100 mob-pb-20'>
        <div className='container1300'>
          <div className='row br20 dark-box box-shadow pb100 mob-pb-60 mob-column mb150 mob-mb-20'>
            <div className='one-of-3 mob-fullwidth pt54 column column-center'>
              <p className='big-num big-num90 mb36 mob-mb-20 gradient-text mob-font'>
                100+
              </p>
              <p className='font16 text-center mob-font-14' style={{ maxWidth: 297 }}>{t("hours of cybersecurity education available")}</p>
            </div>
            <div className='one-of-3 mob-fullwidth pt54 column column-center'>
              <p className='big-num big-num90 mb36 mob-mb-20 gradient-text'>
                95%
              </p>
              <p className='font16 text-center mob-font-14' style={{ maxWidth: 297 }}>{t("of employees report improved understanding of cyber threats after completing the program")}</p>
            </div>
            <div className='one-of-3 mob-fullwidth pt54 column column-center'>
              <p className='big-num big-num90 mb36 mob-mb-20 gradient-text'>
                <span className='font32 font700 '>{t("up to")}</span> 80%
              </p>
              <p className='font16 text-center mob-font-14' style={{ maxWidth: 237 }}>{t("reducing average threat report time")}</p>
            </div>
          </div>


          <div className='br30 row row-center space-between mob-column dark-box mb40 padding-80-110 mob-padding-46-43'>
            <div className='column'>
              <p className='font46 mb20 mob-font-26' style={{ maxWidth: 310 }}>{t("Tailored cybersecurity curriculum")}</p>
              <p className='font20 mob-font-14 mob-mb-40' style={{ maxWidth: 422 }}>{t("Our AI-powered platform designs a tailored cybersecurity curriculum for each employee based on their role, knowledge level, and learning style.")}</p>
            </div>
            <div className='img-container-25'>
              <img src="/images/tailored-cybersec.png" className='mob-hide' width={'673'} alt="" />
              <img src="/images/tailored-cybersec-mob.png" className='mob-img hide mob-show' alt="" />
            </div>
          </div>

          <div className='row space-between mob-column mb30'>
            <div className='br30 mob-fullwidth on-demand-learning column width520 mob-mb-20' style={{ justifyContent: 'flex-end' }}>
              <div className='ondemand-gradient padding-74-80 mob-padding-46-43'>
                <p className='font46 mb20 mob-font-26'>{t("On-demand learning")}</p>
                <p className='font20 mob-font-14 mob-mb-40'>{t("Access a rich library of cybersecurity resources, available to fit the busy schedules of your employees.")}</p>

              </div>
            </div>
            <div className='br30 mob-fullwidth dark-box box-shadow column mob-column-reverse width740 padding-74-100 mob-padding-46-43'>
              <div className='img-container-26'>
                <img src="/images/track-progress.png" className='mob-img mob-hide' width={520} alt="" />
                <img src="/images/track-progress-mob.png" className='mob-img hide mob-show mob-mb-40' style={{ maxWidth: '307px' }} alt="" />
              </div>
              <div className='column'>
                <p className='font46 mb20 mob-font-26'>{t("Track progress and measure impact")}</p>
                <p className='font20 mob-font-14 mob-mb-40' style={{ maxWidth: 510 }}>{t("Track your team's progress and measure the impact of your cybersecurity training programs with our detailed analytics and reports.")}</p>

              </div>
            </div>
          </div>

          <div className='br30 column column-center dark-box box-shadow' >
            <div className='text-center padding-80-0 mob-pb-60 mob-pt-50 column column-center' style={{}}>
              <p className='font46 mb20 mob-font-26 mob-mw-265'>{t("Stay updated on latest threats")}</p>
              <p className='font20 mob-font-14 mob-mb-40 mob-mw-243' style={{ maxWidth: 816 }}>{t("Our curriculum is constantly updated to include the latest cyber threats and prevention strategies, ensuring your team is always equipped with the most up-to-date knowledge.")}</p>
            </div>
            <img src="/images/stay-updated.jpg" className='mob-hide' width={"100%"} alt="" />
            <img src="/images/stay-updated-mob.png" className='hide mob-show' style={{ margin: '0px -20px -50px -20px' }} width={"375"} alt="" />
          </div>
        </div>
      </section>
      <section className='darker pb150 mob-pb-100'>

        <PromoBlock>
          <p className='mb36 sato36 font500 mob-font-24'>{t("An all-new platform.")}<br />
            {t("An all-new world for security teams.")}</p>
        </PromoBlock>
      </section>
      <Footer theme={THEMES.dark} />
    </>
  )
}