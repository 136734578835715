export const en = {
  "Welcome": "Welcome",

  'Products': 'Products',
  'Solutions': 'Solutions',
  'Why Cyberlift': 'Why Cyberlift',
  'Pricing': 'Pricing',

  'AI-powered cyber attack simulation': 'AI-powered cyber attack simulation',
  'Make employees Smarter than Attackers': 'Make employees Smarter than Attackers',
  'Security awareness trainings': 'Security awareness trainings',
  'Hundreds of security awareness trainings in one place': 'Hundreds of security awareness trainings in one place',
  'Email threat report button': 'Email threat report button',
  'Introducing Phishing email reporting button': 'Introducing Phishing email reporting button',
  'Intelligent threat response': 'Intelligent threat response',
  'Gamification platform': 'Gamification platform',
  'Engage employees in security with a gamification experience': 'Engage employees in security with a gamification experience',

  'Social engineering prevention': 'Social engineering prevention',
  'Shield your organization from social engineering attacks': 'Shield your organization from social engineering attacks',
  'Behavioral change and employee engagement': 'Behavioral change and employee engagement',
  'Transform your cybersecurity culture': 'Transform your cybersecurity culture',
  'Comprehensive cybersecurity education': 'Comprehensive cybersecurity education',
  'Stay ahead with comprehensive cybersecurity education': 'Stay ahead with comprehensive cybersecurity education',
  'Proactive risk assessment and management': 'Proactive risk assessment and management',
  'Anticipate and mitigate cyber risks before they become threats': 'Anticipate and mitigate cyber risks before they become threats',
  'Global workforce defense': 'Global workforce defense',
  'Defend your global workforce from cyber threats': 'Defend your global workforce from cyber threats',
  'Achieve and maintain robust compliance with ISO 27001, PCI, HIPPA, GDPR, SOC 2, NIS 2 regulations': 'Achieve and maintain robust compliance with ISO 27001, PCI, HIPPA, GDPR, SOC 2, NIS 2 regulations',

  'Sign in': 'Sign in',
  'Sign up': 'Sign up',

  'Company': 'Company',

  'About us': 'About us',
  'Contacts': 'Contacts',
  'Privacy policy': 'Privacy policy',
  'Terms of Use': 'Terms of Use',
  'Data Processing Agreement': 'Data Processing Agreement',

  'Security Awareness Trainings': 'Security Awareness Trainings',
  'AI Assistant for Security Awareness': 'AI Assistant for Security Awareness',
  'Email Threat Report Button': 'Email Threat Report Button',
  'Intelligent Threat Response': 'Intelligent Threat Response',

  'Get started': 'Get started',
  'Learn More': 'Learn More',
  'Request a demo': 'Request a demo ',


  header: {
    signIn: '',
    signUp: '',
    products: '',
    solutions: '',
    'Why Cyberlift': 'Why Cyberlift',
    Pricing: 'Pricing',

  },
  home: {
    title: 'Minimize Human-Related Cyber Risks',
    subtitle: 'Cyberlift is an automated Human Risk Management platform that leverages AI and behavioral science to combat social engineering attacks.',
    setction2title: 'Cyberlift will help you combat the increasing threat landscape:',
    setction2text1: 'of Cyber Incidents Begin with Email',
    setction2text2: 'of Data Breaches Involve Human Error',
    setction2text3: 'Annual Increase in AI-Powered Cyber Attacks',
    setction2text4: 'Average Cost of a Data Breach',

    section3title: 'Traditional Security Awareness training is no longer effective. Cyberlift enhances your security team with AI-driven insights, enabling the prediction and mitigation of risks at an individual level.',


    'Access to sensitive systems': 'Access to sensitive systems',
    'Analyzing Adam co-workers': 'Analyzing Adam co-workers',
    'Matchmaking phishing trends to personalize simulations': 'Matchmaking phishing trends to personalize simulations',
    'Launching anonymous simulations for audit': 'Launching anonymous simulations for audit',
    'Detect Adam vulnerability patterns, risks #passwords, #servers, #bitbucket': 'Detect Adam vulnerability patterns, risks #passwords, #servers, #bitbucket',
    'spoofing simulation test #bitbucket, #passwords, #co-worker request': 'spoofing simulation test #bitbucket, #passwords, #co-worker request',
    'Intention to report simulation': 'Intention to report simulation',
    'Microtraining engagement': 'Microtraining engagement',
    'Knowledge test': 'Knowledge test',

  },
  'Engineering team, Australia remote': 'Engineering team, Australia remote',

  'Automated and personalized attack simulation': 'Automated and personalized attack simulation',
  'Our AI generates multi-step simulation scenarios and templates, delivering targeted attacks to each user via email, Slack, or Teams, effectively reducing individual human risk.': 'Our AI generates multi-step simulation scenarios and templates, delivering targeted attacks to each user via email, Slack, or Teams, effectively reducing individual human risk.',
  'Cyberlift\'s AI assistant creates unique, multilingual training content tailored to the specific context of your organization and employees.It automatically identifies training needs, delivers training via Slack/Teams, and helps reduce risks.': 'Cyberlift\'s AI assistant creates unique, multilingual training content tailored to the specific context of your organization and employees. It automatically identifies training needs, delivers training via Slack/Teams, and helps reduce risks.',
  'GDPR, NIS2, ISO27001, HIPAA, SOC2, PCI DSS.': 'GDPR, NIS2, ISO27001, HIPAA, SOC2, PCI DSS.',
  'Email threat reporting button': 'Email threat reporting button',
  'Boost employee reporting rates with the Cyberlift plugin button in Outlook and Gmail. This AI assistant actively displays phishing indicators for real threats, enabling prompt identification and reporting for further investigation.': 'Boost employee reporting rates with the Cyberlift plugin button in Outlook and Gmail. This AI assistant actively displays phishing indicators for real threats, enabling prompt identification and reporting for further investigation.',
  'Get faster view of reported threats and resolve them automatically. Identify and manage human-related threats through already made integrations with other working and security tools.': 'Get faster view of reported threats and resolve them automatically. Identify and manage human-related threats through already made integrations with other working and security tools.',
  'Gamification platform for employees': 'Gamification platform for employees',
  'Supercharge your employees with a gamification platform that changes human behavior through an interactive security journey.': 'Supercharge your employees with a gamification platform that changes human behavior through an interactive security journey.',
  'Combining human intelligence and training performance, Cyberlift empowers security teams with data that helps to predict and prevent cyber incidents.': 'Combining human intelligence and training performance, Cyberlift empowers security teams with data that helps to predict and prevent cyber incidents.',
  'Overall company': 'Overall company',
  'Human Risk Score': 'Human Risk Score',
  'Risk Category': 'Risk Category',
  'Moderate risk': 'Moderate risk',
  'Risk Rating By Dept': 'Risk Rating By Dept',
  'Transform your employees into Human firewall today': 'Transform your employees into Human firewall today',
  'Get started with Cyberlift.': 'Get started with Cyberlift.',
  'Automated Phishing simulation': 'Automated Phishing simulation',
  'The smartest way to secure your organization from social engineering attacks is to train employees with real life exercises powered with behavioral science and AI.': 'The smartest way to secure your organization from social engineering attacks is to train employees with real life exercises powered with behavioral science and AI.',
  '93% of all cyber incidents start with an email': '93% of all cyber incidents start with an email',
  'Spam doesn’t work': 'Spam doesn’t work',
  'Phishing attacks employ advanced tactics, encryption, and personalization, making it difficult for traditional spam filters to effectively detect and block them.': 'Phishing attacks employ advanced tactics, encryption, and personalization, making it difficult for traditional spam filters to effectively detect and block them.',
  'Frequent': 'Frequent',
  'Each 6 second business faces an email based attack. It takes an average of 308 days to identify and contain a phishing.': 'Each 6 second business faces an email based attack. It takes an average of 308 days to identify and contain a phishing.',
  'Costly': 'Costly',
  'The average business email attack costs organizations $4,3M and reputational damages.': 'The average business email attack costs organizations $4,3M and reputational damages.',
  'Cyberlift trains employees with email attacks simulations': 'Cyberlift trains employees with email attacks simulations',
  'Real-life exercises enable individual learning path to identify & report real attacks.': 'Real-life exercises enable individual learning path to identify & report real attacks.',
  'Evaluate the vulnerability with anonymous audit': 'EVALUATE THE VULNERABILITY WITH ANONYMOUS AUDIT',
  'Cyberlift sends first simulations anonymously within the organization, providing a comprehensive evaluation of each individual`s strengths and weaknesses. Discover how your team will react facing a phishing attack.': 'Cyberlift sends first simulations anonymously within the organization, providing a comprehensive evaluation of each individual’s strengths and weaknesses. Discover how your team will react facing a phishing attack.',
  '100% automated simulations': '100% AUTOMATED SIMULATIONS',
  'Boost security awareness in to next level with fully automation. Cyberlift launches automatically personalized simulation for each inidividual employee. Training individuals, not groups Cyberlift automatically selects type of attack based on weakest risks.': 'Boost security awareness in to next level with fully automation. Cyberlift launches automatically personalized simulation for each inidividual employee. Training individuals, not groups Cyberlift automatically selects type of attack based on weakest risks.',
  'personalized for each person, not group': 'PERSONALIZED FOR EACH PERSON, NOT GROUP',
  'Our model designs & launches automatically individualized training plans, based on their evaluation results, location, department, role, language and many other factors. This level of personalization ensures that each employee receives the most relevant and effective training': 'Our model designs & launches automatically individualized training plans, based on their evaluation results, location, department, role, language and many other factors. This level of personalization ensures that each employee receives the most relevant and effective training',
  'localized templates': 'LOCALIZED TEMPLATES',
  'Because attackers use AI/ML technologies to launch targeted attacks, Cyberlift AI designed several steps ahead. Cyberlift in real-time analyzes all new incidents in different regions and automatically adds new scenarios to the training database to stay educated with all current trends.': 'Because attackers use AI/ML technologies to launch targeted attacks, Cyberlift AI designed several steps ahead. Cyberlift in real-time analyzes all new incidents in different regions and automatically adds new scenarios to the training database to stay educated with all current trends.',
  'educate with bite-sized microtrainings': 'EDUCATE WITH BITE-SIZED MICROTRAININGS',
  'Cyberlift enhances individual capabilities through prompt micro-training sessions tailored to real-time responses, promoting sustained engagement and optimal email practices. Microtrainings boost security awareness faster than other types of content.': 'Cyberlift enhances individual capabilities through prompt micro-training sessions tailored to real-time responses, promoting sustained engagement and optimal email practices. Microtrainings boost security awareness faster than other types of content.',
  'adaptive learning path': 'ADAPTIVE LEARNING PATH',
  'Cyberlift core system integrates behavioral science principles into creation and implementation of personalized simulations based on risk.': 'Cyberlift core system integrates behavioral science principles into creation and implementation of personalized simulations based on risk.',
  'Our method is based on Adaptive learning and Motivation, where system dynamically adjust the difficulty and complexity of simulations based on each user`s skill level and progress. This adaptive learning approach ensures that users are consistently challenged, helping them improve their security awareness and response to phishing attacks faster than ever before.': 'Our method is based on Adaptive learning and Motivation, where system dynamically adjust the difficulty and complexity of simulations based on each user’s skill level and progress. This adaptive learning approach ensures that users are consistently challenged, helping them improve their security awareness and response to phishing attacks faster than ever before.',
  'Improve the reporting rate from day one': 'Improve the reporting rate from day one',
  'Reporting rate is the most important and fundamental metric. When technologies fail-people should react. Cyberlift attack simulations improve effectively the way how people fast can identify and report threats inside their inboxes.': 'Reporting rate is the most important and fundamental metric. When technologies fail- people should react. Cyberlift attack simulations improve effectively the way how people fast can identify and report threats inside their inboxes.',
  'Start boosting your security!': 'Start boosting your security!',
  'Move beyond traditional phishing tests and manual tools. Because phishing is an ever-changing threat, educating your team can’t be a one-time exercise.': 'Move beyond traditional phishing tests and manual tools. Because phishing is an ever-changing threat, educating your team can’t be a one-time exercise.',
  'Essential content platform covering all security aspects': 'Essential content platform covering all security aspects',
  'Security content library': 'Security content library',
  'Tons of security topics that cover all your bases.  Privacy, Device security, Working remotely, Malware.': 'Tons of security topics that cover all your bases.  Privacy, Device security, Working remotely, Malware.',
  'Compliance content library': 'Compliance content library',
  'Automated security awareness tailored to your compliance needs.': 'Automated security awareness tailored to your compliance needs.',










  "Here’s how simple it works": "Here’s how simple it works",
  "Explore and prioritize topics": "Explore and prioritize topics",
  "Set up the most relevant awareness topics by your rules. Courses customized for each individual employee and sent during all work periods.": "Set up the most relevant awareness topics by your rules. Courses customized for each individual employee and sent during all work periods.",
  "Create in seconds training packages": "Create in seconds training packages",
  "Choose training modules, set up rules and launch trainings across all your company departments.": "Choose training modules, set up rules and launch trainings across all your company departments.",
  "Track the progress with smart reporting": "Track the progress with smart reporting",
  "Follow in real time employees performance. Know which business unit perform better across your organization with our data driven reporting.": "Follow in real time employees performance. Know which business unit perform better across your organization with our data driven reporting.",
  "Reasons to use Cyberlift for your trainings": "Reasons to use Cyberlift for your trainings",
  "Automation": "Automation",
  "Trainings are delivered automatically based on your preferences and needs of employee lifetime.": "Trainings are delivered automatically based on your preferences and needs of employee lifetime.",
  "Personalized by your rules": "Personalized by your rules",
  "Launch training packages based on your preferences. Set-up specific rules, create groups and track the progress.": "Launch training packages based on your preferences. Set-up specific rules, create groups and track the progress.",
  "Built in microlearning framework": "Built in microlearning framework",
  "Forget about sending long- read pdf’s or videos that employees ignore. Our method is to educate employees with bite-sized information with several cards & quiz.": "Forget about sending long- read pdf’s or videos that employees ignore. Our method is to educate employees with bite-sized information with several cards & quiz.",
  "Magical experience with gamification platform": "Magical experience with gamification platform",
  "Save up 90 % of your time with our engagement platform. We want employees to stay motivated across their journey in Cyberlift gamified platform. Employees are earning stars and get beautiful achievements at different levels.": "Save up 90 % of your time with our engagement platform. We want employees to stay motivated across their journey in Cyberlift gamified platform. Employees are earning stars and get beautiful achievements at different levels.",
  "Optimize compliance procedures": "Optimize compliance procedures",
  "Cyberlift security trainings modules cover compliance needs for modern business. Set up trainings based on your certifications and regulatory requirements across different countries and regulations.": "Cyberlift security trainings modules cover compliance needs for modern business. Set up trainings based on your certifications and regulatory requirements across different countries and regulations.",
  "Fully integrated platform": "Fully integrated platform",
  "Experience a streamlined user and admin interface that consolidates all your data into a single, centralized place.": "Experience a streamlined user and admin interface that consolidates all your data into a single, centralized place.",
  "The knowledge you need to get to stay tuned with the new era of security.": "The knowledge you need to get to stay tuned with the new era of security.",
  "Introducing Phishing email": "Introducing Phishing email",
  "reporting button": "reporting button",
  "Cyberlift plugin button empowers employees to report phishing emails and other suspicious messages with one click directly to the security admin.": "Cyberlift plugin button empowers employees to report phishing emails and other suspicious messages with one click directly to the security admin.",
  "Powerful Action button inside your inbox": "Powerful Action button inside your inbox",
  "For Google": "For Google",
  "Workspace users": "Workspace users",
  "For Microsoft": "For Microsoft",
  "365 users": "365 users",
  "Faster Email reporting": "Faster Email reporting",
  "It’s important to identify and stop phishing attacks inside the inbox. Cyberlift products educate employees to report suspicious emails faster than ever before.": "It’s important to identify and stop phishing attacks inside the inbox. Cyberlift products educate employees to report suspicious emails faster than ever before.",
  "308 days": "308 days",
  "Without Cyberlift": "Without Cyberlift",
  "With Cyberlift": "With Cyberlift",
  "Here’s how fast it works": "Here’s how fast it works",
  "Identify": "Identify",
  "a suspicious email Based on our automated & personalized attack simulations employees increase security awareness with a goal to identify potential email based threats.": "a suspicious email Based on our automated & personalized attack simulations employees increase security awareness with a goal to identify potential email based threats.",
  "Mark": "Mark",
  "as Spam or Suspicious email In a case if an employee clicks Spam email automatically removes to spam. In a case if an employee clicks a Suspicious email then it will be reported to the security team for further investigation.": "as Spam or Suspicious email In a case if an employee clicks Spam email automatically removes to spam. In a case if an employee clicks a Suspicious email then it will be reported to the security team for further investigation.",
  "Report": "Report",
  "and inform security team with action made before When an employee wants to report this email they can choose the action that has been made. If it was Cyberlift’s simulation employee will get a reward. If it wasn’t the Security team will get this email into the Cyberlift’d Admin panel for further threat investigation.": "and inform security team with action made before When an employee wants to report this email they can choose the action that has been made. If it was Cyberlift’s simulation employee will get a reward. If it wasn’t the Security team will get this email into the Cyberlift’d Admin panel for further threat investigation.",
  "Revolutionary performance.": "Revolutionary performance.",
  "Increase employee threat response from day one": "Increase employee threat response from day one",
  "Cyberlift helps organizations and their employees to build a stable security culture. When technologies fail-people should act with a simple scenario to stay protected from the incident.": "Cyberlift helps organizations and their employees to build a stable security culture. When technologies fail - people should act with a simple scenario to stay protected from the incident.",
  "It’s important to understand which reported user emails are malicious. And it’s the key to quickly removing active phishing and spear phishing attacks. Get reported emails from employees directly in your admin panel.": "It’s important to understand which reported user emails are malicious. And it’s the key to quickly removing active phishing and spear phishing attacks. Get reported emails from employees directly in your admin panel.",
  "Detect on real-time critical incident": "Detect on real-time critical incident",
  "Centralize threat reporting from all organization in one place.": "Centralize threat reporting from all organization in one place.",
  "Metrics": "Metrics",
  "With Cyberlift security awareness your employees' security actions performance will grow. Improve detection rates metrics and reporting metrics from day one.": "With Cyberlift security awareness your employees' security actions performance will grow. Improve detection rates metrics and reporting metrics from day one.",
  "+ detect rate": "+ detect rate",
  "reporting rate": "reporting rate",
  "Gamification Platform": "Gamification Platform",
  "A gamification platform designed to supercharge employees < users > with interactive content and actions by collecting beautiful achievements and showing long-term impact of the learning security.": "A gamification platform designed to supercharge employees < users > with interactive content and actions by collecting beautiful achievements and showing long-term impact of the learning security.",
  "Get Started": "Get Started",
  "For collecting": "For collecting",
  "bonuses & achievements": "bonuses & achievements",
  "For security awareness trainings": "For security awareness trainings",
  "Increase employee engagement in to security": "Increase employee engagement in to security",
  "Move beyond traditional security tests and trainings with personalized security awareness trainings powered by behavioral science and AI.": "Move beyond traditional security tests and trainings with personalized security awareness trainings powered by behavioral science and AI.",
  "Gamified experience": "Gamified experience",
  "Cyberlift motivates employees to learn security using gamification principles. For each action the user collects rewards and tracks his personal security impact.": "Cyberlift motivates employees to learn security using gamification principles. For each action the user collects rewards and tracks his personal security impact.",
  "Bahaviour change": "Bahaviour change",
  "Cyberlift gamification platform changes employees behaviour with a solution that reward person to identify & report phishing emails. While hackers use fear for social engineering, cyberlift make the process positive and impactful through collecting rewards.": "Cyberlift gamification platform changes employees behaviour with a solution that reward person to identify & report phishing emails. While hackers use fear for social engineering, cyberlift make the process positive and impactful through collecting rewards.",
  "Smart reporting": "Smart reporting",
  "Cyberlift plugin button empowers employees to report phishing emails and other suspicious messages faster to the security team with one click.": "Cyberlift plugin button empowers employees to report phishing emails and other suspicious messages faster to the security team with one click.",
  "of security professionals feel inadequately prepared to defend their oraganization": "of security professionals feel inadequately prepared to defend their oraganization",
  "of security professionals don’t believe their organizations provides the right training": "of security professionals don’t believe their organizations provides the right training",
  "of professionals claim their organization is impacted by the security skills shortage": "of professionals claim their organization is impacted by the security skills shortage",
  "Social Engineering Prevention": "Social Engineering Prevention",
  "Stay ahead of social engineering attempts and protect your sensitive data by enabling your team with the right knowledge and training.": "Stay ahead of social engineering attempts and protect your sensitive data by enabling your team with the right knowledge and training.",
  "of Cyber Attacks Are Targeting Your Employees Instead of Your Tech": "of Cyber Attacks Are Targeting Your Employees Instead of Your Tech",
  "Today hackers use AI / ML for much complexed, personalized attacks %": "Today hackers use AI / ML for much complexed, personalized attacks %",
  "$4.1M": "$4.1M",
  "Million The Price of the Average Social Engineering-Related Breach": "Million The Price of the Average Social Engineering-Related Breach",
  "Thousands of Simulation Scenarios": "Thousands of Simulation Scenarios",
  "From spear phishing emails to voice and text messages, and deceptive web pages, CyberLift covers all types of social engineering attacks in its simulations, ensuring your team is prepared for any threat.": "From spear phishing emails to voice and text messages, and deceptive web pages, CyberLift covers all types of social engineering attacks in its simulations, ensuring your team is prepared for any threat.",
  "Skills Development for Threat Detection": "Skills Development for Threat Detection",
  "As social engineering schemes evolve in complexity, we empower your team with the essential skills to swiftly identify and neutralize these potential threats.": "As social engineering schemes evolve in complexity, we empower your team with the essential skills to swiftly identify and neutralize these potential threats.",
  "Real-Time Feedback for Improvement": "Real-Time Feedback for Improvement",
  "Benefit from immediate, detailed feedback on every interaction within our social-engineering simulations, fostering a cycle of continuous learning and enhancement in your team's security competencies.": "Benefit from immediate, detailed feedback on every interaction within our social-engineering simulations, fostering a cycle of continuous learning and enhancement in your team's security competencies.",
  "AI-Assisted Vulnerability Assessment": "AI-Assisted Vulnerability Assessment",
  "Leverage our AI-powered risk analysis to pinpoint your organization's vulnerabilities and devise effective mitigation strategies.": "Leverage our AI-powered risk analysis to pinpoint your organization's vulnerabilities and devise effective mitigation strategies.",
  "An all-new platform.": "An all-new platform.",
  "An all-new world for security teams.": "An all-new world for security teams.",
  "Behavioral Change and Employee Engagement": "Behavioral Change and Employee Engagement",
  "Empower your workforce to become your strongest defense line by fostering a culture of cybersecurity awareness and engagement.": "Empower your workforce to become your strongest defense line by fostering a culture of cybersecurity awareness and engagement.",
  "Get started ": "Get started ",
  "Reduce human cyber risks in a single platform": "Reduce human cyber risks in a single platform",
  "Gamification platform for engaging cybersecurity training": "Gamification platform for engaging cybersecurity training",
  "Maximizes employee involvement for complete training participation": "Maximizes employee involvement for complete training participation",
  "Organizations boosting security culture experience a significant reduction in cyber incidents": "Organizations boosting security culture experience a significant reduction in cyber incidents",
  "Foster a proactive cybersecurity culture": "Foster a proactive cybersecurity culture",
  "Our programs are designed to foster an active security culture, encouraging employees to take responsibility for their own and the organization's cybersecurity.": "Our programs are designed to foster an active security culture, encouraging employees to take responsibility for their own and the organization's cybersecurity.",
  "Gamified learning experience": "Gamified learning experience",
  "Our gamified learning experiences make cybersecurity education engaging and fun, improving knowledge retention and fostering positive behavior change.": "Our gamified learning experiences make cybersecurity education engaging and fun, improving knowledge retention and fostering positive behavior change.",
  "Personalized learning paths": "Personalized learning paths",
  "CyberLift provides personalized learning paths for each employee, ensuring that each person's unique learning needs are met.": "CyberLift provides personalized learning paths for each employee, ensuring that each person's unique learning needs are met.",
  "Comprehensive Cybersecurity Education": "Comprehensive Cybersecurity Education",
  "Equip your team with the knowledge they need to spot, report, and prevent cyber threats.": "Equip your team with the knowledge they need to spot, report, and prevent cyber threats.",
  "hours of cybersecurity education available": "hours of cybersecurity education available",
  "of employees report improved understanding of cyber threats after completing the program": "of employees report improved understanding of cyber threats after completing the program",
  "up to": "up to",
  "reducing average threat report time": "reducing average threat report time",
  "Tailored cybersecurity curriculum": "Tailored cybersecurity curriculum",
  "Our AI-powered platform designs a tailored cybersecurity curriculum for each employee based on their role, knowledge level, and learning style.": "Our AI-powered platform designs a tailored cybersecurity curriculum for each employee based on their role, knowledge level, and learning style.",
  "On-demand learning": "On-demand learning",
  "Access a rich library of cybersecurity resources, available to fit the busy schedules of your employees.": "Access a rich library of cybersecurity resources, available to fit the busy schedules of your employees.",
  "Track progress and measure impact": "Track progress and measure impact",
  "Track your team's progress and measure the impact of your cybersecurity training programs with our detailed analytics and reports.": "Track your team's progress and measure the impact of your cybersecurity training programs with our detailed analytics and reports.",
  "Stay updated on latest threats": "Stay updated on latest threats",
  "Our curriculum is constantly updated to include the latest cyber threats and prevention strategies, ensuring your team is always equipped with the most up-to-date knowledge.": "Our curriculum is constantly updated to include the latest cyber threats and prevention strategies, ensuring your team is always equipped with the most up-to-date knowledge.",
  "Proactive Risk Assessment and Management": "Proactive Risk Assessment and Management",
  "AI-powered risk assessment": "AI-powered risk assessment",
  "Our AI-powered technology helps you identify potential vulnerabilities and assess the level of risk associated with each one.": "Our AI-powered technology helps you identify potential vulnerabilities and assess the level of risk associated with each one.",
  "True risk": "True risk",
  "Continual monitoring and updates": "Continual monitoring and updates",
  "We provide continuous monitoring and risk updates, enabling you to take immediate action on emerging threats.": "We provide continuous monitoring and risk updates, enabling you to take immediate action on emerging threats.",
  "Comprehensive risk reports": "Comprehensive risk reports",
  "Get detailed risk reports with actionable insights to help you prioritize and mitigate your organization's cybersecurity risks.": "Get detailed risk reports with actionable insights to help you prioritize and mitigate your organization's cybersecurity risks.",
  "Phishing Simulation": "Phishing Simulation",
  "Stay compliant with regulations": "Stay compliant with regulations",
  "Our platform helps you stay compliant with international cybersecurity regulations by ensuring your risk assessment processes are in line with global standards.": "Our platform helps you stay compliant with international cybersecurity regulations by ensuring your risk assessment processes are in line with global standards.",
  "Global Workforce Defense": "Global Workforce Defense",
  "Secure your organization's sensitive data no matter": "Secure your organization's sensitive data no matter",
  "where your employees are located with our global": "where your employees are located with our global",
  "workforce defense solution.": "workforce defense solution.",
  "Global": "Global",
  "Coverage": "Coverage",
  "With coverage in over 100 countries, we've got your global workforce covered against cyber threats.": "With coverage in over 100 countries, we've got your global workforce covered against cyber threats.",
  "Global threat": "Global threat",
  "intelligence": "intelligence",
  "Stay informed about the latest global cyber threats and use these insights to protect your organization.": "Stay informed about the latest global cyber threats and use these insights to protect your organization.",
  "Scalability": "Scalability",
  "Our solutions support a wide range of business sizes, from SMEs to multinational": "Our solutions support a wide range of business sizes, from SMEs to multinational",
  "corporations, ensuring adaptable and scalable cyber defense strategies.": "corporations, ensuring adaptable and scalable cyber defense strategies.",
  "Training in local languages": "Training in local languages",
  "Provide cybersecurity training in your employees' native languages, improving their understanding and application of key concepts.": "Provide cybersecurity training in your employees' native languages, improving their understanding and application of key concepts.",
  "Privacy and Compliance Across Borders": "Privacy and Compliance Across Borders",
  "We navigate the complexities of cross-border data privacy laws and regulations, ensuring your global workforce operates within the necessary legal frameworks.": "We navigate the complexities of cross-border data privacy laws and regulations, ensuring your global workforce operates within the necessary legal frameworks.",
  "Navigate the complex landscape of global data protection and privacy regulations confidently, ensuring your business remains compliant while prioritizing data security.": "Navigate the complex landscape of global data protection and privacy regulations confidently, ensuring your business remains compliant while prioritizing data security.",
  "Comprehensive Compliance Training": "Comprehensive Compliance Training",
  "Equip your team with the knowledge and tools they need to understand and adhere to global compliance standards, ensuring a culture of proactive compliance within your organization.": "Equip your team with the knowledge and tools they need to understand and adhere to global compliance standards, ensuring a culture of proactive compliance within your organization.",
  "Automated Compliance Reporting": "Automated Compliance Reporting",
  "Save time and resources with automated compliance reports that ensure you're always audit-ready and help you identify areas for improvement.": "Save time and resources with automated compliance reports that ensure you're always audit-ready and help you identify areas for improvement.",
  "Real-time Compliance Monitoring": "Real-time Compliance Monitoring",
  "With continuous monitoring, get alerted to potential non-compliance issues in real-time and take immediate action to stay compliant.": "With continuous monitoring, get alerted to potential non-compliance issues in real-time and take immediate action to stay compliant.",
  "AI-Powered Compliance Risk Assessment": "AI-Powered Compliance Risk Assessment",
  "Use our AI-powered risk assessment to identify compliance vulnerabilities and devise effective strategies to address them.": "Use our AI-powered risk assessment to identify compliance vulnerabilities and devise effective strategies to address them.",
  "Regulatory Updates": "Regulatory Updates",
  "Stay abreast of updates and changes with regular notifications and resources to help you understand their impact.": "Stay abreast of updates and changes with regular notifications and resources to help you understand their impact.",
  "Behavioral Scoring for Compliance": "Behavioral Scoring for Compliance",
  "Leverage our platform's individual behavioral compliance scoring system, enabling you to identify employees who might need additional training or resources to ensure compliance.": "Leverage our platform's individual behavioral compliance scoring system, enabling you to identify employees who might need additional training or resources to ensure compliance.",
  "Switch to Cyberlift today": "Switch to Cyberlift today",
  "Cyberlift offers more than just a security platform. It's a game-changing combination of advanced AI, deep learning, and human behavior change solutions.": "Cyberlift offers more than just a security platform. It's a game-changing combination of advanced AI, deep learning, and human behavior change solutions.",
  "Features": "Features",
  "Cyberlift": "Cyberlift",
  "Others": "Others",
  "Fully Automated Creation and Sending of Simulations": "Fully Automated Creation and Sending of Simulations",
  "Social engineering simulations": "Social engineering simulations",
  "Customized approach per each individual user": "Customized approach per each individual user",
  "AI-powered voice phishing simulations": "AI-powered voice phishing simulations",
  "Global coverage with multilingual content": "Global coverage with multilingual content",
  "Global Coverage": "Global Coverage",
  "Trainings modules for Various Security Certifications(ISO / IEC 27001, SOC 2, HIPAA, GDPR, PCI DSS)": "Trainings modules for Various Security Certifications(ISO / IEC 27001, SOC 2, HIPAA, GDPR, PCI DSS)",
  "AI-powered security co-pilot for social engineering analysis": "AI-powered security co-pilot for social engineering analysis",
  "Automated Threat Risk Evaluation and Escalation": "Automated Threat Risk Evaluation and Escalation",
  "Gamification platform for users": "Gamification platform for users",
  "Rewards & achievements": "Rewards & achievements",
  "Easy LDAP Integration for Automatic Team Synchronization": "Easy LDAP Integration for Automatic Team Synchronization",
  "Reporting Employee Performance Progress to Relevant Team Members": "Reporting Employee Performance Progress to Relevant Team Members",
  "Dedicated API for reported threats management": "Dedicated API for reported threats management",
  "Dynamic rules set-up for trainings packages": "Dynamic rules set-up for trainings packages",
  "Seamless Adaptation to Company’s security policy": "Seamless Adaptation to Company’s security policy",
  "It’s time to rethink how to": "It’s time to rethink how to",
  "mitigate your biggest": "mitigate your biggest",
  "security risks.": "security risks.",
  "Predict and prevent email": "Predict and prevent email",
  "based attacks with AI": "based attacks with AI",
  "- powered solutions.": "- powered solutions.",
  "Let’s set up a meeting to choose a pricing plan tailored to your business needs.": "Let’s set up a meeting to choose a pricing plan tailored to your business needs.",
  "Localized platform with 10+ languages": "Localized platform with 10+ languages",
  "AI-powered engine for automated & personalized experience": "AI-powered engine for automated & personalized experience ",
  "24/7 customer support": "24/7 customer support",
  "Unlimited users on platform": "Unlimited users on platform",
  "Auto-synchronized with your security policies": "Auto-synchronized with your security policies ",
  "Easy user management by department, groups and roles": "Easy user management by department, groups and roles",
  "First name": "First name",
  "Last name": "Last name",
  "Work email": "Work email",
  "Company name": "Company name",
  "Number of employees": "Number of employees",
  "By clicking the button you agree": "By clicking the button you agree",
  "with our": "with our",
  "Privacy Policy": "Privacy Policy",
  "Request quote": "Request quote",
  "APP prevention": "APP prevention",
  "Protect Your Organization Against Authorized Push Payment Fraud.": "Protect Your Organization Against Authorized Push Payment Fraud.",
  "Cyberlift helps to reduce Authorized Push Payment(APP) fraud by up to 50 % of potential losses through automatic and engaging security awareness training for your end customers": "Cyberlift helps to reduce Authorized Push Payment(APP) fraud by up to 50 % of potential losses through automatic and engaging security awareness training for your end customers",
  "APP risk prevention should be the highest priority for executives": "APP risk prevention should be the highest priority for executives",
  "of all reported crimes in England and Wales are accounted for by fraud": "of all reported crimes in England and Wales are accounted for by fraud",
  "£485.2 m": "£485.2 m",
  "represents the total amount lost to APP fraud in the UK in 2022.": "represents the total amount lost to APP fraud in the UK in 2022.",
  "of fraud losses are expected to be covered by financial institutions under new PSR reimbursement requirements.": "of fraud losses are expected to be covered by financial institutions under new PSR reimbursement requirements.",
  "Security Awareness Training for your end customers is an essential part of reducing potential losses in the near term.": "Security Awareness Training for your end customers is an essential part of reducing potential losses in the near term.",
  "Mitigate APP Fraud Risks": "Mitigate APP Fraud Risks",
  "Equip your customers with the necessary knowledge and skills to significantly reduce the likelihood of falling victim to APP fraud with Cyberlift.": "Equip your customers with the necessary knowledge and skills to significantly reduce the likelihood of falling victim to APP fraud with Cyberlift.",
  "Build Trust and Loyalty": "Build Trust and Loyalty",
  "Demonstrate your commitment to customer security, enhancing trust and reinforcing your reputation as a safe and responsible financial institution through Cyberlift's solutions.": "Demonstrate your commitment to customer security, enhancing trust and reinforcing your reputation as a safe and responsible financial institution through Cyberlift's solutions.",
  "Ensure Regulatory Compliance": "Ensure Regulatory Compliance",
  "With Cyberlift, meet and exceed the requirements set forth for APP fraud prevention and customer reimbursement, avoiding penalties and reinforcing customer confidence.": "With Cyberlift, meet and exceed the requirements set forth for APP fraud prevention and customer reimbursement, avoiding penalties and reinforcing customer confidence.",
  "Adaptable and Scalable Solutions": "Adaptable and Scalable Solutions",
  "Cyberlift offers adaptable and scalable solutions that cater to both small local banks and large multinational institutions, meeting diverse needs in the evolving landscape of online fraud prevention.": "Cyberlift offers adaptable and scalable solutions that cater to both small local banks and large multinational institutions, meeting diverse needs in the evolving landscape of online fraud prevention.",
  "How it works ?": "How it works ?",
  "Change consumers security Behavior and reduce Risk": "Change consumers security Behavior and reduce Risk",
  "Cyberlift assigns short, gamified security training modules to increase engagement and build long-term security behavior. Our AI-powered engine personalizes and delivers frequent security training and simulations to educate your end consumers on the most advanced payment fraud tactics": "Cyberlift assigns short, gamified security training modules to increase engagement and build long-term security behavior. Our AI-powered engine personalizes and delivers frequent security training and simulations to educate your end consumers on the most advanced payment fraud tactics",
  "Personalize trainings with AI": "Personalize trainings with AI",
  "Cyberlift's AI engine generates a unique profile for every user and automatically delivers the most relevant training based on language, location, and current fraud trends. Training modules are crafted by experts in 30+ languages and can be customized according to your specific rules. Our content library consists of 170 training modules covering the latest trends, such as Smishing, Vishing, Payment fraud, Social engineering, etc.": "Cyberlift's AI engine generates a unique profile for every user and automatically delivers the most relevant training based on language, location, and current fraud trends. Training modules are crafted by experts in 30+ languages and can be customized according to your specific rules. Our content library consists of 170 training modules covering the latest trends, such as Smishing, Vishing, Payment fraud, Social engineering, etc.",
  "Increase engagement with gamification": "Increase engagement with gamification",
  "Cyberlift rewards users with stars and levels to enhance engagement. You can customize it to integrate with your existing rewards and loyalty programs, such as cashback, points, or discounts.": "Cyberlift rewards users with stars and levels to enhance engagement. You can customize it to integrate with your existing rewards and loyalty programs, such as cashback, points, or discounts.",
  "Train consumers with microlearning framework": "Train consumers with microlearning framework",
  "Cyberlift delivers short, bite-sized micro-trainings that reinforce good behavior and transform risks into engaging learning processes. Our method educates users through brief cards and quizzes.": "Cyberlift delivers short, bite-sized micro-trainings that reinforce good behavior and transform risks into engaging learning processes. Our method educates users through brief cards and quizzes.",
  "Orchestrate consumers vulnerabilities data with your Anti-fraud": "Orchestrate consumers vulnerabilities data with your Anti-fraud",
  "Cyberlift leverages real-time analytics to help you quickly understand the impact of the Cyberlift platform, enabling you to promptly reduce risk. Automate and enhance your anti-fraud operations by managing data through the Cyberlift API. Transform your end consumers into a human firewall today.": "Cyberlift leverages real-time analytics to help you quickly understand the impact of the Cyberlift platform, enabling you to promptly reduce risk. Automate and enhance your anti-fraud operations by managing data through the Cyberlift API. Transform your end consumers into a human firewall today.",
  "Integration": "Integration",
  "Easy integration is available for both small businesses and large enterprises, leveraging advanced API integration and a sophisticated cloud infrastructure. Our system operates without collecting any personal information about your customers, utilizing anonymized identifiers for all processes.": "Easy integration is available for both small businesses and large enterprises, leveraging advanced API integration and a sophisticated cloud infrastructure. Our system operates without collecting any personal information about your customers, utilizing anonymized identifiers for all processes.",
  "Implement Cyberlift and Save on your potential losses.": "Implement Cyberlift and Save on your potential losses.",




  "end": "end"
}