import React from 'react'
import Navigation from '../../components/navigation'
import Footer from '../../components/footer'
import { THEMES } from 'src/constatnts'

import './style.css'
import Divider from 'src/components/Divider'
import { useTranslation } from 'react-i18next'

export const AboutPage = () => {
  const { t } = useTranslation()
  return (
    <>
      <Navigation theme={THEMES.dark} />
      <section className='dark heading-section'>
        <div className='container860'>
          <h1 className='text-center mb80 mob-mb-16 mob-sato-32'>{t("About Cyberlift")}</h1>
          <p className='sato26 mob-font14 mb20'>
            {t("Cybertlift is a cyber prevention AI research & deployment company with a mission to stop social engineering attacks using automation and human insights.")}
          </p>
          <p className='sato26 mob-font14 mb100 mob-mb70'>
            {t("Cyberlift creates a human risks management platform providing the ability to control human mistakes and email security in a fully automated way. As a technology research company, Cyberlift is at the forefront of developing new intelligent prevention AI that analyzes & improves human behavior. We deploy the most advanced models to predict human mistakes and vulnerabilities. Cyberlift platform is a fully automated ecosystem that helps security teams and employees stop and identify intelligent email based attacks.")}      </p>
        </div>
        <div className='container1080'>
          <div className='blog-image-container br30 mob-br20'>
            <img src="/images/about-us-illustration-1.jpg" alt="" />
          </div>

        </div>


        <div className='container860'>
          <h2 className='text-center mb80 mob-mb-16 mob-sato-32'>{t("Our mission")}</h2>
          <p className='sato26 mob-font14 mb90'>
            {t("Revolutionize the cybersecurity market with fully automated AI solutions for incidents prevention.")}
          </p>
        </div>

        <div className='container1080'>
          <div className='blog-image-container blog-image-container-high br30 mob-br20'>
            <img src="/images/about-us-illustration-2.jpg" alt="" />
          </div>
        </div>

        <div className='container860'>
          <h2 className='text-center mb80 mob-mb-16 mob-sato-32'>{t("Community")}</h2>
          <p className='sato26 mob-font14 mb20'>
            {t("We are a small team of deep tech engineers, tech founders and security enthusiasts. Community is a goal to share best practices for current technologies and trends.")}
          </p>
          <p className='sato26 mob-font14 mb100 mob-mb70'>
            {t("Join our security enthusiasts community and stay in touch with Cyberlift insights.")}
          </p>

          <h2 className='text-center mb80 mob-mb-16 mob-sato-32'>{t("Support")}</h2>
          <p className='sato26 mob-font14 mb20'>
            {t("To get help with the product and would like to get in touch with, please email us at")} <a style={{ color: '#0066FF' }} href="mailto:">support@cyberlift.ai</a>
          </p>
        </div>
      </section>
      <Divider style={{ margin: 0, background: '#2F2F2F' }}></Divider >
      <Footer theme={THEMES.dark} />
    </>
  )
}