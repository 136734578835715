import React from 'react'

import { Checkbox } from '@mui/material'

const CheckBoxIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='1.5' y='1.5' width='17' height='17' rx='4.5' fill='white' stroke='#D0D0D8' />
  </svg>
)
const CheckBoxIconChecked = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='1' y='1' width='18' height='18' rx='5' fill='#6123FF' />
    <path d='M4.63672 9.79134L8.35016 13.5776L15.3644 6.42578' stroke='white' strokeWidth='1.5' strokeLinecap='round' />
  </svg>
)
const IndeterminateIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='1' y='1' width='18' height='18' rx='5' fill='#6123FF' />
    <path d='M5.12109 10H14.8775' stroke='white' strokeWidth='1.5' strokeLinecap='round' />
  </svg>
)
const CustomCheckbox = props => (
  <Checkbox
    indeterminateIcon={<IndeterminateIcon />}
    checkedIcon={<CheckBoxIconChecked />}
    icon={<CheckBoxIcon />}
    {...props}
  />
)

export default CustomCheckbox
