import React from "react";
import './style.css'

export const BUTTON_TYPES = {
  contained: 'contained',
  outlined: 'outlined',
  link: 'link',
}

export const Button = ({ onClick, variant, children, className, style }) => {
  return (<button onClick={onClick} className={"button " + variant + ' ' + className} style={style}>{children}</button>)
}