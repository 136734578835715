import React from 'react'
import Navigation from '../../components/navigation'
import Footer from '../../components/footer'
import { THEMES } from 'src/constatnts'

import './style.css'
import { Button } from 'src/components/button'
import Divider from 'src/components/Divider'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// import { backdropClasses } from '@mui/material'

export const GamificationPlatformPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <>
      <Navigation style={{ borderBottom: '1px solid #2E2E3C8f', background: '#14141740' }} theme={THEMES.dark} />
      <section className='dark heading-section-small'>
        <div className='container1300'>
          <div className='br30 padding100-110-122 gamigication-section-bcg mob-fullsize mb40'>
            <div className='row'>
              <div className='column column-start mob-column-center'>
                <p className='mb30 mob-text-center mob-font-14'>{t("Gamification Platform")}</p>
                <h1 className='gradient-text-1 mob-text-center mob-sato-32 mb20 mob-mb-60' style={{ maxWidth: '510px' }}>{t("Engage employees in security with a gamification experience")}</h1>
                <p className='mb45 mob-text-center font18 mob-font-14' style={{ maxWidth: '510px' }}>{t("A gamification platform designed to supercharge employees < users > with interactive content and actions by collecting beautiful achievements and showing long-term impact of the learning security.")}</p>
                <Button onClick={() => navigate('/pricing')} variant={'gradient-button'}>{t('Get started')}
                  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill="white" />
                  </svg>
                </Button>
              </div>
            </div>
          </div>

          <div className='row space-between mob-column'>
            <div style={{ height: '100%' }} className='one-of-2 mob-fullwidth mob-mb-20'>
              <div style={{ background: 'radial-gradient(75.16% 51.92% at 118.59% 80.91%, #6D4AFF 0%, rgba(109, 74, 255, 0) 90.62%), radial-gradient(63.9% 63.9% at 15.16% -2.47%, #D459FF 0%, rgba(212, 89, 255, 0) 84.61%)', backgroundColor: '#1D1D29' }} className="column column-center padding-70 br30 mob-br20 ">
                <p className='font36 text-center mob-font22 mb20' style={{ maxWidth: 505 }}>{t("For collecting")} <br /> {t("bonuses & achievements")}</p>
                <div className='img-container-17'>
                  <img src="/images/gamification-platform4.png" className='mob-img mob-hide' width={'640'} alt="" />
                  <img src="/images/gamification-platform4-mob.png" className='mob-img hide mob-show' alt="" />
                </div>
              </div>
            </div>
            <div className='one-of-2 mob-fullwidth'>
              <div style={{ background: '#1D1D29' }} className="column column-center padding-70 br30 mob-br20 ">
                <p className='font36 text-center mob-font22 mb20'>{t("For security awareness trainings")}</p>
                <div className='img-container-18'>
                  <img src="/images/security-awarennes-cards2.png" className='mob-img' width={'587'} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='dark'>
        <div className='container1300'>
          <div className='container860'>
            <h2 className='text-center mb40'>{t("Increase employee engagement in to security")}</h2>
            <p className='text-center mb100 mob-font-14 font22'>{t("Move beyond traditional security tests and trainings with personalized security awareness trainings powered by behavioral science and AI.")}</p>

          </div>

          <div
            style={{
              backgroundColor: '#1D1D29',
              backgroundImage: 'radial-gradient(87.83% 175.83% at 69.77% -108.83%, rgb(109, 74, 255) 0%, rgba(20, 20, 23, 0.1) 100%)', boxShadow: '0px 8px 55px 0px #4C2DBA0F'
            }}
            className='br30 content-box padding-74-100 mob-padding-46-43 mb40'
          >
            <div className='row space-between mob-column'>
              <div className='column'>
                <p className='font32 mob-font22 mb40'>{t("Gamified experience")}</p>
                <p style={{ maxWidth: '409px' }} className='font18 mob-font14'>{t("Cyberlift motivates employees to learn security using gamification principles. For each action the user collects rewards and tracks his personal security impact.")}</p>
              </div>
              <div className='img-container-3'>
                <img width="808" className='mob-hide' src="/images/achievements.png" alt="" />
                <img width="340" className='hide mob-show' src="/images/achievements-mob.png" alt="" />
              </div>
            </div>
          </div>

          <div
            style={{ background: '#1D1D29' }}
            className='br30 content-box padding-74-100 mob-padding-46-43 mb40'>
            <div className='row space-between mob-column'>
              <div className='column'>
                <p className='font32 mb40 mob-font22'>{t("Bahaviour change")}</p>
                <p style={{ maxWidth: '469px' }} className='font18 mob-font14'>{t("Cyberlift gamification platform changes employees behaviour with a solution that reward person to identify & report phishing emails. While hackers use fear for social engineering, cyberlift make the process positive and impactful through collecting rewards.")}</p>
              </div>
              <div className='img-container-28'>
                <img width="532" className='mob-img' src="/images/behavior-change.png" alt="" />
              </div>
            </div>
          </div>

          <div className='row space-between mob-column'>
            <div style={{ background: '#1D1D29' }} className='column br20 one-of-2 mob-fullwidth padding-74-100 mob-padding-46-43 mb20'>
              <p className='font32 mb40 mob-font22'>{t("Smart reporting")}</p>
              <p style={{ maxWidth: '409px' }} className='font18 mob-mb-40 mob-font14'>{t("Cyberlift plugin button empowers employees to report phishing emails and other suspicious messages faster to the security team with one click.")}</p>

              <div className='img-container-27'>
                <img width="500" className='mob-img mob-hide' src="/images/threat-reporting-button-white.png" alt="" />
                <img width="342" className='mob-img hide mob-show' src="/images/threat-reporting-button-white-mob.png" alt="" />
              </div>
            </div>
            <div style={{ background: '#1D1D29' }} className='column br20 one-of-2 mob-fullwidth padding-74-100 mob-padding-46-43 mb20'>
              <p className='font32 mb40 mob-font22 mob-text-center'>{t("Fully integrated platform")}</p>
              <p style={{ maxWidth: '409px' }} className='font18 mob-mb-40 mob-font14 mob-text-center'>{t("Experience a streamlined user and admin interface that consolidates all your data into a single, centralized place.")}</p>
              <div className='img-container-4'>
                <div className='world-background'>
                  <img src="/images/shield.png" alt="" />
                </div>
                {/* <img src="/images/world-shield.png" className='mob-img mob-hide' width="600px" alt="" /> */}
                {/* <img src="/images/world-shield.png" className='mob-img hide mob-show' width="100%" alt="" /> */}

              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='dark pt110 pb150'>
        <div className='container'>
          <div className='column column-center'>
            <h2 className='mb80 mob-text-center'>{t("Increase employee engagement in to security")}</h2>

          </div>

          <div className="row space-between mob-column mb70">
            <div className='column column-center one-of-3 mob-fullwidth mob-mb-50'>
              <p className='sato40 font700 gradient-text' style={{ marginBottom: 26 }}>80%</p>
              <p className='text-center font18-400' style={{ color: '#CECEFF', maxWidth: 314 }}>{t("of security professionals feel inadequately prepared to defend their oraganization")}</p>
            </div>
            <div className='column column-center one-of-3 mob-fullwidth mob-mb-50'>
              <p className='sato40 font700 gradient-text' style={{ marginBottom: 26 }}>68%</p>
              <p className='text-center font18-400' style={{ color: '#CECEFF', maxWidth: 314 }}>{t("of security professionals don’t believe their organizations provides the right training")}</p>
            </div>
            <div className='column column-center one-of-3 mob-fullwidth mob-mb-50'>
              <p className='sato40 font700 gradient-text' style={{ marginBottom: 26 }}>70%</p>
              <p className='text-center font18-400' style={{ color: '#CECEFF', maxWidth: 314 }}>{t("of professionals claim their organization is impacted by the security skills shortage")}</p>
            </div>
          </div>

          <div className='column column-center'>

            <Button onClick={() => navigate('/pricing')} variant={'gradient-button'}>{t("Request a demo")}
              <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill="white" />
              </svg>
            </Button>
          </div>
        </div>
      </section>
      <Divider style={{ margin: 0, background: '#2F2F2F' }} />
      <Footer theme={THEMES.dark} />
    </>
  )
}