import React, { useState } from "react";
import { Logo } from "../logo";
import { Link, NavLink } from "react-router-dom";
import { Button } from "../button";

import './style.css'
import Spacer from "../Spacer";
import Divider from "../Divider";
import { THEMES } from "src/constatnts";
import { ClickAwayListener, Fade, Paper, Popover, Popper } from "@mui/material";
import { useTranslation } from "react-i18next";

import i18next from '../../i18n'

export const ROUTES = {
  home: '/',
  pricing: '/pricing',
  aiPoweredCyberAttackSimulation: '/ai-powered-cyber-attack-simulation',
  securityAwarenessTrainings: '/security-awareness-trainings',
  emailThreatReportButton: '/email-threat-report-button',
  intelligentThreatResponse: '/intelligent-threat-response',
  gamificationPlatform: '/gamification-platform',
  socialEngineeringPrevention: '/social-engineering-prevention',
  behavioralChange: '/behavioral-change',
  comprehensiveCybersecurity: '/comprehensive-cybersecurity',
  proactiveRiskAssessment: '/proactive-risk-assessment',
  globalWorkforceDefense: '/global-workforce-defense',
  appfraud: '/appfraud',
  achieveAndMaintainRobust: '/achieve-and-maintain-robust',
  whyCyberlift: '/why-cyberlift',
  aiPoweredPlatform: '/ai-powered-platform',
  cyberliftVsCompetitors: '/cyberlift-vs-competitors',
  aboutUs: '/about-us',
  terms: '/terms',
  newsroom: '/newsroom',
  partners: '/partners',
  careers: '/careers',
  securityCompliance: '/security-compliance',
  technologies: '/technologies',
  contacts: '/contacts',
  privacyPolicy: '/privacy-policy',
  termsOfUse: '/terms-of-use',
  dpa: '/data-processing-agreement',

}

const getPopMenu = (t) => ({
  solutions: {
    title: t('Solutions'),
    items: [
      { name: t('Social engineering prevention'), description: t('Shield your organization from social engineering attacks'), link: ROUTES.socialEngineeringPrevention },
      { name: t('Behavioral change and employee engagement'), description: t('Transform your cybersecurity culture'), link: ROUTES.behavioralChange },
      { name: t('Comprehensive cybersecurity education'), description: t('Stay ahead with comprehensive cybersecurity education'), link: ROUTES.comprehensiveCybersecurity },
      { name: t('Proactive risk assessment and management'), description: t('Anticipate and mitigate cyber risks before they become threats'), link: ROUTES.proactiveRiskAssessment },
      { name: t('Global workforce defense'), description: t('Defend your global workforce from cyber threats'), link: ROUTES.globalWorkforceDefense },
      { name: t('Achieve and maintain robust compliance with ISO 27001, PCI, HIPPA, GDPR, SOC 2, NIS 2 regulations'), description: '', link: ROUTES.achieveAndMaintainRobust },
    ]
  },
  products: {
    title: t('Products'),
    items: [
      { name: t('AI-powered cyber attack simulation'), description: t('Make employees Smarter than Attackers'), link: ROUTES.aiPoweredCyberAttackSimulation },
      { name: t('Security awareness trainings'), description: t('Hundreds of security awareness trainings in one place'), link: ROUTES.securityAwarenessTrainings },
      { name: t('Email threat report button'), description: t('Introducing Phishing email reporting button'), link: ROUTES.emailThreatReportButton },
      { name: t('Intelligent threat response'), description: '', link: ROUTES.intelligentThreatResponse },
      { name: t('Gamification platform'), description: t('Engage employees in security with a gamification experience'), link: ROUTES.gamificationPlatform },
    ]
  },
})

const PopMenu = ({ element }) => {
  return (
    <div className="padding-50-90 mt40">
      <p style={{ fontSize: '20px', fontWeight: '600', marginTop: 3 }}>{element.title}</p>
      <Divider style={{ marginTop: '16px', marginBottom: '35px', background: '#D8D8FF88', }} />
      <div style={{ marginTop: '-8px' }} className="row">
        <div style={{ marginRight: '50px' }} className="column">
          {element.items.slice(0, 3).map(el =>
            <NavLink style={{ textDecoration: 'none' }} key={el.link} to={el.link}>
              <div className="row pop-menu-item">
                <div className="column ">
                  <p style={{ fontSize: '14px', fontWeight: '500', marginBottom: '0px', marginTop: '0px', lineHeight: '25px' }}>{el.name}</p>
                  <p style={{ color: '#AEAEF3', fontSize: '12px', fontWeight: '500', lineHeight: '22px' }}>{el.description}</p>
                </div>
                <Spacer />
                <div style={{ width: 20 }}></div>
                <div className="row row-center">
                  <svg className="pop-menu-item-arrow" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM15.5303 6.53033C15.8232 6.23744 15.8232 5.76256 15.5303 5.46967L10.7574 0.696699C10.4645 0.403806 9.98959 0.403806 9.6967 0.696699C9.40381 0.989593 9.40381 1.46447 9.6967 1.75736L13.9393 6L9.6967 10.2426C9.40381 10.5355 9.40381 11.0104 9.6967 11.3033C9.98959 11.5962 10.4645 11.5962 10.7574 11.3033L15.5303 6.53033ZM1 6.75L15 6.75V5.25L1 5.25L1 6.75Z" fill="#ffffff" />
                  </svg>

                </div>

              </div>
            </NavLink>
          )}
        </div>
        <div style={{ marginRight: '-70px' }} className="column">
          {element.items.slice(3, 6).map(el =>
            <NavLink style={{ textDecoration: 'none' }} key={el.link} to={el.link}>
              <div className="row pop-menu-item">
                <div className="column ">
                  <p style={{ fontSize: '14px', fontWeight: '500', marginBottom: '0px', marginTop: '0px', lineHeight: '25px' }}>{el.name}</p>
                  <p style={{ color: '#AEAEF3', fontSize: '12px', lineHeight: '22px', fontWeight: '500' }}>{el.description}</p>
                </div>
                <Spacer />
                <div style={{ width: 20 }}></div>
                <div className="row row-center">
                  <svg className="pop-menu-item-arrow" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM15.5303 6.53033C15.8232 6.23744 15.8232 5.76256 15.5303 5.46967L10.7574 0.696699C10.4645 0.403806 9.98959 0.403806 9.6967 0.696699C9.40381 0.989593 9.40381 1.46447 9.6967 1.75736L13.9393 6L9.6967 10.2426C9.40381 10.5355 9.40381 11.0104 9.6967 11.3033C9.98959 11.5962 10.4645 11.5962 10.7574 11.3033L15.5303 6.53033ZM1 6.75L15 6.75V5.25L1 5.25L1 6.75Z" fill="#ffffff" />
                  </svg>

                </div>

              </div>
            </NavLink>
          )}
        </div>
      </div>

    </div>
  )
}

export const Navigation = ({ theme, style }) => {
  const [mobileMenuShown, setMobileMenuShown] = useState(false)
  const [productsMenuShown, setProductsMenuShown] = useState(false)
  const [solutionsMenuShown, setSolutionsMenuShown] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedMenuElement, setSelectedMenuElement] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();

  const [anchorLangEl, setAnchorLangEl] = React.useState(null);

  const { t } = useTranslation()

  const popMenu = getPopMenu(t)

  let timeout = null

  const handleClick = (newPlacement, element) => (event) => {
    clearTimeout(timeout)
    setAnchorEl(event.currentTarget);
    setSelectedMenuElement(element);
    setOpen(true);
    setPlacement(newPlacement);
  };

  const closeSubMenu = () => {
    timeout = setTimeout(() => {
      setAnchorEl(null)
      setOpen(false)
    }, 500);
  }

  const onEnterMenu = () => {
    clearTimeout(timeout);
  }

  const handleClose = () => {
    if (open) {
      setAnchorEl(null);
      setOpen(false);
    }
  };

  const handleLangClose = () => {
    setAnchorLangEl(null);
  };

  const langOpen = Boolean(anchorLangEl);

  const handleOpenMenu = () => {
    document.body.classList.toggle('lock-scroll')
    setMobileMenuShown(!mobileMenuShown)
  }
  const handleCloseMenu = () => {
    document.body.classList.remove('lock-scroll')
    setMobileMenuShown(false)
    setProductsMenuShown(false)
    setSolutionsMenuShown(false)
  }

  const handleOpenProductsMenu = () => {
    setProductsMenuShown(!productsMenuShown)
  }
  const handleOpenSolutionsMenu = () => {
    setSolutionsMenuShown(!solutionsMenuShown)
  }

  const handleOpenSelectLang = (event) => {
    setAnchorLangEl(event.currentTarget);
  }

  return (
    <div style={style} className={"nav row row-center " + theme}>
      <div className="container1300 row row-center">
        <div className="row row-center" style={{ width: '100%' }}>
          <NavLink to='/'>
            <Logo theme={theme} />
          </NavLink>

          <Spacer />

          <div className="row row-center mob-hide">
            {
              open &&
              <ClickAwayListener onClickAway={handleClose}>
                <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper onMouseEnter={onEnterMenu} onMouseLeave={closeSubMenu} style={{ background: '#101012', color: '#ffffff', borderRadius: '20px', marginLeft: '-100px', boxShadow: '0px 20px 50px 0px #0000004D' }}>
                        <PopMenu element={selectedMenuElement} />
                      </Paper>
                    </Fade>
                  )}
                </Popper>
              </ClickAwayListener>
            }

            <NavLink onClick={() => {
              setAnchorEl(null)
              setOpen(false)
            }} onMouseLeave={closeSubMenu} onMouseEnter={handleClick('bottom-start', popMenu.products)} className='nav-item' to='/ai-powered-cyber-attack-simulation'>{t('Products')}</NavLink>
            <NavLink onClick={() => {
              setAnchorEl(null)
              setOpen(false)
            }} onMouseLeave={closeSubMenu} onMouseEnter={handleClick('bottom-start', popMenu.solutions)} className='nav-item' to='/social-engineering-prevention'>{t('Solutions')}</NavLink>
            <NavLink className='nav-item' to='/why-cyberlift'>{t('Why Cyberlift')}</NavLink>
            <NavLink className='nav-item' to='/pricing'>{t('Pricing')}</NavLink>
          </div>

          <Spacer />
          <Link to={'https://admin.cyberlift.ai/login'}>

            <div className="mob-hide">
              <Button style={{ background: 'transparent', marginRight: 10 }} variant={'outlined'} >{t('Sign in')}</Button>
            </div>
          </Link>
          <Link to={'https://admin.cyberlift.ai/registration'}>

            <div className="mob-hide">
              <Button >{t('Sign up')}</Button>
            </div>
          </Link>
          <div onClick={handleOpenSelectLang} className="select-lang-btn">
            <div>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.2" d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#ffffff" />
                <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#ffffff" strokeMiterlimit="10" />
                <path d="M3.51562 9H20.4905" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.51562 15H20.4904" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 20.759C14.0711 20.759 15.75 16.8377 15.75 12.0006C15.75 7.16346 14.0711 3.24219 12 3.24219C9.92893 3.24219 8.25 7.16346 8.25 12.0006C8.25 16.8377 9.92893 20.759 12 20.759Z" stroke="#ffffff" strokeMiterlimit="10" />
              </svg>

            </div>
          </div>

          {
            langOpen &&
            <ClickAwayListener onClickAway={handleLangClose}>
              <Popper
                sx={{ zIndex: 100000, borderRadius: '10px', overflow: 'hidden', marginTop: '10px' }}
                open={langOpen}
                anchorEl={anchorLangEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }} transition>
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper onMouseEnter={onEnterMenu} onMouseLeave={closeSubMenu} style={{ background: '#101012', color: '#ffffff', borderRadius: '20px', boxShadow: '0px 20px 50px 0px #0000004D' }}>
                      <div style={{ height: 'auto', padding: 10, background: '#101012', color: '#ffffff', boxShadow: '0px 20px 50px 0px #0000004D' }} className="column column-center">
                        <p className="select-lang-menu-item" onClick={() => {
                          setAnchorLangEl(null)
                          localStorage.setItem('lang', 'en-EN')
                          i18next.changeLanguage('en')
                        }}>English</p>
                        <p className="select-lang-menu-item" onClick={() => {
                          setAnchorLangEl(null)
                          localStorage.setItem('lang', 'pl')
                          i18next.changeLanguage('pl')
                        }}>Polish</p>
                      </div>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </ClickAwayListener>
          }


          <div onClick={handleOpenMenu} className="burger-menu-button">
            <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1H17.9987" stroke={theme == THEMES.light ? "#1D1D29" : "white"} strokeWidth="2" strokeLinecap="round" />
              <path d="M1 8.00049H17.9987" stroke={theme == THEMES.light ? "#1D1D29" : "white"} strokeWidth="2" strokeLinecap="round" />
              <path d="M1 15H17.9987" stroke={theme == THEMES.light ? "#1D1D29" : "white"} strokeWidth="2" strokeLinecap="round" />
            </svg>
          </div>

          {mobileMenuShown &&
            <div className="mobile-menu" >
              <div className="row row-center">
                <Logo theme={'dark'} />
                <Spacer />
                <div onClick={handleOpenMenu} className="burger-menu-button">
                  <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.24219 6.75391L18.7422 19.2539" stroke="#ffffff" strokeWidth="1.75" strokeLinecap="round" />
                    <path d="M6.24219 19.2461L18.7422 6.7461" stroke="#ffffff" strokeWidth="1.75" strokeLinecap="round" />
                  </svg>

                </div>
              </div>
              <Divider style={{ background: '#1C1C26', margin: 0 }} />
              <div onClick={handleOpenProductsMenu} className="mobile-menu-item row row-center">
                <p>{t('Products')}</p>
                <Spacer />
                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill="#ffffff" />
                </svg>

              </div>
              <Divider style={{ background: '#1C1C26', margin: 0 }} />

              <div onClick={handleOpenSolutionsMenu} className="mobile-menu-item row row-center">
                <p>{t('Solutions')}</p>
                <Spacer />
                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill="#ffffff" />
                </svg>

              </div>
              <Divider style={{ background: '#1C1C26', margin: 0 }} />

              <div className="mobile-menu-item row row-center">
                <NavLink onClick={handleCloseMenu} to={'/why-cyberlift'}>{t('Why Cyberlift')}</NavLink>
              </div>
              <Divider style={{ background: '#1C1C26', margin: 0 }} />

              <div className="mobile-menu-item row row-center">
                <NavLink onClick={handleCloseMenu} to={'/pricing'}>{t('Pricing')}</NavLink>
              </div>
              <Divider style={{ background: '#1C1C26', margin: 0 }} />

              <div className="mobile-menu-item row row-center">
                <NavLink onClick={handleCloseMenu} to={ROUTES.aboutUs}>{t('Company')}</NavLink>
              </div>
              <Divider style={{ background: '#1C1C26', marginTop: '0', marginBottom: '40px' }} />

              <div className="column column-center" style={{ width: '100%' }}>
                {/* <Link style={{ width: '100%', textDecoration: 'none' }} to={'https://admin.cyberlift.ai/login'}>
                  <Button style={{ width: '100%', height: '62px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'transparent', marginBottom: 20 }} variant={'outlined'} >
                    <div className="row row-center">
                      <p>
                        Sign in
                      </p>
                    </div>
                  </Button>
                </Link> */}

                <Link style={{ width: '100%', textDecoration: 'none' }} to={'https://admin.cyberlift.ai/registration'}>
                  <Button style={{ width: '100%', height: '62px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant={'gradient-button'}>
                    <div className="row row-center">
                      <p>
                        {t('Sign up')}
                      </p>
                      <svg style={{ marginLeft: '20px' }} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill="#E3E3FF" />
                      </svg>
                    </div>
                  </Button>
                </Link>
              </div>

            </div>
          }
          {productsMenuShown &&
            <div className="mobile-menu" style={{ padding: '10px 28px' }}>
              <div className="row row-center pointer">
                <div onClick={handleOpenProductsMenu} className="row row-center">
                  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.25781 14.2539L1.00781 8.00391" stroke="#ffffff" strokeWidth="1.75" strokeLinecap="round" />
                    <path d="M7.25781 1.74609L1.00781 7.99609" stroke="#ffffff" strokeWidth="1.75" strokeLinecap="round" />
                  </svg>

                  <p>{t('Products')}</p>

                </div>
                <Spacer />
                <div onClick={handleCloseMenu} className="burger-menu-button">
                  <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.24219 6.75391L18.7422 19.2539" stroke="#ffffff" strokeWidth="1.75" strokeLinecap="round" />
                    <path d="M6.24219 19.2461L18.7422 6.7461" stroke="#ffffff" strokeWidth="1.75" strokeLinecap="round" />
                  </svg>

                </div>
              </div>
              <Divider style={{ margin: 0, background: '#1C1C26' }} />
              <div style={{ height: 'calc(100dvh - 90px)', overflow: 'auto' }}>
                <div style={{ marginTop: '30px' }} className="column">
                  {popMenu.products.items.map(el =>
                    <NavLink onClick={handleCloseMenu} style={{ textDecoration: 'none' }} key={el.link} to={el.link}>
                      <div className="column pop-menu-item">
                        <p style={{ fontSize: '14px', fontWeight: '500', lineHeight: '24px' }}>{el.name}</p>
                        <p style={{ color: '#AEAEF3', fontSize: '12px', fontWeight: '500' }}>{el.description}</p>
                      </div>
                    </NavLink>
                  )}
                </div>
              </div>
            </div>
          }
          {solutionsMenuShown &&
            <div className="mobile-menu" style={{ padding: '10px 28px' }}>
              <div className="row row-center pointer">
                <div onClick={handleOpenSolutionsMenu} className="row row-center">
                  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.25781 14.2539L1.00781 8.00391" stroke="#ffffff" strokeWidth="1.75" strokeLinecap="round" />
                    <path d="M7.25781 1.74609L1.00781 7.99609" stroke="#ffffff" strokeWidth="1.75" strokeLinecap="round" />
                  </svg>

                  <p>{t('Solutions')}</p>

                </div>
                <Spacer />
                <div onClick={handleCloseMenu} className="burger-menu-button">
                  <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.24219 6.75391L18.7422 19.2539" stroke="#ffffff" strokeWidth="1.75" strokeLinecap="round" />
                    <path d="M6.24219 19.2461L18.7422 6.7461" stroke="#ffffff" strokeWidth="1.75" strokeLinecap="round" />
                  </svg>

                </div>
              </div>
              <Divider style={{ margin: 0, background: '#1C1C26' }} />
              <div style={{ height: 'calc(100dvh - 90px)', overflow: 'auto' }}>
                <div style={{ marginTop: '10px' }} className="column">
                  {popMenu.solutions.items.map(el =>
                    <NavLink onClick={handleCloseMenu} style={{ textDecoration: 'none' }} key={el.link} to={el.link}>
                      <div className="column pop-menu-item">
                        <p style={{ fontSize: '14px', fontWeight: '500', lineHeight: '24px' }}>{el.name}</p>
                        <p style={{ color: '#AEAEF3', fontSize: '12px', lineHeight: '20px', fontWeight: '500' }}>{el.description}</p>
                      </div>
                    </NavLink>
                  )}
                </div>
              </div>
            </div>
          }

        </div>

      </div>
    </div>
  )
}