import React from 'react'
import Navigation from '../../components/navigation'
import Footer from '../../components/footer'
import { THEMES } from 'src/constatnts'

import './style.css'
import { Button } from 'src/components/button'
import PromoBlock from 'src/components/PromoBlock'
import { MobBreak } from '../securityAwarenessTrainings'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const EmailThreatReportButtonPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <>
      <Navigation style={{ borderBottom: '1px solid #4f4f4f' }} theme={THEMES.dark} />
      <section className='dark heading-section mob-pb-100'>
        <div className='container'>
          <div className='column column-center'>
            <svg width="194" height="203" viewBox="0 0 194 203" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M137.817 90.1694C133.023 73.0998 117.668 60.6128 99.4659 60.6128C77.4279 60.6128 59.5625 78.9184 59.5625 101.499C59.5625 124.081 77.4279 142.386 99.4659 142.386C118.016 142.386 133.61 129.417 138.081 111.844" stroke="url(#paint0_linear_3778_405)" strokeWidth="2.22224" strokeLinecap="round" />
              <path d="M164.266 70.9583C152.902 46.2281 128.165 29.0864 99.479 29.0864C60.0281 29.0864 28.0469 61.5072 28.0469 101.5C28.0469 141.493 60.0281 173.914 99.479 173.914C128.733 173.914 153.88 156.087 164.925 130.564" stroke="url(#paint1_linear_3778_405)" strokeWidth="2.22224" strokeLinecap="round" />
              <path d="M192.115 66.032C178.013 28.3114 142.074 1.5 99.9645 1.5C45.5495 1.5 1.4375 46.2715 1.4375 101.5C1.4375 156.728 45.5495 201.5 99.9645 201.5C141.706 201.5 177.385 175.155 191.74 137.953" stroke="url(#paint2_linear_3778_405)" strokeWidth="2.22224" strokeLinecap="round" />
              <defs>
                <linearGradient id="paint0_linear_3778_405" x1="98.8218" y1="60.6128" x2="98.8218" y2="142.386" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#D459FF" />
                  <stop offset="0.171875" stopColor="#6D4AFF" />
                  <stop offset="0.322917" stopColor="#141417" />
                  <stop offset="0.515625" stopColor="#FF0053" />
                  <stop offset="0.692708" stopColor="white" />
                  <stop offset="0.848958" stopColor="#CECEFF" />
                  <stop offset="1" stopColor="#141417" />
                </linearGradient>
                <linearGradient id="paint1_linear_3778_405" x1="96.4858" y1="29.0864" x2="96.4858" y2="173.914" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#D459FF" />
                  <stop offset="0.171875" stopColor="#6D4AFF" />
                  <stop offset="0.322917" stopColor="#141417" />
                  <stop offset="0.515625" stopColor="#FF0053" />
                  <stop offset="0.692708" stopColor="white" />
                  <stop offset="0.848958" stopColor="#CECEFF" />
                  <stop offset="1" stopColor="#141417" />
                </linearGradient>
                <linearGradient id="paint2_linear_3778_405" x1="96.776" y1="1.5" x2="96.776" y2="201.5" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#D459FF" />
                  <stop offset="0.171875" stopColor="#6D4AFF" />
                  <stop offset="0.322917" stopColor="#141417" />
                  <stop offset="0.515625" stopColor="#FF0053" />
                  <stop offset="0.692708" stopColor="white" />
                  <stop offset="0.848958" stopColor="#CECEFF" />
                  <stop offset="1" stopColor="#141417" />
                </linearGradient>
              </defs>
            </svg>

            <p className='gradient-text-2 mb30 mt70'>
              {t("Email Threat Report Button")}
            </p>

            <h1 className='text-center mb20'>{t("Introducing Phishing email reporting button")}</h1>

            <p className='mb34 text-center font18 mob-font14' style={{ maxWidth: 679 }}>{t("Cyberlift plugin button empowers employees to report phishing emails and other suspicious messages with one click directly to the security admin.")}</p>

            <Button onClick={() => navigate('/pricing')} variant={'gradient-button'}>{t('Get started')}
              <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill="#E3E3FF" />
              </svg>
            </Button>

          </div>
        </div>
      </section>
      <section className='darker service-elements-section'>
        <div className='container1300'>
          <div className=' br30 column column-center padding-86-90 mob-padding-93-20-0  service-elements' style={{ background: '#1D1D29 radial-gradient(67.87% 67.87% at 47.96% 71.39%, #6D4AFF 3.58%, rgba(29, 29, 41, 0) 100%)' }}>
            <h2 style={{ color: '#fff' }} className='text-center mb100 mob-mb-90'>
              {t("Powerful Action button inside your inbox")}
            </h2>
            <div className='row space-between mob-column'>
              <div className='service-element one-of-2 mob-fullwidth br20 column dark-light-box mob-mb-20 box-shadow'>
                <img className='mob-hide' src="/images/g-services.png" width={"100%"} alt="" />
                <img className='hide mob-show' src="/images/g-services-mob.png" width={"100%"} alt="" />
                <div className='padding-20-0 mob-padding-15-0-43'>
                  <p className='text-center font28 mob-font22 mob-font500'>
                    {t("For Google")} <br /> {t("Workspace users")}
                  </p>

                </div>
              </div>

              <div className='service-element one-of-2 mob-fullwidth br20 column dark-light-box box-shadow'>
                <img className='mob-hide' src="/images/m-services.png" width={"100%"} alt="" />
                <img className='hide mob-show' src="/images/m-services-mob.png" width={"100%"} alt="" />
                <div className='padding-20-0 mob-padding-15-0-43'>
                  <p className='text-center font28 mob-font22 mob-font500'>
                    {t("For Microsoft")} <br /> {t("365 users")}
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section className='darker'>
        <div className='container1300'>
          <div className='br30 dark-box column padding-100-100 mob-padding-46-23'>
            <div className='column column-center' style={{ width: '100%' }}>
              <h2 className='text-center mb30'>{t("Faster Email reporting")}</h2>
              <p className='text-center mb70 font22 mob-font-14 mob-mw-243' style={{ maxWidth: 760 }}>{t("It’s important to identify and stop phishing attacks inside the inbox. Cyberlift products educate employees to report suspicious emails faster than ever before.")}</p>

            </div>

            <div className='row container760 mob-column' style={{ justifyContent: 'space-around' }}>
              <div className='column column-center mob-fullwidth'>
                <div className='big-num gradient-text-3 mob-font-60'>
                  <p>{t("308 days")}</p>
                  <div className='mb20' style={{ opacity: '0.4', marginTop: 20, height: 1, width: '100%', background: 'linear-gradient(90deg, #FA145F 0%, #1D1D29 128.59%)' }}></div>
                </div>
                <p className='font22'>{t("Without Cyberlift")}</p>
              </div>
              <div className='column column-center mob-fullwidth'>
                <div className='big-num gradient-text'>
                  <p>&lt; 2 h.</p>
                  <div className='mb20' style={{ opacity: '0.4', marginTop: 20, height: 1, width: '100%', background: 'linear-gradient(90deg, #6D4AFF 52.21%, #D459FF 100%)' }}></div>
                </div>
                <p className='font22'>{t("With Cyberlift")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='darker'>
        <div className='container1300 pb30'>
          <div className='column column-center pt130 mob-pt-90 mb100 mob-mb-90'>
            <h2 className='text-center'>{t("Here’s how fast it works")}</h2>
          </div>

          <div className='br30 dark-box mb30 box-shadow padding-60-90 mob-padding-70-50'>
            <div className='row mob-column mob-column-center'>
              <div className='row row-center fast-works-img-container'>
                <img className='mob-img mob-mb-20' src="/images/dark-envelop.png" width={'202'} alt="" />
              </div>
              <div className='column'>
                <p className='font40 mob-font22 mob-text-center mob-mb-20 mb24 mob-font-600'>{t("Identify")}</p>
                <p className='font22 mob-font-14 mob-text-center'>{t("a suspicious email Based on our automated & personalized attack simulations employees increase security awareness with a goal to identify potential email based threats.")}
                </p>
              </div>
            </div>
          </div>
          <div className='br30 dark-box mb30 box-shadow padding-60-90 mob-padding-70-50'>
            <div className='row mob-column mob-column-center'>
              <div className='row row-center fast-works-img-container'>

                <img className='mob-img mob-mb-20' src="/images/dark-shield.png" width={'220'} alt="" />
              </div>
              <div className='column'>
                <p className='font40 mob-font22 mob-text-center mob-mb-20 mb24 mob-font-600'>{t("Mark")}</p>
                <p className='font22 mob-font-14 mob-text-center'>{t("as Spam or Suspicious email In a case if an employee clicks Spam email automatically removes to spam. In a case if an employee clicks a Suspicious email then it will be reported to the security team for further investigation.")}

                </p>
              </div>
            </div>
          </div>
          <div className='br30 dark-box box-shadow padding-60-90 mob-padding-70-50'>
            <div className='row mob-column mob-column-center'>
              <div className='row row-center fast-works-img-container'>
                <img className='mob-img mob-mb-20' src="/images/dark-planes.png" width={'200'} alt="" />
              </div>
              <div className='column'>
                <p className='font40 mob-font22 mob-text-center mob-mb-20 mb24 mob-font-600'>{t("Report")}</p>
                <p className='font22 mob-font-14 mob-text-center'>{t("and inform security team with action made before When an employee wants to report this email they can choose the action that has been made. If it was Cyberlift’s simulation employee will get a reward. If it wasn’t the Security team will get this email into the Cyberlift’d Admin panel for further threat investigation.")}

                </p>
              </div>
            </div>
          </div>

        </div>

      </section>
      <section className='darker pt150 pb150 mob-pt-90 mob-pb-94'>
        <div className='container1300'>
          <div className='column column-center'>
            <h2 style={{ maxWidth: '859px' }} className='sato40 mob-font26 text-center mb40 mob-mb-20 mob-mw-265'>{t("Revolutionary performance.")}  {t("Increase employee threat response from day one")}</h2>

            <p style={{ maxWidth: '860px' }} className='mob-font-14 font22 text-center mb120 mob-mb-0 mob-mw-285'>{t("Cyberlift helps organizations and their employees to build a stable security culture. When technologies fail-people should act with a simple scenario to stay protected from the incident.")}</p>
          </div>
        </div>
      </section>
      <section className='darker pb150 mob-pb-100'>
        <PromoBlock >
          <p className='sato34 mb36 mob-font-24'>{t("Transform your employees into Human firewall today")}</p>
        </PromoBlock>
      </section>
      <Footer theme={THEMES.dark} />
    </>
  )
}