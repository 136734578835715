import React from 'react'
import Navigation from '../../components/navigation'
import Footer from '../../components/footer'
import { THEMES } from 'src/constatnts'

import './style.css'
import { Button } from 'src/components/button'
import { useNavigate } from 'react-router-dom'
import PromoBlock from 'src/components/PromoBlock'
import { useTranslation } from 'react-i18next'

export const SocialEngineeringPreventionPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <>
      <Navigation theme={THEMES.dark} />
      <section className='dark heading-section'>
        <div className='container'>
          <div className='row space-between mob-column-reverse'>
            <div className='column column-start mob-column-center' >
              <p className='secondary-heading mob-text-center mob-font14'>{t("Social Engineering Prevention")}</p>
              <h1 style={{ maxWidth: '540px' }} className='mb40 mob-text-center'>{t("Shield your organization from social engineering attacks")}</h1>
              <p className='mb50 heading-description mob-text-center mob-font14' style={{ maxWidth: 420 }}>{t("Stay ahead of social engineering attempts and protect your sensitive data by enabling your team with the right knowledge and training.")}</p>

              <Button onClick={() => navigate('/pricing')} variant={'gradient-button'} >{t('Get started')} <svg style={{ marginLeft: '20px' }} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill="#E3E3FF" />
              </svg>
              </Button>
            </div>
            <img style={{ margin: 0 }} src="/images/sep-shield.png" className='mob-img sep-mob' width="405" alt="" />
          </div>
        </div>
      </section>
      <section className='darker pt200 mob-pt-20'>
        <div className='container'>
          <div className='row space-between mob-column mob-mb-40'>
            <div className='br20 dark-box one-of-3 mob-fullwidth padding-50-60 box-shadow mob-padding-46-43 mob-mb-20'>
              <p className='big-num-jakarta font700 gradient-text mb20'>
                90%
              </p>
              <p className='font16 text-left'>{t("of Cyber Attacks Are Targeting Your Employees Instead of Your Tech")}</p>
            </div>
            <div className='br20 dark-box one-of-3 mob-fullwidth padding-50-60 box-shadow mob-padding-46-43 mob-mb-20'>
              <p className='gradient-text font32'>
                {t("Today hackers use AI / ML for much complexed, personalized attacks %")}
              </p>
            </div>
            <div className='br20 dark-box one-of-3 mob-fullwidth padding-50-60 box-shadow mob-padding-46-43 mob-mb-20'>
              <p className='big-num-jakarta font700 gradient-text'>
                $4.1M
              </p>
              <p className='font16 text-left'>{t("Million The Price of the Average Social Engineering-Related Breach")}</p>
            </div>
          </div>

          <div className='row row-center mob-column mt220 mob-mt-90'>
            <div className='column'>
              <p className='font46 font600 mb30 mob-text-center mob-font-26'>{t("Thousands of Simulation Scenarios")}</p>
              <p className='font20 mob-text-center mob-font-14 mob-mb-40' style={{ maxWidth: 410 }}>{t("From spear phishing emails to voice and text messages, and deceptive web pages, CyberLift covers all types of social engineering attacks in its simulations, ensuring your team is prepared for any threat.")}</p>
            </div>
            <div className='img-container-29'>
              <img className='mob-img mob-mb-40' src="/images/sim-scenarios.png" width={'680'} alt="" />
            </div>
          </div>
          <div className='row row-center space-between mob-column-reverse mt140 mob-mt-90'>
            <div className='img-container-30'>
              <img className='mob-img mob-mb-40' src="/images/skills-dev.png" width={'644'} alt="" />
            </div>
            <div className='column'>
              <p className='font46 font600 mb30 mob-text-center mob-font-26 ' style={{ maxWidth: 468 }}>{t("Skills Development for Threat Detection")}</p>
              <p className='font20 mob-text-center mob-font-14 mob-mb-40' style={{ maxWidth: 460 }}>{t("As social engineering schemes evolve in complexity, we empower your team with the essential skills to swiftly identify and neutralize these potential threats.")}</p>
            </div>
          </div>
          <div className='row row-center space-between mob-column' style={{ marginTop: 50 }}>
            <div className='column'>
              <p className='font46 font600 mb30 mob-text-center mob-font-26' style={{ maxWidth: 470 }}>{t("Real-Time Feedback for Improvement")}</p>
              <p className='font20 mob-text-center mob-font-14 mob-mb-40' style={{ maxWidth: 440 }}>{t("Benefit from immediate, detailed feedback on every interaction within our social-engineering simulations, fostering a cycle of continuous learning and enhancement in your team's security competencies.")}</p>
            </div>
            <div className='img-container-31'>

              <img className='mob-img' src="/images/feedback-improve.png" width={'587'} alt="" />
            </div>
          </div>


        </div>
      </section>


      <section className='darker pt110 pb150 mob-pt-60 mob-pb-100'>
        <div className='container1300'>

          <div className='br30 row row-center dark-box mob-column-reverse padding-50-60 box-shadow mob-padding-46-43 mb40 mob-mb-20'>
            <div className='img-container-55'>
              <img className='mob-img mob-hide' src="/images/vulnerability-assessment.png" width={'700'} alt="" />
              <img className='mob-img hide mob-show' src="/images/vulnerability-assessment-mob.png" alt="" />

            </div>
            <div className='column'>
              <p className='font46 mb30 mob-text-center mob-font-26' style={{ maxWidth: 358 }}>{t("AI-Assisted Vulnerability Assessment")}</p>
              <p className='font20 mob-text-center mob-font-14 mob-mb-40' style={{ maxWidth: 358 }}>{t("Leverage our AI-powered risk analysis to pinpoint your organization's vulnerabilities and devise effective mitigation strategies.")}</p>
            </div>
          </div>

        </div>

        <PromoBlock>
          <p className='mb36 sato36 font500 mob-font-24'>{t("An all-new platform.")}<br />
            {t("An all-new world for security teams.")}</p>
        </PromoBlock>
      </section>

      <section></section>
      <Footer theme={THEMES.dark} />
    </>
  )
}