import React from 'react'
import Navigation from '../../components/navigation'
import Footer from '../../components/footer'
import { THEMES } from 'src/constatnts'

import './style.css'
// import Spacer from 'src/components/Spacer'
import { Button } from 'src/components/button'
import PromoBlock from 'src/components/PromoBlock'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const IntelligentThreatResponsePage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <>
      <Navigation style={{ borderBottom: '1px solid #2E2E3C8f' }} theme={THEMES.dark} />
      <section className='dark-soft heading-section-small pb0'>
        <div className='container1300'>
          <div className='dark-box br30 mb40 padding-114-150-110 mob-padding-60-38' style={{ background: 'linear-gradient(180deg, #1D1D29 -135.14%, #141417 183.52%)' }}>
            <div className='row mob-column space-between'>
              <div className='column one-of-2 mob-fullwidth mob-column-center column-start mob-mb-60'>
                <h1 className='gradient-text-4 sato60 mb20 mob-mb-30 mob-text-center mob-sato45'>
                  {t('Intelligent Threat Response')}
                </h1>
                <p className='font18 mb40 mob-mb-60 mob-font-14 mob-text-center'>{t('It’s important to understand which reported user emails are malicious. And it’s the key to quickly removing active phishing and spear phishing attacks. Get reported emails from employees directly in your admin panel.')}</p>
                <Button onClick={() => navigate('/pricing')} variant={'contained button-large'} size='large'>{t('Get started')}
                  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill="white" />
                  </svg>

                </Button>
              </div>
              <div className='one-of-2 mob-fullwidth img-container-1 column column-center'>
                <img className='mob-img' src="/images/intelligent-threat-response-main-img.png" alt="" />

              </div>
            </div>
          </div>

          <div className='row mob-column space-between'>

            <div style={{ background: 'linear-gradient(180deg, #1D1D29 -135.14%, #141417 183.52%)' }} className='dark-box br30 mb40 padding-74-100 mob-padding-46-43 width520 mob-fullwidth'>
              <p className='font35 mob-font26 mob-text-center mb20'>{t('Detect on real-time critical incident')}</p>
              <p className='font18 mob-font14 mob-text-center'>
                {t('Centralize threat reporting from all organization in one place.')}
              </p>
              <div className='img-container-2'>
                <img src="/images/detect-critical-incident.png" className='mob-img' width={'480px'} style={{ marginBottom: '-80px' }} alt="" />
                <img src="/images/detect-critical-incident-2.png" className='mob-img' width={'480px'} alt="" />

              </div>
            </div>
            <div style={{ background: 'linear-gradient(180deg, #1D1D29 -135.14%, #141417 183.52%)' }} className='dark-box br30 mb40 mob-mb-20 padding-74-100 mob-padding-46-43 width740 mob-fullwidth'>
              <p className='font40 font500 mob-font26 mob-text-center mb20'>{t('Metrics')}</p>
              <p className='font22 mb70 mob-font14 mob-text-center'>
                {t("With Cyberlift security awareness your employees' security actions performance will grow. Improve detection rates metrics and reporting metrics from day one.")}
              </p>
              <div style={{ color: '#6D4AFF' }} className='row space-between mob-column'>
                <div className='column column-center one-of-2 mob-fullwidth mob-mb-20'>
                  <div className='mb20 column column-center mob-mw-188' style={{ border: '1px solid #CECEFF4D', padding: '45px 28px', borderRadius: '9px', width: '100%' }}>
                    <p className='big-num'>75%</p>
                  </div>
                  <p className='font28 mob-font-24 text-center'>{t("+ detect rate")}</p>
                </div>
                <div className='column column-center one-of-2 mob-fullwidth'>
                  <div className='mb20 column column-center mob-mw-188' style={{ border: '1px solid #CECEFF4D', padding: '45px 28px', borderRadius: '9px', width: '100%' }}>
                    <p className='big-num'>&lt; 2 h.</p>
                  </div>
                  <p className='font28 mob-font-24 text-center'>{t('reporting rate')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='dark-soft pb150'>
        <div className='contaner1300'>
          <PromoBlock inverted={true} dark={true} >
            <p className='sato34 mb36 mob-font-24'>{t('Transform your employees into Human firewall today')}</p>

          </PromoBlock>

        </div>
      </section>
      <section></section>
      <section></section>
      <Footer theme={THEMES.dark} />
    </>
  )
}