import React from 'react'
import { useTranslation } from 'react-i18next'
import Spacer from 'src/components/Spacer'

export const DataProcessingAgreementPage = () => {
  const { t } = useTranslation()

  return (
    <div style={{ background: '#1D1D29', height: '100vh' }} className='column column-center'>
      <h1 style={{ color: '#ffffff', marginTop: 30 }}>Data Processing Agreement</h1>
      <h3 style={{ color: '#ffffff', fontFamily: "'Plus Jakarta Sans', sans-serif", marginTop: '30px' }}>Select language</h3>

      <div className='column' style={{ width: '300px', marginTop: 30 }}>
        <div className="column">

          <a style={{ textDecoration: 'none', borderBottom: '1px solid #ffffff1a' }} href={'/assets/cyberlift-dpa-en.pdf'}>
            <div className="row pop-menu-item" style={{ marginRight: 0 }}>
              <div className="column ">
                <p style={{ fontSize: '14px', fontWeight: '500', marginBottom: '0px', marginTop: '0px', lineHeight: '25px' }}>English</p>
              </div>
              <Spacer />
              <div style={{ width: 20 }}></div>
              <div className="row row-center">
                <svg className="pop-menu-item-arrow" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM15.5303 6.53033C15.8232 6.23744 15.8232 5.76256 15.5303 5.46967L10.7574 0.696699C10.4645 0.403806 9.98959 0.403806 9.6967 0.696699C9.40381 0.989593 9.40381 1.46447 9.6967 1.75736L13.9393 6L9.6967 10.2426C9.40381 10.5355 9.40381 11.0104 9.6967 11.3033C9.98959 11.5962 10.4645 11.5962 10.7574 11.3033L15.5303 6.53033ZM1 6.75L15 6.75V5.25L1 5.25L1 6.75Z" fill="#ffffff" />
                </svg>

              </div>

            </div>
          </a>
          <a style={{ textDecoration: 'none', borderBottom: '1px solid #ffffff1a' }} href={'/assets/cyberlift-dpa-pl.pdf'}>
            <div className="row pop-menu-item" style={{ marginRight: 0 }}>
              <div className="column ">
                <p style={{ fontSize: '14px', fontWeight: '500', marginBottom: '0px', marginTop: '0px', lineHeight: '25px' }}>Polish</p>
              </div>
              <Spacer />
              <div style={{ width: 20 }}></div>
              <div className="row row-center">
                <svg className="pop-menu-item-arrow" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM15.5303 6.53033C15.8232 6.23744 15.8232 5.76256 15.5303 5.46967L10.7574 0.696699C10.4645 0.403806 9.98959 0.403806 9.6967 0.696699C9.40381 0.989593 9.40381 1.46447 9.6967 1.75736L13.9393 6L9.6967 10.2426C9.40381 10.5355 9.40381 11.0104 9.6967 11.3033C9.98959 11.5962 10.4645 11.5962 10.7574 11.3033L15.5303 6.53033ZM1 6.75L15 6.75V5.25L1 5.25L1 6.75Z" fill="#ffffff" />
                </svg>

              </div>

            </div>
          </a>

        </div>
      </div>

    </div>
  )
}