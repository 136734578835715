import React from 'react'
import Navigation from '../../components/navigation'
import Footer from '../../components/footer'
import { THEMES } from 'src/constatnts'

import './style.css'
import { Button } from 'src/components/button'
import PromoBlock from 'src/components/PromoBlock'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const AchieveAndMaintainRobustPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <>
      <Navigation theme={THEMES.dark} />
      <section className='dark heading-section plasma-bcg'>
        <div className='container'>
          <div className='row mob-column mob-column-center'>
            <div className='column column-start mob-column-center mob-text-center' >
              <h1 className='mb30' style={{ maxWidth: '520px' }}>{t("Achieve and maintain robust compliance with ISO 27001, PCI, HIPPA, GDPR, SOC 2, NIS 2 regulations")}</h1>
              <p className='mb30 font18 mob-font14 mob-text-center mob-mw-265' style={{ maxWidth: '520px' }}>{t("Navigate the complex landscape of global data protection and privacy regulations confidently, ensuring your business remains compliant while prioritizing data security.")}</p>

              <Button onClick={() => navigate('/pricing')} variant={'gradient-button'} >{t('Get started')} <svg style={{ marginLeft: '20px' }} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill="#E3E3FF" />
              </svg>
              </Button>
            </div>
            {/* <img style={{ margin: 0 }} src="/images/atom-cc.png" width="559" alt="" /> */}
          </div>
        </div>
      </section>
      <section className='darker pt150 mob-pt-20 pb150 mob-pb-20'>
        <div className='container1300'>

          <div className='dark-box row mob-column br30 padding-74-100 mob-padding-46-43 mb20'>
            <div className='column one-of-2 mob-fullwidth'>
              <p className='font46 mb20 mob-font-26' style={{ maxWidth: 360 }}>{t("Comprehensive Compliance Training")}</p>
              <p className='font20 mob-font-14 mob-mb-40' style={{ maxWidth: 362 }}>{t("Equip your team with the knowledge and tools they need to understand and adhere to global compliance standards, ensuring a culture of proactive compliance within your organization.")}</p>
            </div>
            <div className='one-of-2 mob-fullwidth'>
              <div className='img-container-6'>
                <img className='mob-hide' src="/images/comperhensive-compliance.png" width={580} alt="" />
                <img className='mob-img hide mob-show' src="/images/comperhensive-compliance-mob.png" alt="" />

              </div>
            </div>
          </div>

          <div className="row row-center space-between mob-column-reverse mob-column-center br30 padding-74-100 mob-padding-46-0 mb20">
            <div className='one-of-2 mob-fullwidth '><img className='mob-img' src="/images/continual-monitoring.png" width={'617'} alt="" /></div>
            <div className='one-of-2 mob-fullwidth column mob-column mob-column-center' style={{ maxWidth: 409 }}>
              <p className='font46 mb20 mob-font-26 mob-text-center' style={{ maxWidth: 289 }}>{t("Automated Compliance Reporting")}</p>
              <p className='font20 mob-font-14 mob-mb-40 mob-text-center' style={{ maxWidth: 350 }}>{t("Save time and resources with automated compliance reports that ensure you're always audit-ready and help you identify areas for improvement.")}</p>
            </div>
          </div>


          <div className='br30 row mob-column dark-box mb30'>

            <div className='column one-of-2 mob-fullwidth padding-110 pr-0 mob-padding-46-43 mb20'>
              <p className='font46 mb20 mob-font-26 mob-text-center'>{t("Real-time Compliance Monitoring")}</p>
              <p className='font24 mob-font-14 mob-mb-0 mob-text-center' style={{ maxWidth: 460 }}>{t("With continuous monitoring, get alerted to potential non-compliance issues in real-time and take immediate action to stay compliant.")}</p>
            </div>

            <div className='one-of-2 mob-fullwidth realtime-compliance'></div>
          </div>

          <div className='br30 row row-center dark-box mob-column-reverse padding-50-60 box-shadow mob-padding-46-43 mb40'>
            <div className='img-container-55'>
              <img className='mob-img' src="/images/vulnerability-assessment.png" width={'700'} alt="" />

            </div>
            <div className='column'>
              <p className='font46 mb30 mob-text-center mob-font-26' style={{ maxWidth: 378 }}>{t("AI-Powered Compliance Risk Assessment")}</p>
              <p className='font20 mob-text-center mob-font-14 mob-mb-40' style={{ maxWidth: 358 }}>{t("Use our AI-powered risk assessment to identify compliance vulnerabilities and devise effective strategies to address them.")}</p>
            </div>
          </div>




          <div className='row row-center space-between mob-column padding-110 mob-padding-30-20'>
            <div className='column'>
              <p className='font46 mb20 mob-font-26 mob-text-center'>{t("Regulatory Updates")}</p>
              <p className='font24 mob-font-14 mob-mb-40 mob-text-center' style={{ maxWidth: 480 }}>{t("Stay abreast of updates and changes with regular notifications and resources to help you understand their impact.")}</p>
            </div>

            <img className='mob-img' src="/images/reg-updates.png" width={'530'} alt="" />

          </div>

          <div className='br30 dark-box space-between row row-center mob-column-reverse padding-88-110 mob-padding-46-43'>
            <img className='mob-img' src="/images/track-progress.png" width={446} alt="" />

            <div style={{ maxWidth: 508 }}>
              <p className='font46 mb20 mob-font-26'>{t("Behavioral Scoring for Compliance")}</p>
              <p className='font24 mob-font-14 mob-mb-40'>{t("Leverage our platform's individual behavioral compliance scoring system, enabling you to identify employees who might need additional training or resources to ensure compliance.")}</p>
            </div>
          </div>

        </div>
      </section>
      <section className='darker pb160 mob-pb-100'>
        <PromoBlock>
          <p className='mb36 sato36 font500 mob-font-24'>{t("An all-new platform.")}<br />
            {t("An all-new world for security teams.")}</p>
        </PromoBlock>
      </section>
      <Footer theme={THEMES.dark} />
    </>
  )
}