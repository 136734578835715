import i18next from 'i18next'
import { en } from './translations/en.js'
import { pl } from './translations/pl.js'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector';



const resources = {
  en: { translation: en },
  pl: { translation: pl },
}

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    resources,

  })

export default i18next;