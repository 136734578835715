import React from "react";
import { Button } from "../button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PromoBlock = ({ children, description, buttonText, imgWidth = 560, dark, inverted }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className='container1300'>

      <div className={`br30 row ${inverted ? ' row-reverse' : ''} space-between mob-column ${dark ? 'gradient7' : 'gradient4'}`}>
        <div className='column column-start mob-text-center mob-column-center promo-block'>
          {children ? children : (
            <p className='mb36 sato36 font500 mob-font-24'>{t('An all-new platform.')}<br />
              {t('An all-new world for security teams.')}</p>
          )
          }
          <p style={{ color: '#CECEFF' }} className='mb40 mob-font14'>{description || t('Get started with Cyberlift.')}</p>
          <Button onClick={() => navigate('/pricing')} variant={'gradient-button'}>{buttonText || t('Get started')}
            <svg style={{ marginLeft: '20px' }} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill="#E3E3FF" />
            </svg>
          </Button>

          <div className="hide mob-show" style={{ height: 30 }}></div>

        </div>
        <div className="get-started-home-orig"></div>

      </div>
    </div >
  )
}

export default PromoBlock