import React from 'react'
import Navigation from '../../components/navigation'
import Footer from '../../components/footer'
import { THEMES } from 'src/constatnts'
import './style.css'
import { Button } from 'src/components/button'
import PromoBlock from 'src/components/PromoBlock'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
export const MobBreak = () => <br className='hide mob-show' />
export const SecurityAwarenessTrainingsPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <>
      <Navigation style={{ background: '#3F3F3F26' }} theme={THEMES.dark} />
      <section className='dark-soft pt110'>
        <div className='container1300'>
          <div className='br30 mt110 mob-fullsize gradient6'>
            <div className='row mob-column-reverse space-between padding-70-90 mob-padding-46-20'>
              <div className='column column-start mob-column-center'>
                <p className='mb30 font20 mob-font-14'>{t('Security awareness trainings')}</p>
                <h1 className='mb25 sato50 mob-sato-26 mw-506 mob-mw-282 mob-text-center'>{t('Hundreds of security awareness trainings in one place')}</h1>
                <p className='mb50 font18 mob-text-center mob-font-14'>{t('Launch impactful security trainings in seconds')}</p>
                <Button onClick={() => navigate('/pricing')} variant={'white-button'}>
                  {t('Get started')}
                  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill="#6D4AFF" />
                  </svg>
                </Button>
                <div className='hide mob-show' style={{ height: 60 }}></div>
              </div>
              <div className='br30 box-shadow img460 column column-center mob-mb-50'>
                <img src="/images/sat-main-img.png" className='mob-img mob-mw-340' width="460" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='dark-soft pt140 mob-pt-90'>
        <div className='container1300'>
          <div className='column column-center'>
            <h2 style={{ maxWidth: '689px' }} className='text-center mb100'> {t('Essential content platform covering all security aspects')}</h2>
          </div>
          <div className='row row-center space-between mob-column'>
            <div className='br30 column column-center darker-box-gradient one-of-2 padding-90-74 mob-padding-70-48 mob-fullwidth mob-mb-20'>
              <img className='mob-img' src="/images/sat-cards.png" width="442" alt="" />
              <p className='font32 mob-font-26 mb20 mob-text-center mob-mt-40' style={{ width: '100%' }}> {t('Security content library')}</p>
              <p className='font20 mob-text-center mob-font-14'> {t('Tons of security topics that cover all your bases.  Privacy, Device security, Working remotely, Malware.')}</p>
            </div>
            <div className='br30 column column-center darker-box-gradient one-of-2 padding-90-74 mob-padding-70-48 mob-fullwidth'>
              <div className='img-container-22'>
                <img className='mob-img' src="/images/sat-planet.png" width="406" alt="" />
              </div>
              <p className='font32 mob-font-26 mb20 mob-text-center' style={{ width: '100%' }}> {t('Compliance content library')}</p>
              <p className='font20 mob-text-center mob-font-14'> {t('Automated security awareness tailored to your compliance needs.')} <br />
                GDPR, NIS2, ISO 27001, HIPAA, SOC2, PCI DSS.</p>
            </div>
          </div>
        </div>
      </section>
      <section className='dark-soft pt110 mob-pt-90'>
        <div className='container1300'>
          <h2 className='text-center mb100 '> {t("Here’s how simple it works")}</h2>
          {/* <h2 className='text-center mb100 '>Here’s how simple it <MobBreak /> works</h2> */}
          <div className='row row-center mob-column space-between'>
            <div className='br20 dark-box one-of-3 padding-52-54 mob-mb-20 mob-fullwidth h420 mob-h340'>
              <p className='sato30 mb40 mob-font22 mob-font-600'> {t("Explore and prioritize topics")}</p>
              <p className='font18 mob-font14' style={{ color: '#EEEEFFB2' }}> {t("Set up the most relevant awareness topics by your rules. Courses customized for each individual employee and sent during all work periods.")}</p>
            </div>
            <div className='br20 dark-box one-of-3 padding-52-54 mob-mb-20 mob-fullwidth h420 mob-h340'>
              <p className='sato30 mb40 mob-font22 mob-font-600'> {t("Create in seconds training packages")}</p>
              <p className='font18 mob-font14' style={{ color: '#EEEEFFB2' }}> {t("Choose training modules, set up rules and launch trainings across all your company departments.")}</p>
            </div>
            <div className='br20 dark-box one-of-3 padding-52-54 mob-fullwidth h420 mob-h340'>
              <p className='sato30 mb40 mob-font22 mob-font-600'> {t("Track the progress with smart reporting")}</p>
              <p className='font18 mob-font14' style={{ color: '#EEEEFFB2' }}> {t("Follow in real time employees performance. Know which business unit perform better across your organization with our data driven reporting.")}</p>
            </div>
          </div>
        </div>
      </section>
      <section className='dark-soft pt150 mob-pt-90'>
        <div className='container1300'>
          <h2 className='text-center mb140 mob-mb-90'> {t("Reasons to use Cyberlift for your trainings")}</h2>
          <div className='br30 mb40' style={{ background: 'radial-gradient(69.05% 153.06% at 2.38% -61.63%, rgb(109, 74, 255) 3%, rgba(212, 89, 255, 0.2) 64%, rgba(212, 89, 255, 0) 108%), linear-gradient(rgb(25, 25, 29) 0%, rgba(20, 20, 23, 0) 122.68%)' }}>
            <div className='row mob-column padding-100-100 mob-padding-46-43'>
              <div className='column one-of-2 mob-fullwidth'>
                <h3 className='font42 mob-font22 mb30'> {t("Automation")}</h3>
                <p className='font24 mob-font14 mob-mb-20' style={{ color: '#EEEEFFB2', maxWidth: 440 }}> {t("Trainings are delivered automatically based on your preferences and needs of employee lifetime.")}</p>
              </div>
              <div className='img-container-13'>
                <img className='mob-img' src="/images/automation.png" width="703" alt="" />
              </div>
            </div>
          </div>
          <div className='br30 mb40' style={{ background: 'linear-gradient(0deg, #19191D 0%, rgba(20, 20, 23, 0) 122.68%)' }}>
            <div className='row space-between mob-column-reverse padding-100-100 mob-padding-46-43'>
              <div className='one-of-2 mob-fullwidth'>
                <div className='img-container-23'>
                  <img className='mob-img' src="/images/personalized-group.png" width="490" alt="" />
                </div>
              </div>
              <div className='one-of-2 mob-fullwidth column'>
                <h3 className='font42 mob-font26 mb30' style={{ maxWidth: 482 }}> {t("Personalized by your rules")}</h3>
                <p className='font24 mob-font14 mob-mb-40' style={{ maxWidth: 482 }}> {t("Launch training packages based on your preferences. Set-up specific rules, create groups and track the progress.")}</p>
              </div>
            </div>
          </div>
          <div className='br30 mb40' style={{ background: 'linear-gradient(0deg, #19191D 0%, rgba(20, 20, 23, 0) 122.68%)' }}>
            <div className='row mob-column padding-90-100 mob-padding-46-43 space-between'>
              <div className='column  mob-fullwidth' style={{ maxWidth: 610 }}>
                <h3 className='font42 mob-font26 mb30' style={{ maxWidth: 610 }}> {t("Built in microlearning framework")}</h3>
                <p className='font24 mob-font14 mob-mb-20'> {t('Forget about sending long- read pdf’s or videos that employees ignore. Our method is to educate employees with bite-sized information with several cards & quiz.')}</p>
              </div>
              <div className='mob-fullwidth img-container-14'>
                <img className='mob-img' src="/images/sat-card.png" width="422" alt="" />
              </div>
            </div>
          </div>
          <div className='br30 mb40' style={{ backgroundColor: '#141417', backgroundImage: 'radial-gradient(69.81% 148.05% at -3.88% -63.98%, #D459FF 0%, rgba(212, 89, 255, 0) 100%), radial-gradient(41.5% 107.12% at 96.04% -7.12%, #6D4AFF 0%, rgba(109, 74, 255, 0) 91.36%)' }}>
            <div className='row mob-column-reverse space-between padding-90-100 mob-padding-46-43'>
              <div className='one-of-2 mob-hide img-container-15'>
                <img className='mob-img' src="/images/gamification-platform4.png" width="640" alt="" />
              </div>
              <div className='one-of-2 hide mob-show mob-fullwidth img-container-15'>
                <img className='mob-img' src="/images/gamification-platform4-mob.png" alt="" />
              </div>
              <div className='one-of-2 mob-fullwidth column'>
                <p className='font42 mob-font26 mb30'> {t("Magical experience with gamification platform")}</p>
                <p className='font24 mob-font14 mob-mb-20 mb40' style={{ maxWidth: 520 }}> {t("Save up 90 % of your time with our engagement platform. We want employees to stay motivated across their journey in Cyberlift gamified platform. Employees are earning stars and get beautiful achievements at different levels.")}</p>
                <Button onClick={() => navigate('/gamification-platform')} variant={'link'}> {t('Learn More')}
                  <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.5625 5.25C1.14829 5.25 0.8125 5.58579 0.8125 6C0.8125 6.41421 1.14829 6.75 1.5625 6.75L1.5625 5.25ZM14.0928 6.53033C14.3857 6.23744 14.3857 5.76256 14.0928 5.46967L9.31986 0.696699C9.02697 0.403806 8.55209 0.403806 8.2592 0.696699C7.96631 0.989593 7.96631 1.46447 8.2592 1.75736L12.5018 6L8.2592 10.2426C7.96631 10.5355 7.96631 11.0104 8.2592 11.3033C8.55209 11.5962 9.02697 11.5962 9.31986 11.3033L14.0928 6.53033ZM1.5625 6.75L13.5625 6.75V5.25L1.5625 5.25L1.5625 6.75Z" fill="#6D4AFF" />
                  </svg>
                </Button>
              </div>
            </div>
          </div>
          <div className='br30 mb40' style={{ background: 'linear-gradient(0deg, rgb(25, 25, 29) 28%, rgba(20, 20, 23, 0) 160.68%)' }}>
            <div className='row mob-column padding-90-100 mob-padding-46-43'>
              <div className='column'>
                <p className='font42 mob-font26 mb30'> {t("Optimize compliance procedures")}</p>
                <p className='font24 mob-font14 mob-mb-20'> {t("Cyberlift security trainings modules cover compliance needs for modern business. Set up trainings based on your certifications and regulatory requirements across different countries and regulations.")}</p>
              </div>
              <img className='mob-img' src="/images/compliance.png" width="494" alt="" />
            </div>
          </div>
          <div className='br30 pb40' style={{ background: 'linear-gradient(180deg, #19191D 0%, rgba(20, 20, 23, 0) 122.68%)' }}>
            <div className='column column-center padding-90-100 mob-padding-46-43'>
              <p className='font42 mob-font26 mb30 text-center'> {t("Fully integrated platform")}</p>
              <p className='font24 mob-font14 mob-mb-20 text-center' style={{ maxWidth: 760 }}> {t("Experience a streamlined user and admin interface that consolidates all your data into a single, centralized place.")}</p>
              <div className='img-container-16'>
                <img className='mob-img' src="/images/world-shield.png" width="100%" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='dark-soft pb150 mob-pb-100'>
        <PromoBlock imgWidth={620} dark={true}>
          <p className='sato34 mb36 font500 mob-mw263 mob-font-24' style={{ maxWidth: 510 }}> {t("The knowledge you need to get to stay tuned with the new era of security.")}</p>
        </PromoBlock>
      </section>
      <Footer theme={THEMES.dark} />
    </>
  )
}