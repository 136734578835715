import React from 'react'
import Navigation from '../../components/navigation'
import Footer from '../../components/footer'
import { THEMES } from 'src/constatnts'

import './style.css'
import { Button } from 'src/components/button'
import Divider from 'src/components/Divider'
import { useNavigate } from 'react-router-dom'
import PromoBlock from 'src/components/PromoBlock'
import { useTranslation } from 'react-i18next'

export const GlobalWorkforceDefensePage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <>
      <Navigation style={{ borderBottom: '1px solid #4F4F4F80', background: '#14141740' }} theme={THEMES.dark} />
      <section className='dark pt110 pb150 mob-pb-20'>
        <div className='container1300 '>
          <div className='row br30 people-bcg padding-112-110 mob-padding-46-43'>
            <div className='darker'></div>
            <div className='column column-start mob-column-center mob-text-center'>
              <p className='secondary-heading mob-text-center word-space-0'>{t("Global Workforce Defense")}</p>
              <h1 style={{ maxWidth: '540px' }} className='mb40 mob-text-center'>{t("Defend your global workforce from cyber threats")}</h1>
              <p className='mb50 heading-description mob-text-center'>{t("Secure your organization's sensitive data no matter")} <br className='mob-hide' /> {t("where your employees are located with our global")}<br className='mob-hide' /> {t("workforce defense solution.")}</p>
              {/* <p className='mb50 heading-description mob-text-center' style={{ maxWidth: 350 }}>{t("Secure your organization's sensitive data no matter where your employees are located with our global workforce defense solution.")}</p> */}

              <Button onClick={() => navigate('/pricing')} variant={'gradient-button'} >{t('Get started')} <svg style={{ marginLeft: '20px' }} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill="#E3E3FF" />
              </svg>
              </Button>
            </div>
            {/* <img style={{ margin: 0 }} src="/images/atom-cc.png" width="559" alt="" /> */}
          </div>
        </div>
      </section>
      <section className='dark pb150 mob-pb-20'>
        <div className='container1300 pb20'>
          <div className='row space-between mob-column  mb30'>
            <div className='br30 column mob-column-reverse one-of-2 mob-fullwidth padding-74-100 mob-padding-46-43 mob-mb-20' style={{ background: 'radial-gradient(131.38% 131.38% at 83.41% -31.38%, #141417 0.14%, rgba(29, 29, 41, 0.42) 85.96%)' }}>
              <div className='column column-center img-container-56'>
                <img src="/images/earth.png" className='mob-img' width={417} alt="" />
              </div>
              <div className='column'>
                {/* <p className='font46 mb20 mob-font-26 '>Global <br className='mob-hide' /> Coverage</p> */}
                <p className='font46 mb20 mob-font-26 '>{t("Global Coverage")}</p>
                <p className='font20 mob-font-14 mob-mb-40 '>{t("With coverage in over 100 countries, we've got your global workforce covered against cyber threats.")}</p>

              </div>
            </div>
            <div className='br30 people-read column one-of-2 mob-fullwidth ondemand-gradient padding-74-100 mob-padding-46-43 mob-mb20' style={{ justifyContent: 'flex-end' }}>
              {/* <p className='font46 mb20 mob-font-26'>Global threat <br /> intelligence</p> */}
              <p className='font46 mb20 mob-font-26'>{t("Global threat intelligence")}</p>
              <p className='font20 mob-font-14 mob-mb-40' style={{ maxWidth: 418 }}>{t("Stay informed about the latest global cyber threats and use these insights to protect your organization.")}</p>
            </div>
          </div>

          <div className='br30 column text-center mb30 padding-85-100-90 mob-padding-46-43 ' style={{ background: 'radial-gradient(294.26% 340.74% at 57.62% -5.44%, rgba(29, 29, 41, 0.86) 0%, rgba(29, 29, 41, 0) 100%)' }}>
            <p className='gradient-text font50 mob-font45 mb30'>{t("Scalability")}</p>
            <p className='font22 mob-font14'>{t("Our solutions support a wide range of business sizes, from SMEs to multinational")} <br /> {t("corporations, ensuring adaptable and scalable cyber defense strategies.")}</p>
            {/* <p className='font22 mob-font14'>{t("Our solutions support a wide range of business sizes, from SMEs to multinational corporations, ensuring adaptable and scalable cyber defense strategies")}.</p> */}
          </div>

          <div className='br30 row mob-column mob-column-reverse mb30' style={{ background: 'radial-gradient(294.26% 340.74% at 57.62% -5.44%, rgba(29, 29, 41, 0.86) 0%, rgba(29, 29, 41, 0) 100%)' }}>
            <div className='one-of-2 mob-fullwidth training-langs'></div>

            <div className='column one-of-2 mob-fullwidth padding-74-100 mob-padding-46-43 mb20'>
              <p className='font46 mb20 mob-font-26 mob-sato-26 mob-text-center'>{t("Training in local languages")}</p>
              <p className='font20 mob-font-14 mob-text-center' style={{ maxWidth: 381 }}>{t("Provide cybersecurity training in your employees' native languages, improving their understanding and application of key concepts.")}</p>
            </div>
          </div>

          <div className='br30 row space-between mob-column'
            style={{ background: 'radial-gradient(286.57% 286.57% at 55.85% -83.15%, rgba(29, 29, 41, 0) 0%, #1D1D29 100%)' }}
          >
            <div className='one-of-2 mob-fullwidth padding-74-100 mob-padding-46-43 mb20'>
              <p className='font66 mob-font42 font600'>{t("Privacy and Compliance Across Borders")}</p>
            </div>
            <div className='one-of-2 mob-fullwidth br30 padding-74-100 mob-padding-46-43' style={{ background: '#6D4AFF' }}>
              <p className='font30 mart80 mob-font18'>{t("We navigate the complexities of cross-border data privacy laws and regulations, ensuring your global workforce operates within the necessary legal frameworks.")}</p>
            </div>
          </div>
        </div>
      </section>


      <section className='dark pb150 mob-pb-100'>
        <PromoBlock>
          <p className='mb36 sato36 font500 mob-font-24'>{t("An all-new platform.")}<br />
            {t("An all-new world for security teams.")}</p>
        </PromoBlock>


      </section>
      <Divider style={{ margin: 0, background: '#2F2F2F' }}></Divider >
      <Footer theme={THEMES.dark} />
    </>
  )
}