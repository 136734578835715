import React from "react";

const Divider = ({ style }) => <div style={{
  width: '100%',
  height: '1px',
  background: '#1D1D2920',
  // opacity: 0.5,
  marginTop: '30px',
  marginBottom: '36px',
  ...style
}} className=""></div>

export default Divider