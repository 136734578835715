import React from "react";
import { THEMES } from "../../constatnts";

export const Logo = ({ theme = THEMES.dark }) => {
  const isMobile = window.innerWidth < 768
  return (
    <svg width={isMobile ? "148" : "114"} height={isMobile ? "39" : "24"} viewBox="0 0 114 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.875 10.0148C25.875 4.75811 29.6655 0.894531 34.7275 0.894531C38.2061 0.894531 40.8206 2.41018 42.4765 5.5154L40.7967 6.66455L40.3884 6.56632C39.0693 4.1941 37.2694 3.14318 34.7752 3.14318C31.1049 3.14318 28.3702 6.05301 28.3702 10.0138C28.3702 13.9745 31.1525 16.8843 34.799 16.8843C37.5098 16.8843 39.2372 15.8577 40.5087 13.5098L40.9408 13.4116L42.548 14.5607C40.7729 17.6659 38.2538 19.133 34.7275 19.133C29.6417 19.133 25.875 15.2937 25.875 10.0127V10.0148Z" fill={theme === THEMES.dark ? '#fff' : '#1D1D29'} />
      <path d="M44.8976 24.0007L47.8965 17.6434L43.5547 7.78906H46.0738L49.025 15.418H49.0726L52.1668 7.78906H54.6133L47.3204 24.0007H44.8976Z" fill={theme === THEMES.dark ? '#fff' : '#1D1D29'} />
      <path d="M58.1186 16.8128V18.6707H56.0078V0.748047H58.1912V9.67294C59.1269 8.15729 60.5424 7.32606 62.1972 7.32606C65.0997 7.32606 67.1152 9.60006 67.1152 13.1457C67.1152 16.6914 64.9805 19.1365 62.0532 19.1365C60.4937 19.1365 58.9341 18.2567 58.1186 16.8139V16.8128ZM64.8593 13.17C64.8593 10.7492 63.6593 9.33073 61.6211 9.33073C59.5828 9.33073 58.1186 10.9689 58.1186 13.2429C58.1186 15.5169 59.5103 17.1308 61.5734 17.1308C63.6365 17.1308 64.8603 15.6151 64.8603 13.17H64.8593Z" fill={theme === THEMES.dark ? '#fff' : '#1D1D29'} />
      <path d="M70.8739 13.6084V13.731C70.8739 15.9067 72.3132 17.2523 74.2084 17.2523C75.5276 17.2523 76.4633 16.6408 77.2788 15.3448L77.4705 15.2963L79.1015 16.2257C78.0456 18.1089 76.3192 19.1355 74.1359 19.1355C70.8013 19.1355 68.5703 16.7147 68.5703 13.243C68.5703 9.77127 70.8251 7.32617 74.0157 7.32617C77.2063 7.32617 79.2694 9.50194 79.2694 13.1944C79.2694 13.3412 79.2694 13.4141 79.2456 13.6106H70.8728L70.8739 13.6084ZM70.9215 12.0432H77.0146C76.8944 10.2339 75.7431 9.15762 74.0395 9.15762C72.336 9.15762 71.2086 10.2096 70.9205 12.0432H70.9215Z" fill={theme === THEMES.dark ? '#fff' : '#1D1D29'} />
      <path d="M81.625 18.6699V7.78895H83.6156V9.91613C84.5275 8.08257 85.6549 7.32422 87.0704 7.32422C88.1263 7.32422 88.9657 7.91147 89.3729 9.18207L87.5502 10.2583L87.3346 10.2098C87.0704 9.57392 86.7347 9.35423 86.1834 9.35423C85.0083 9.35423 83.8083 10.9195 83.8083 13.6086V18.6699H81.625Z" fill={theme === THEMES.dark ? '#fff' : '#1D1D29'} />
      <path d="M90.8125 18.6707V0.748047H92.9958V18.6707H90.8125Z" fill={theme === THEMES.dark ? '#fff' : '#1D1D29'} />
      <path d="M95.7031 2.75198C95.7031 1.89645 96.3508 1.23633 97.214 1.23633C98.0771 1.23633 98.7248 1.92075 98.7248 2.77627C98.7248 3.63179 98.0533 4.24333 97.214 4.24333C96.3746 4.24333 95.7031 3.6075 95.7031 2.75198ZM96.1114 18.67V7.78899H98.2947V18.67H96.1114Z" fill={theme === THEMES.dark ? '#fff' : '#1D1D29'} />
      <path d="M106.757 2.75239C106.349 2.557 105.942 2.48306 105.63 2.48306C104.623 2.48306 104.214 3.04496 104.214 5.05068V7.78941H106.781V9.62297H104.214V18.6704H102.031V9.62297H100.016V7.78941H102.031V5.1732C102.031 1.65183 103.038 0.454102 105.39 0.454102C105.893 0.454102 106.541 0.552328 107.093 0.747725L106.973 2.65522L106.757 2.75345V2.75239Z" fill={theme === THEMES.dark ? '#fff' : '#1D1D29'} />
      <path d="M113.177 16.8858L113.248 18.7691C112.648 19.0141 111.929 19.1356 111.185 19.1356C109.098 19.1356 107.898 17.9378 107.898 15.7853V9.62342H105.883V7.78986H107.922V4.48923L109.745 3.38867H110.081V7.78986H113.223V9.62342H110.081V15.1495C110.081 16.6408 110.489 17.1298 111.664 17.1298C112.096 17.1298 112.503 17.0073 112.959 16.7876L113.175 16.8858H113.177Z" fill={theme === THEMES.dark ? '#fff' : '#1D1D29'} />
      <path d="M19.8664 5.97018C18.4291 2.68645 15.3163 0.439913 11.8232 0.0554567C8.29481 -0.333225 4.76644 1.34719 2.71782 4.28026C1.72925 5.69556 1.13446 7.36753 0.952079 9.09125C0.763485 10.8741 1.0785 12.6792 1.79764 14.3099C3.23489 17.5672 6.35188 19.7906 9.81703 20.1718C13.5454 20.5827 17.2841 18.6773 19.255 15.4369C19.4934 15.045 19.7016 14.6374 19.884 14.2159C19.9949 13.9603 19.9576 13.6604 19.7016 13.5083C19.4861 13.3794 19.1182 13.4365 19.0074 13.6942C18.2913 15.3524 17.1494 16.7603 15.6634 17.7331C14.249 18.6594 12.6211 19.1642 10.9797 19.1917C7.87721 19.2434 4.88147 17.6 3.24733 14.8908C2.80071 14.1515 2.49814 13.4544 2.26498 12.6337C2.14893 12.225 2.05981 11.8088 1.99764 11.3885C1.98002 11.2702 1.98727 11.3209 1.9738 11.2005C1.96344 11.1096 1.95515 11.0199 1.94686 10.929C1.92924 10.7125 1.91785 10.4949 1.91474 10.2774C1.90852 9.86438 1.92821 9.45035 1.9738 9.03949C2.00593 8.75432 2.08053 8.33184 2.16861 7.96534C2.36343 7.15946 2.61316 6.50144 2.98413 5.8033C4.51257 2.92515 7.52282 1.07152 10.7144 1.03667C13.8158 1.00287 16.8023 2.66533 18.4229 5.3861C18.6354 5.74204 18.8229 6.11276 18.9897 6.49299C19.1017 6.7486 19.4664 6.8088 19.684 6.67889C19.942 6.52468 19.9783 6.22683 19.8664 5.97123V5.97018Z" fill={theme === THEMES.dark ? '#fff' : '#1D1D29'} />
      <path d="M17.2715 6.43115C15.8125 3.78959 12.8468 2.2486 9.89356 2.64467C7.06776 3.02385 4.67821 5.03591 3.79016 7.80104C3.18811 9.67685 3.37359 11.729 4.21916 13.4929C4.33936 13.7443 4.68961 13.8119 4.91343 13.6788C5.16524 13.5288 5.21601 13.2225 5.09581 12.9711C4.45231 11.6298 4.27511 10.0761 4.61707 8.62276C4.93105 7.28984 5.61703 6.14175 6.58695 5.2556C7.42526 4.49091 8.46771 3.96703 9.61378 3.73361C9.69046 3.71777 9.76817 3.70404 9.84589 3.69031C9.88009 3.68503 9.91532 3.67975 9.94951 3.67341C10.0366 3.65968 9.8832 3.68186 9.97024 3.67024C10.1184 3.65123 10.2666 3.6375 10.4158 3.62799C10.7153 3.61004 11.0158 3.61109 11.3142 3.63327C11.394 3.63856 11.4738 3.64595 11.5526 3.65545C11.5878 3.65968 11.623 3.6639 11.6583 3.66813C11.7474 3.67869 11.594 3.65757 11.6821 3.6713C11.8323 3.69348 11.9826 3.72094 12.1318 3.75368C12.4344 3.82022 12.7318 3.90683 13.0219 4.01456C13.3432 4.13286 13.6571 4.28178 13.9929 4.47823C14.3286 4.67469 14.6447 4.89438 14.9327 5.1447C15.5338 5.66646 16.0084 6.25899 16.3928 6.95502C16.714 7.53593 17.5907 7.01417 17.2695 6.4322L17.2715 6.43115Z" fill={theme === THEMES.dark ? '#fff' : '#1D1D29'} />
      <path d="M7.50494 16.8477C8.96292 17.5733 10.5794 17.812 12.1763 17.5163C13.5783 17.2564 14.8984 16.5562 15.9285 15.5549C16.5378 14.9624 17.0455 14.2547 17.4217 13.4869C17.5439 13.2365 17.487 12.926 17.2393 12.7792C17.0144 12.6451 16.6673 12.7137 16.545 12.9651C15.9544 14.1702 15.0746 15.1282 13.9389 15.7788C12.8032 16.4294 11.6136 16.6861 10.339 16.5974C9.59088 16.5456 8.75464 16.3217 8.01788 15.9552C7.77125 15.8327 7.46868 15.8865 7.3236 16.1411C7.19304 16.3682 7.25832 16.7262 7.50598 16.8487L7.50494 16.8477Z" fill={theme === THEMES.dark ? '#fff' : '#1D1D29'} />
      <path d="M14.7454 8.24829C14.0926 6.80552 12.6968 5.85282 11.1549 5.71658C9.60778 5.57927 8.08452 6.37036 7.22341 7.67266C6.36127 8.97601 6.28562 10.6807 6.95606 12.0833C7.6265 13.486 9.06894 14.4271 10.6046 14.5189C11.4709 14.5707 12.361 14.3331 13.0978 13.8694C13.933 13.3434 14.5392 12.5534 14.8905 11.626C14.9889 11.3651 14.7817 11.0578 14.5361 10.9891C14.2491 10.9089 14.0107 11.0884 13.9112 11.3503C13.6916 11.9291 13.2584 12.5079 12.7393 12.8723C12.2015 13.2494 11.5953 13.4585 11.0025 13.486C9.84093 13.5398 8.69797 12.9706 8.06587 11.9777C7.88452 11.6936 7.76743 11.4433 7.67728 11.1549C7.56951 10.8096 7.53013 10.5867 7.5177 10.2646C7.51148 10.1114 7.51563 9.95827 7.52703 9.80618C7.53013 9.76182 7.53428 9.71746 7.53946 9.67204C7.53635 9.69528 7.52495 9.75654 7.54257 9.64986C7.55708 9.5622 7.57262 9.47453 7.59231 9.38687C7.62547 9.239 7.66692 9.09325 7.71666 8.95066C7.77468 8.78378 7.7923 8.74153 7.88556 8.56092C8.4472 7.47198 9.55597 6.76644 10.7321 6.73686C11.3911 6.71996 12.0077 6.8879 12.5776 7.24595C13.1475 7.604 13.5724 8.11204 13.8698 8.77005C13.9848 9.02354 14.3444 9.08691 14.5641 8.95594C14.82 8.80385 14.8615 8.50283 14.7464 8.24829H14.7454Z" fill={theme === THEMES.dark ? '#fff' : '#1D1D29'} />
    </svg>
  )
}