import React, { useRef } from 'react'
import './home.css'
import Navigation from '../../components/navigation'
import Footer from '../../components/footer'
import { THEMES } from '../../constatnts'
import { Button } from 'src/components/button'
import Spacer from 'src/components/Spacer'
import PromoBlock from 'src/components/PromoBlock'
import { Link, useNavigate } from 'react-router-dom'
import CountUp from 'react-countup'
import { useIsInViewportOnce } from 'src/hooks'
import { Grow, Slide, Zoom } from '@mui/material'
import { useTranslation } from 'react-i18next';

const Intersector = ({ children }) => {
  const someRef = useRef(null)
  const isInViewport = useIsInViewportOnce(someRef);

  return <div ref={someRef}>{isInViewport && children}</div>
}

const productProps = [
  { num: '01', text: 'Access to sensitive systems' },
  { num: '02', text: 'Analyzing Adam co-workers' },
  { num: '03', text: 'Matchmaking phishing trends to personalize simulations' },
  { num: '04', text: 'Launching anonymous simulations for audit' },
  { num: '05', text: 'Detect Adam vulnerability patterns, risks #passwords, #servers, #bitbucket' },
  { num: '06', text: 'spoofing simulation test #bitbucket, #passwords, #co-worker request' },
  { num: '07', text: 'Intention to report simulation' },
  { num: '08', text: 'Microtraining engagement' },
  { num: '09', text: 'Knowledge test' },
]

export const HomePage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const containerRef = useRef(null)
  return (
    <>
      <Navigation theme={THEMES.dark} />
      <section className='dark heading-section gradient5'>
        <div className='container'>
          <div className='row row-center mob-column-reverse mob-column-center'>
            <div className='column column-start one-of-2 mob-fullwidth mob-column-center' style={{ maxWidth: '470px' }}>
              <h1 className='mob-text-center' style={{ textShadow: '2px 2px 12px black' }}>
                {t('home.title')}
              </h1>

              <p className='mob-text-center heading-description mw371 mom-mw305' style={{ marginTop: '33px' }}>
                {t('home.subtitle')}
              </p>
              <Link to="https://admin.cyberlift.ai/registration">

                <Button variant={'gradient-button'} >{t('Get started')} <svg style={{ marginLeft: '20px' }} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill="#E3E3FF" />
                </svg>
                </Button>
              </Link>
            </div>
            <div className='mob-hide' style={{ position: 'relative' }}>
              <img style={{ margin: '-150px -116px -110px 0px', animationDuration: '1s', animationName: 'apear' }} src="/images/ai-powered-sim-b.png" width="665" alt="" />
              <img style={{ position: 'absolute', left: -85, bottom: 27, animationDuration: '3s', animationName: 'slidein2' }} width={304} src="/images/p1.png" alt="" />
              <img style={{ position: 'absolute', left: 71, bottom: -91, animationDuration: '2s', animationName: 'slidein' }} width={304} src="/images/p2.png" alt="" />

            </div>
            <img className='hide mob-show' style={{ marginTop: '-80px' }} src="/images/mob-ai-powered-sim.png" width="375" alt="" />
          </div>
        </div>
      </section>
      <section className='header-to-dark darker padding140-150 box-shadow'>
        <div className='container'>
          <div className='column column-center'>
            <h2 className='text-center mb100 mob-mb-73 mw900 mob-mw341 mob-sato-26'>{t('home.setction2title')}</h2>

          </div>
          <div className='row space-between mob-column margin--40 mob-margin-0'>
            <div className='column column-center one-of-4 mob-fullwidth mob-mb-40'>
              <div className='gradient-text text-center big-num mb40 mob-mb-20'> <Intersector><CountUp end={93} />%</Intersector></div>
              <p className='text-center font16 mob-font14 mob-font500 mw279'>{t('home.setction2text1')}</p>
            </div>

            <div className='column column-center one-of-4 mob-fullwidth mob-mb-40'>
              <div className='gradient-text text-center big-num mb40 mob-mb-20'><Intersector><CountUp end={82} />%</Intersector></div>
              <p className='text-center font16 mob-font14 mob-font500 mw339 mob-mw299'>{t('home.setction2text2')}</p>
            </div>

            <div className='column column-center one-of-4 mob-fullwidth mob-mb-20'>
              <div className='gradient-text text-center big-num mb40 mob-mb-20'><Intersector><CountUp duration={3} end={30} />%</Intersector></div>
              <p className='text-center font16 mob-font14 mob-font500 mw279 mob-mw245'>{t('home.setction2text3')}</p>
            </div>

            <div className='column column-center one-of-4 mob-fullwidth mob-mb-20'>
              <div className='gradient-text text-center big-num mb40 mob-mb-20'><Intersector>$<CountUp duration={3} end={4} />.45M</Intersector></div>
              <p className='text-center font16 mob-font14 mob-font500 mw279 mob-mw245'>{t('home.setction2text4')}</p>
            </div>
          </div>

        </div>
      </section>
      <section className='dark padding140-150 mob-padding-82-0-102'>
        <div className='container1080'>
          <div className='row space-between mob-column'>
            <div className='one-of-2 mob-fullwidth pr50 mob-pad-0'>
              <p className='sato30 mob-font22 mob-mb-60' style={{ maxWidth: '440px' }}>
                {t('home.section3title')}
              </p>
            </div>
            <div className='column one-of-2 mob-fullwidth'>
              <div className='row mb20'>
                <div className='mr10'>
                  <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M26.4276 25.2503V25.2503C26.4276 21.1719 23.1214 17.8657 19.0429 17.8657H7.96274C3.88433 17.8657 0.578125 21.1719 0.578125 25.2503V25.2503" stroke="white" strokeWidth="0.923135" />
                    <ellipse cx="13.4984" cy="7.40385" rx="6.15463" ry="6.15385" stroke="white" strokeWidth="0.923135" />
                  </svg>

                </div>
                <div className='column'>
                  <p style={{ fontWeight: '700', marginBottom: '10px' }}>Adam Smith</p>
                  <p className='mob-font-14'>{t('Engineering team, Australia remote')}</p>
                </div>
              </div>

              <div className='column'>
                <div className='row'></div>
                {productProps.map((el, index) => <div key={el.num} className='column'>
                  <div style={{ color: '#CECEFF', marginBottom: '14px', marginTop: '14px' }} className='row font13 mob-font12'>
                    <span style={{ marginRight: '18px' }}>{el.num}</span>
                    <Intersector>
                      {/* <Grow mountOnEnter={true} in={true} timeout={(index + 1) * 500}> */}
                      <p>
                        {t('home.' + el.text)}
                      </p>
                      {/* </Grow> */}
                    </Intersector>
                  </div>
                  <div style={{ width: '100%' }} className='risk-ranging-line'></div>

                </div>)}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='dark pb50'>

        <div className='container1080'>
          <div className='column'>
            <div className='br30 mob-br20 gradient1 mob-gradient1 content-box automted-card mb40 mob-mb-20 padding-74-100 mob-padding-46-43'>
              <div className='row mob-column'>
                <div className='one-of-2 mob-fullwidth' style={{ flexShrink: 0, zIndex: 100 }}>
                  <h3 className='mb36 mob-font26 mb30'>{t('Automated and personalized attack simulation')}</h3>
                  <p className='mb40 font18-400 mob-font14 mob-mb-30' style={{ maxWidth: 389 }}>{t('Our AI generates multi-step simulation scenarios and templates, delivering targeted attacks to each user via email, Slack, or Teams, effectively reducing individual human risk.')}</p>
                  <Spacer />
                  <Button onClick={() => navigate('/ai-powered-cyber-attack-simulation')} variant={'link'}>{t('Learn More')}
                    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.5625 5.25C1.14829 5.25 0.8125 5.58579 0.8125 6C0.8125 6.41421 1.14829 6.75 1.5625 6.75L1.5625 5.25ZM14.0928 6.53033C14.3857 6.23744 14.3857 5.76256 14.0928 5.46967L9.31986 0.696699C9.02697 0.403806 8.55209 0.403806 8.2592 0.696699C7.96631 0.989593 7.96631 1.46447 8.2592 1.75736L12.5018 6L8.2592 10.2426C7.96631 10.5355 7.96631 11.0104 8.2592 11.3033C8.55209 11.5962 9.02697 11.5962 9.31986 11.3033L14.0928 6.53033ZM1.5625 6.75L13.5625 6.75V5.25L1.5625 5.25L1.5625 6.75Z" fill="#6D4AFF" />
                    </svg>

                  </Button>
                </div>
                <Intersector>
                  <div className='mob-hide' style={{ margin: '-120px 0px -270px 100px' }}>

                    <div className='row' style={{ transform: 'rotate(338deg)' }}>
                      <div className='column' style={{ marginTop: '70px' }} ref={containerRef}>
                        {/* <Zoom container={containerRef.current} in={true} timeout={500} duration={1000} direction='up'> */}
                        <img width="344" style={{ margin: '-42px' }} src="/images/card1.png" alt="" />
                        {/* </Zoom> */}
                        {/* <Zoom container={containerRef.current} in={true} timeout={1000} duration={1000} direction='up'> */}
                        <img width="344" style={{ margin: '-42px' }} src="/images/card3.png" alt="" />
                        {/* </Zoom> */}
                      </div>
                      <div className='column'>
                        {/* <Zoom container={containerRef.current} in={true} timeout={1500} duration={1000} direction='up'> */}
                        <img width="344" style={{ margin: '-42px' }} src="/images/card2.png" alt="" />
                        {/* </Zoom> */}
                        {/* <Zoom container={containerRef.current} in={true} timeout={2000} duration={1000} direction='up'> */}
                        <img width="344" style={{ margin: '-42px' }} src="/images/card4.png" alt="" />
                        {/* </Zoom> */}
                      </div>
                    </div>
                  </div>
                </Intersector>
                <div className='hide mob-show' style={{ margin: '-14px -43px -46px -43px' }}>
                  <img width="100%" src="/images/mob-cards.png" alt="" />
                </div>
              </div>
            </div>

            <div className='br30 mob-br20 dark-box-gradient mb40 mob-mb-20 padding-74-100 mob-padding-45-41 header-to-dark'>
              <div className='row space-between mob-column-reverse'>
                <div className='one-of-2 mob-fullwidth img-container-19'>
                  <Intersector>
                    <div style={{ width: 394, height: 320, position: 'relative' }} className='mob-hide'>
                      <div className='sat-card sat-card-purple sat-animate-1'>
                        <img width="147" className='mob-img mob-hide' src="/images/sat3.png" alt="" />
                      </div>
                      <div className='sat-card sat-card-blue sat-animate-2'>
                        <img width="202" className='mob-img mob-hide' src="/images/sat2.png" alt="" />
                      </div>
                      <div className='sat-card sat-card-pink sat-animate-3'>
                        <img width="147" className='mob-img mob-hide' src="/images/sat1.png" alt="" />
                      </div>

                    </div>

                  </Intersector>

                  <img className='mob-img hide mob-show' src="/images/security-awarennes-cards-mobile.png" alt="" />
                </div>
                <div className='mob-mb-20 one-of-2 mob-fullwidth'>
                  <h3 style={{ maxWidth: '419px' }} className='mb36 mob-font26 mob-font600 mb30'>{t('AI Assistant for Security Awareness')}</h3>
                  <p className='mb58 font18-400 mob-font14 mob-mb-20'>{t('Cyberlift\'s AI assistant creates unique, multilingual training content tailored to the specific context of your organization and employees.It automatically identifies training needs, delivers training via Slack/Teams, and helps reduce risks.')}
                    <strong>
                      <br />
                      <br />{t('GDPR, NIS2, ISO27001, HIPAA, SOC2, PCI DSS.')}
                    </strong>
                  </p>
                  <Button onClick={() => navigate('/security-awareness-trainings')} variant={'link'}>{t('Learn More')}
                    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.5625 5.25C1.14829 5.25 0.8125 5.58579 0.8125 6C0.8125 6.41421 1.14829 6.75 1.5625 6.75L1.5625 5.25ZM14.0928 6.53033C14.3857 6.23744 14.3857 5.76256 14.0928 5.46967L9.31986 0.696699C9.02697 0.403806 8.55209 0.403806 8.2592 0.696699C7.96631 0.989593 7.96631 1.46447 8.2592 1.75736L12.5018 6L8.2592 10.2426C7.96631 10.5355 7.96631 11.0104 8.2592 11.3033C8.55209 11.5962 9.02697 11.5962 9.31986 11.3033L14.0928 6.53033ZM1.5625 6.75L13.5625 6.75V5.25L1.5625 5.25L1.5625 6.75Z" fill="#6D4AFF" />
                    </svg>

                  </Button>
                </div>
              </div>
            </div>

            <div className='br30 mob-br20 gradient2 content-box mb40 mob-mb-20 padding-74-100 mob-padding-46-43'>
              <div className='row row-center mob-column'>
                <div className='mob-mb-20'>
                  <h3 className='mb36 mob-font26 mb30'>{t('Email threat reporting button')}</h3>
                  <p style={{ maxWidth: '339px' }} className='mb58 font18-400 mob-font14 mob-mb-20'>{t('Boost employee reporting rates with the Cyberlift plugin button in Outlook and Gmail. This AI assistant actively displays phishing indicators for real threats, enabling prompt identification and reporting for further investigation.')}</p>
                  <Button onClick={() => navigate('/email-threat-report-button')} variant={'link'}>{t('Learn More')}
                    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.5625 5.25C1.14829 5.25 0.8125 5.58579 0.8125 6C0.8125 6.41421 1.14829 6.75 1.5625 6.75L1.5625 5.25ZM14.0928 6.53033C14.3857 6.23744 14.3857 5.76256 14.0928 5.46967L9.31986 0.696699C9.02697 0.403806 8.55209 0.403806 8.2592 0.696699C7.96631 0.989593 7.96631 1.46447 8.2592 1.75736L12.5018 6L8.2592 10.2426C7.96631 10.5355 7.96631 11.0104 8.2592 11.3033C8.55209 11.5962 9.02697 11.5962 9.31986 11.3033L14.0928 6.53033ZM1.5625 6.75L13.5625 6.75V5.25L1.5625 5.25L1.5625 6.75Z" fill="#6D4AFF" />
                    </svg>

                  </Button>
                </div>
                <div className=''>
                  <img width="479" className='mob-hide' src="/images/threat-reporting-button.png" alt="" />
                  <img width="342" style={{ marginTop: 60 }} className='hide mob-show' src="/images/mob-threat-reporting-button.png" alt="" />
                </div>
              </div>
            </div>

            <div className='br30 mob-br20 dark-box-gradient itr-bg mb40 mob-mb-20 padding-74-100 mob-padding-46-43 header-to-dark'>
              <div className='row  mob-column-reverse'>
                <div className='img-container-7'>
                  <img width="417" className='mob-img' src="/images/feedback-improve.png" alt="" />
                </div>
                <div className='column'>
                  <h3 className='mb36 mob-font26 mb30'>{t('Intelligent threat response')}</h3>
                  <p className='mb40 font18-400 mob-font14 mob-mb-20'>{t('Get faster view of reported threats and resolve them automatically. Identify and manage human-related threats through already made integrations with other working and security tools.')}
                  </p>
                  <Spacer />
                  <Button onClick={() => navigate('/intelligent-threat-response')} variant={'link'}>{t('Learn More')}
                    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.5625 5.25C1.14829 5.25 0.8125 5.58579 0.8125 6C0.8125 6.41421 1.14829 6.75 1.5625 6.75L1.5625 5.25ZM14.0928 6.53033C14.3857 6.23744 14.3857 5.76256 14.0928 5.46967L9.31986 0.696699C9.02697 0.403806 8.55209 0.403806 8.2592 0.696699C7.96631 0.989593 7.96631 1.46447 8.2592 1.75736L12.5018 6L8.2592 10.2426C7.96631 10.5355 7.96631 11.0104 8.2592 11.3033C8.55209 11.5962 9.02697 11.5962 9.31986 11.3033L14.0928 6.53033ZM1.5625 6.75L13.5625 6.75V5.25L1.5625 5.25L1.5625 6.75Z" fill="#6D4AFF" />
                    </svg>

                  </Button>
                </div>
              </div>
            </div>

            <div className='br30 mob-br20 gradient3 content-box mb80 mob-mb-40 padding-74-100 mob-padding-46-43'>
              <div className='row space-between mob-column'>
                <div className='column one-of-2 mob-fullwidth'>
                  <h3 className='mb36 mob-font26 mb30'>{t('Gamification platform for employees')}</h3>
                  <p className='mb40 font18-400 mob-font14 mob-mb-20'>{t('Supercharge your employees with a gamification platform that changes human behavior through an interactive security journey.')}</p>
                  <Spacer />
                  <Button onClick={() => navigate('/gamification-platform')} variant={'link'}>{t('Learn More')}
                    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.5625 5.25C1.14829 5.25 0.8125 5.58579 0.8125 6C0.8125 6.41421 1.14829 6.75 1.5625 6.75L1.5625 5.25ZM14.0928 6.53033C14.3857 6.23744 14.3857 5.76256 14.0928 5.46967L9.31986 0.696699C9.02697 0.403806 8.55209 0.403806 8.2592 0.696699C7.96631 0.989593 7.96631 1.46447 8.2592 1.75736L12.5018 6L8.2592 10.2426C7.96631 10.5355 7.96631 11.0104 8.2592 11.3033C8.55209 11.5962 9.02697 11.5962 9.31986 11.3033L14.0928 6.53033ZM1.5625 6.75L13.5625 6.75V5.25L1.5625 5.25L1.5625 6.75Z" fill="#6D4AFF" />
                    </svg>

                  </Button>
                </div>
                <div className='img-container-8'>
                  <img width="505" className='mob-img mob-hide' src="/images/gamification-platform4-c.png" alt="" />
                  <img width="375" className='mob-img hide mob-show' src="/images/gamification-platform4-mob.png" alt="" />
                </div>
              </div>
            </div>

          </div>
        </div>

      </section>

      <section className='dark'>
        <div className='container'>
          <div className='column column-center mb70'>
            <h2 style={{ fontWeight: 400 }} className='text-center mob-font-24 mw890 mob-mw304'>{t('Combining human intelligence and training performance, Cyberlift empowers security teams with data that helps to predict and prevent cyber incidents.')}</h2>
          </div>

          <div className='row mob-column'>

            <div className='column column-center one-of-2 mob-fullwidth mob-row mb100 mob-hide'>
              <div className='column column-center'>
                <p className='mb40 font16 text-center' >{t('Overall company')}<br /> {t('Human Risk Score')}</p>
                <svg style={{ marginRight: '15px', marginTop: '-20px' }} width="261" height="176" viewBox="0 0 261 176" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M253 143C253 82.2487 203.751 33 143 33C82.2487 33 33 82.2487 33 143" stroke="#CECEFF" strokeOpacity="0.3" strokeWidth="16" strokeLinecap="round" />
                  <g filter="url(#filter0_d_3170_715)">
                    <path d="M25 143C25 147.418 28.5817 151 33 151C37.4183 151 41 147.418 41 143H25ZM41 143C41 86.667 86.667 41 143 41V25C77.8304 25 25 77.8304 25 143H41ZM143 41C155.574 41 167.599 43.2715 178.7 47.4196L184.3 32.4318C171.435 27.6245 157.515 25 143 25V41Z" fill="#6D4AFF" />
                  </g>
                  <path d="M243.32 73.3809L223.32 90.3809" stroke="#FA145F" strokeLinecap="round" />
                  <defs>
                    <filter id="filter0_d_3170_715" x="0" y="0" width="209.297" height="176" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="12.5" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0.427451 0 0 0 0 0.290196 0 0 0 0 1 0 0 0 0.35 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3170_715" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3170_715" result="shape" />
                    </filter>
                  </defs>
                </svg>
                <p style={{ fontSize: '26px', fontWeight: '600', marginTop: '-50px', marginBottom: '80px' }}><Intersector><CountUp end={42} />,<CountUp end={25} /> </Intersector></p>
              </div>

              <div className='column column-center' style={{ position: 'relative' }}>
                <p className='mb40 font16 text-center' >{t('Risk Category')}</p>
                <svg style={{ marginLeft: 6, marginTop: '-30px' }} width="286" height="286" viewBox="0 0 286 286" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="143" cy="143" r="110" stroke="#CECEFF" strokeOpacity="0.3" strokeWidth="16" />
                  <g filter="url(#filter0_d_0_1)">
                    <path d="M253 143C253 203.751 203.751 253 143 253C82.2487 253 33 203.751 33 143C33 82.2487 82.2487 33 143 33" stroke="#CECEFF" strokeOpacity="0.4" strokeWidth="16" shapeRendering="crispEdges" />
                  </g>
                  <path d="M253 143C253 203.751 203.751 253 143 253C82.2487 253 33 203.751 33 143C33 115.65 42.9813 90.6319 59.5 71.3887" stroke="#6D4AFF" strokeWidth="16" />
                  <defs>
                    <filter id="filter0_d_0_1" x="0" y="0" width="286" height="286" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="12.5" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0.427451 0 0 0 0 0.290196 0 0 0 0 1 0 0 0 0.35 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
                    </filter>
                  </defs>
                </svg>
                <p style={{ position: 'absolute', top: 43, left: 47, fontSize: 18, fontWeight: 600, opacity: 0.3 }}>9.4%</p>
                <p style={{ position: 'absolute', top: 80, left: 251, fontSize: 18, fontWeight: 600, opacity: 0.3 }}>25.6%</p>
                <div style={{ position: 'absolute', top: 256, left: 260, fontSize: 18, fontWeight: 600, width: 85 }} className='column column-start'>
                  <p><Intersector><CountUp end={72} />,<CountUp end={5} />% </Intersector></p>
                  <p style={{ fontSize: 12, marginTop: 6 }}>{t('Moderate risk')}</p>
                </div>
              </div>

            </div>





            <div className='row mb100 hide mob-flex'>
              <div className='column one-of-2 column-center'>
                <p className='mb10 font16 mob-font10 text-center' >{t('Overall company')}<br /> {t('Human Risk Score')}</p>
                <svg style={{ marginLeft: '-13px' }} width="142" height="96" viewBox="0 0 142 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M136.881 77.7183C136.881 44.9982 110.356 18.4732 77.6357 18.4732C44.9156 18.4732 18.3906 44.9982 18.3906 77.7183" stroke="#CECEFF" strokeOpacity="0.3" strokeWidth="8.61747" strokeLinecap="round" />
                  <g filter="url(#filter0_d_4156_1035)">
                    <path d="M14.0819 77.7183C14.0819 80.098 16.011 82.0271 18.3906 82.0271C20.7703 82.0271 22.6994 80.098 22.6994 77.7183H14.0819ZM22.6994 77.7183C22.6994 47.3778 47.2952 22.782 77.6357 22.782V14.1645C42.5359 14.1645 14.0819 42.6185 14.0819 77.7183H22.6994ZM77.6357 22.782C84.408 22.782 90.8846 24.0054 96.8633 26.2395L99.8797 18.1672C92.9508 15.578 85.4534 14.1645 77.6357 14.1645V22.782Z" fill="#6D4AFF" />
                  </g>
                  <path d="M131.672 40.222L120.9 49.3781" stroke="#FA145F" strokeWidth="0.538592" strokeLinecap="round" />
                  <defs>
                    <filter id="filter0_d_4156_1035" x="0.613332" y="0.699697" width="112.734" height="94.7922" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="6.7324" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0.427451 0 0 0 0 0.290196 0 0 0 0 1 0 0 0 0.35 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4156_1035" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4156_1035" result="shape" />
                    </filter>
                  </defs>
                </svg>


                <p style={{ fontSize: '16px', fontWeight: '600', marginTop: '-40px', marginBottom: '80px' }}>42,25</p>
              </div>

              <div className='column one-of-2 column-center' style={{ position: 'relative' }}>
                <p className='mb10 font16 mob-font10 text-center' >Risk Category</p>
                <svg width="156" height="156" viewBox="0 0 156 156" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="78.1402" cy="77.6697" r="59.687" stroke="#CECEFF" strokeOpacity="0.3" strokeWidth="8.68175" />
                  <g filter="url(#filter0_d_0_1)">
                    <path d="M137.827 77.6697C137.827 110.634 111.104 137.357 78.1402 137.357C45.1759 137.357 18.4531 110.634 18.4531 77.6697C18.4531 44.7054 45.1759 17.9826 78.1402 17.9826" stroke="#CECEFF" strokeOpacity="0.4" strokeWidth="8.68175" shapeRendering="crispEdges" />
                  </g>
                  <path d="M137.827 77.6697C137.827 110.634 111.104 137.357 78.1402 137.357C45.1759 137.357 18.4531 110.634 18.4531 77.6697C18.4531 62.8295 23.8691 49.2542 32.8323 38.8127" stroke="#6D4AFF" strokeWidth="8.68175" />
                  <defs>
                    <filter id="filter0_d_0_1" x="0.544138" y="0.0765171" width="155.193" height="155.186" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset />
                      <feGaussianBlur stdDeviation="6.78262" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0.427451 0 0 0 0 0.290196 0 0 0 0 1 0 0 0 0.35 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
                    </filter>
                  </defs>
                </svg>


                <p style={{ position: 'absolute', top: 'calc(50% - 60px)', left: 'calc(50% - 80px)', fontSize: 12, fontWeight: 600, opacity: 0.3 }}>9.4%</p>
                <p style={{ position: 'absolute', top: 'calc(50% - 57px)', left: 'calc(50% + 48px)', fontSize: 12, fontWeight: 600, opacity: 0.3 }}>25.6%</p>
                <div style={{ position: 'absolute', top: 'calc(50% + 78px)', left: 'calc(50% + 22px)', fontSize: 12, fontWeight: 600, width: 85 }} className='column column-start'>
                  <p style={{ marginBottom: 4 }}>72.5%</p>
                  <p style={{ fontSize: 8 }}>{t('Moderate risk')}</p>
                </div>
              </div>

            </div>




            <div className='column one-of-2 mob-fullwidth mb100'>
              <p className='mb40'>{t("Risk Rating By Dept")}.</p>
              <div className='column'>
                <p className='mb10 font13' style={{ color: '#CECEFF' }}>
                  Dept  1.
                </p>
                <div className='row row-center risk-rating-by-dept-line'>
                  <div style={{ width: '89%' }} className='risk-ranging-line-darker'></div>
                  <p style={{ color: '#CECEFF4D', fontSize: '13px' }}>89</p>
                </div>
              </div>
              <div className='column'>
                <p className='mb10 font13' style={{ color: '#CECEFF' }}>
                  Dept  2.
                </p>
                <div className='row row-center risk-rating-by-dept-line'>
                  <div style={{ width: '87%' }} className='risk-ranging-line-darker'></div>
                  <p style={{ color: '#CECEFF4D', fontSize: '13px' }}>87</p>
                </div>
              </div>
              <div className='column'>
                <p className='mb10 font13' style={{ color: '#CECEFF' }}>
                  Dept  3.
                </p>
                <div className='row row-center risk-rating-by-dept-line'>
                  <div style={{ width: '80%' }} className='risk-ranging-line-darker'></div>
                  <p style={{ color: '#CECEFF4D', fontSize: '13px' }}>80</p>
                </div>
              </div>
              <div className='column'>
                <p className='mb10 font13' style={{ color: '#CECEFF' }}>
                  Dept  4.
                </p>
                <div className='row row-center risk-rating-by-dept-line'>
                  <div style={{ width: '78%' }} className='risk-ranging-line-darker'></div>
                  <p style={{ color: '#CECEFF4D', fontSize: '13px' }}>78</p>
                </div>
              </div>
              <div className='column'>
                <p className='mb10 font13' style={{ color: '#CECEFF' }}>
                  Dept  5.
                </p>
                <div className='row row-center risk-rating-by-dept-line'>
                  <div style={{ width: '74%' }} className='risk-ranging-line-darker'></div>
                  <p style={{ color: '#CECEFF4D', fontSize: '13px' }}>74</p>
                </div>
              </div>
              <div className='column'>
                <p className='mb10 font13' style={{ color: '#CECEFF' }}>
                  Dept  6.
                </p>
                <div className='row row-center risk-rating-by-dept-line'>
                  <div style={{ width: '70%' }} className='risk-ranging-line'></div>
                  <p style={{ color: '#CECEFF', fontSize: '13px' }}>70</p>
                </div>
              </div>
              <div className='column'>
                <p className='mb10 font13' style={{ color: '#CECEFF' }}>
                  Dept  7.
                </p>
                <div className='row row-center risk-rating-by-dept-line'>
                  <div style={{ width: '68%' }} className='risk-ranging-line'></div>
                  <p style={{ color: '#CECEFF', fontSize: '13px' }}>68</p>
                </div>
              </div>
              <div className='column'>
                <p className='mb10 font13' style={{ color: '#CECEFF' }}>
                  Dept  8.
                </p>
                <div className='row row-center risk-rating-by-dept-line'>
                  <div style={{ width: '65%' }} className='risk-ranging-line'></div>
                  <p style={{ color: '#CECEFF', fontSize: '13px' }}>65</p>
                </div>
              </div>
              <div className='column'>
                <p className='mb10 font13' style={{ color: '#CECEFF' }}>
                  Dept  9.
                </p>
                <div className='row row-center risk-rating-by-dept-line'>
                  <div style={{ width: '63%' }} className='risk-ranging-line'></div>
                  <p style={{ color: '#CECEFF', fontSize: '13px' }}>63</p>
                </div>
              </div>
              <div className='column'>
                <p className='mb10 font13' style={{ color: '#CECEFF' }}>
                  Dept  10.
                </p>
                <div className='row row-center risk-rating-by-dept-line'>
                  <div style={{ width: '59%' }} className='risk-ranging-line'></div>
                  <p style={{ color: '#CECEFF', fontSize: '13px' }}>59</p>
                </div>
              </div>
              <div className='column'>
                <p className='mb10 font13' style={{ color: '#CECEFF' }}>
                  Dept  11.
                </p>
                <div className='row row-center risk-rating-by-dept-line'>
                  <div style={{ width: '52%' }} className='risk-ranging-line'></div>
                  <p style={{ color: '#CECEFF', fontSize: '13px' }}>52</p>
                </div>
              </div>
            </div>
          </div>
        </div>


      </section >

      <section className='dark pb150'>
        <PromoBlock>
          <p className='sato34 mb36 font500 mob-mw263 mob-font-24'>{t('Transform your employees into Human firewall today')}</p>
        </PromoBlock>
      </section>

      <Footer theme={THEMES.dark} />
    </>
  )
}