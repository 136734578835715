import React, { useRef, useState } from 'react'
import Navigation from '../../components/navigation'
import Footer from '../../components/footer'
import { THEMES } from 'src/constatnts'

import { Button } from 'src/components/button'
import Divider from 'src/components/Divider'
import { useNavigate } from 'react-router-dom'
import PromoBlock from 'src/components/PromoBlock'
import CountUp from 'react-countup'
import { useIsInViewportOnce } from 'src/hooks'
import LoadingIndicator from 'src/components/LoadingIndicator'
import CustomCheckbox from 'src/components/CustomCheckbox'
import Spacer from 'src/components/Spacer'
import { useTranslation } from 'react-i18next'

const Intersector = ({ children }) => {
  const someRef = useRef(null)
  const isInViewport = useIsInViewportOnce(someRef);

  return <div ref={someRef}>{isInViewport && children}</div>
}

export const LandingPage = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [agree, setAgree] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const [emailError, setEmailError] = useState(null)
  const [companyError, setCompanyError] = useState(null)
  const [agreeError, setAgreeError] = useState(null)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [employeesNumber, setEmployeesNumber] = useState('1-50')

  const clearForm = () => {
    setFirstName('')
    setLastName('')
    setEmail('')
    setCompanyName('')
    setEmployeesNumber('')
    setAgree(false)
    setAgreeError(null)
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  const handleSendForm = () => {
    if (!validateEmail(email)) {
      setEmailError(true)
    }
    if (!companyName) {
      setCompanyError(true)
    }
    if (!agree) {
      setAgreeError(true)
    }
    if (validateEmail(email) && !!companyName && agree) {
      setLoading(true)
      const data = {
        firstName,
        lastName,
        email,
        companyName,
        employeesNumber,
      }
      fetch(window._env_.REACT_APP_API_HOST + '/v1/companies/demo-request', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(response => {
        // console.log(response.status);
        if (response.status) {
          setLoading(false)
          clearForm()
          setSuccessMessage('Success! Data has been sent.')
        } else {
          setLoading(false)
          setErrorMessage('Something went wrong, please try again')
        }
      })
    }

  }

  return (
    <>
      <Navigation style={{ borderBottom: '1px solid #4F4F4F80', background: '#14141740' }} theme={THEMES.dark} />
      <section className='dark pt110 pb50 mob-pb-20'>
        <div className='container1300 '>
          <div className='row br30 people-bcg padding-112-110 mob-padding-46-43'>
            <div className='darker'></div>
            <div className='column column-start mob-column-center mob-text-center'>
              <p className='secondary-heading mob-text-center word-space-0'>{t("APP prevention")}</p>
              <h1 style={{ maxWidth: '540px' }} className='mb40 mob-text-center'>{t("Protect Your Organization Against Authorized Push Payment Fraud.")}</h1>
              <p style={{ maxWidth: 500 }} className='mb50 heading-description mob-text-center'>{t("Cyberlift helps to reduce Authorized Push Payment (APP) fraud by up to 50% of potential losses through automatic and engaging security awareness training for your end customers")}</p>

              <a href='#form' className={'gradient-button'} style={{ textDecoration: 'none' }}>{t('Get started')} <svg style={{ marginLeft: '20px' }} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill="#E3E3FF" />
              </svg>
              </a>
            </div>
            {/* <img style={{ margin: 0 }} src="/images/atom-cc.png" width="559" alt="" /> */}
          </div>
        </div>
      </section>
      <section className='header-to-dark darker padding140-150 box-shadow'>
        <div className='container'>
          <div className='column column-center'>
            <h2 className='text-center mb100 mob-mb-73 mw900 mob-mw341 mob-sato-26'>{t("APP risk prevention should be the highest priority for executives")}</h2>

          </div>
          <div className='row space-between mob-column margin--40 mob-margin-0'>
            <div className='column column-center one-of-3 mob-fullwidth mob-mb-40'>
              <p className='gradient-text text-center big-num mb40 mob-mb-20'> <Intersector><CountUp end={40} />%</Intersector></p>
              <p className='text-center font16 mob-font14 mob-font500 mw279'>{t("of all reported crimes in England and Wales are accounted for by fraud")}</p>
            </div>

            <div className='column column-center one-of-3 mob-fullwidth mob-mb-40'>
              <p className='gradient-text text-center big-num mb40 mob-mb-20'><Intersector>£<CountUp end={485} />.2 m</Intersector></p>
              <p className='text-center font16 mob-font14 mob-font500 mw339 mob-mw299'>{t("represents the total amount lost to APP fraud in the UK in 2022.")}</p>
            </div>

            <div className='column column-center one-of-3 mob-fullwidth mob-mb-20'>
              <p className='gradient-text text-center big-num mb40 mob-mb-20'><Intersector><CountUp duration={3} end={100} />%</Intersector></p>
              <p className='text-center font16 mob-font14 mob-font500 mw279 mob-mw245'>{t("of fraud losses are expected to be covered by financial institutions under new PSR reimbursement requirements.")}</p>
            </div>
          </div>

        </div>
      </section>
      <section id='form' className='dark heading-section'>
        <div className='container'>
          <div className='row mob-column'>
            <div className='column one-of-2 mob-fullwidth pricing-text-box'>
              <p className='sato34 font500 mob-sato-26-700 mb36 mw450 mob-mw-243' >{t("Security Awareness Training for your end customers is an essential part of reducing potential losses in the near term.")}</p>

              <p className='mb50 mob-mb-40 font18 mob-font-16' style={{ maxWidth: 410 }}>{t("Let’s set up a meeting to choose a pricing plan tailored to your business needs.")}</p>

              <div className='column pricing-pros'>

                <div className='row pricing-pros-item'>
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="13" cy="13" r="13" fill="#6D4AFF" fillOpacity="0.1" />
                    <path d="M8.26562 14.1814L11.6437 16.8076L17.7202 9.4541" stroke="#6D4AFF" strokeWidth="1.77273" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <p className='mob-font14' style={{ maxWidth: 450, fontWeight: 400 }}><strong>{t("Mitigate APP Fraud Risks")}:</strong> {t("Equip your customers with the necessary knowledge and skills to significantly reduce the likelihood of falling victim to APP fraud with Cyberlift.")}</p>
                </div>

                <div className='row pricing-pros-item'>
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="13" cy="13" r="13" fill="#6D4AFF" fillOpacity="0.1" />
                    <path d="M8.26562 14.1814L11.6437 16.8076L17.7202 9.4541" stroke="#6D4AFF" strokeWidth="1.77273" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <p className='mob-font14' style={{ maxWidth: 450, fontWeight: 400 }}><strong>{t("Build Trust and Loyalty")}:</strong> {t("Demonstrate your commitment to customer security, enhancing trust and reinforcing your reputation as a safe and responsible financial institution through Cyberlift's solutions.")}</p>
                </div>

                <div className='row pricing-pros-item'>
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="13" cy="13" r="13" fill="#6D4AFF" fillOpacity="0.1" />
                    <path d="M8.26562 14.1814L11.6437 16.8076L17.7202 9.4541" stroke="#6D4AFF" strokeWidth="1.77273" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <p className='mob-font14' style={{ maxWidth: 450, fontWeight: 400 }}><strong>{t("Ensure Regulatory Compliance")}:</strong> {t("With Cyberlift, meet and exceed the requirements set forth for APP fraud prevention and customer reimbursement, avoiding penalties and reinforcing customer confidence.")}</p>
                </div>

                <div className='row pricing-pros-item'>
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="13" cy="13" r="13" fill="#6D4AFF" fillOpacity="0.1" />
                    <path d="M8.26562 14.1814L11.6437 16.8076L17.7202 9.4541" stroke="#6D4AFF" strokeWidth="1.77273" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <p className='mob-font14' style={{ maxWidth: 450, fontWeight: 400 }}><strong>{t("Adaptable and Scalable Solutions")}:</strong> {t("Cyberlift offers adaptable and scalable solutions that cater to both small local banks and large multinational institutions, meeting diverse needs in the evolving landscape of online fraud prevention.")}</p>
                </div>



              </div>
            </div>


            <div style={{ opacity: loading ? 0.5 : 1, position: 'relative' }} className='br30 padding80 mob-padding-80-32 dark-box box-shadow one-of-2 mob-fullwidth column'>

              {loading && <div style={{ position: 'absolute', background: '#ffffff40', width: 'calc( 100% - 160px)', height: 'calc( 100% - 160px)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', boxSizing: 'border-box' }}>
                <LoadingIndicator />
              </div>}
              {successMessage && <p style={{
                color: '#00BC62',
                marginBottom: 20,
                marginTop: -20,
                fontWeight: 600
              }}>{successMessage}</p>}

              {errorMessage && <p style={{
                color: '#B40020',
                marginBottom: 20,
                marginTop: -20,
                fontWeight: 600
              }}>{errorMessage}</p>}

              <label htmlFor="firstName" >{t("First name")}</label>
              <input value={firstName} onChange={e => setFirstName(e.target.value)} style={{ padding: '0px 20px' }} id="firstName" type="text" />
              <label htmlFor="lastName" >{t("Last name")}</label>
              <input value={lastName} onChange={e => setLastName(e.target.value)} style={{ padding: '0px 20px' }} id="lastName" type="text" />
              <label htmlFor="email" >{t("Work email")}<span style={{ color: '#FA145F' }}>*</span></label>
              <input value={email} onChange={e => {
                setEmail(e.target.value)
                setEmailError(null)
              }} style={{ padding: '0px 20px' }} id="email" type="text" />
              {emailError && <p style={{ color: '#FA145F', margin: '-15px 0px 20px 0px' }}>{t("Please check email")}</p>}
              <label htmlFor="companyName" >{t("Company name")}<span style={{ color: '#FA145F' }}>*</span></label>
              <input value={companyName} onChange={e => {
                setCompanyName(e.target.value)
                setCompanyError(null)
              }} style={{ padding: '0px 20px' }} id="companyName" type="text" />
              {companyError && <p style={{ color: '#FA145F', margin: '-15px 0px 20px 0px' }}>{t("Company name is required")}</p>}
              <label htmlFor="employeesAmount" >{t("Number of employees")}</label>

              <div className='row ' style={{ marginBottom: 20 }}>
                <CustomCheckbox checked={agree} onChange={() => {
                  setAgree(!agree)
                  setAgreeError(null)
                }} />

                <label htmlFor="consent" style={{
                  margin: '0px 0px 0px 10px', lineHeight: 1.5, ...(agreeError && {
                    textDecorationLine: 'underline',
                    textDecorationStyle: 'wavy',
                    textDecorationColor: '#FA145F'
                  }
                  )
                }} ><p>{t("By clicking the button you agree")} <br /> {t("with our")} <a target='_blank' className='form-link' href="/privacy-policy">{t("Privacy Policy")}</a><span style={{ color: '#FA145F' }}>*</span></p></label>

              </div>

              <div className='column column-center'>

              </div>
              <div className='text-center'>
                <Button onClick={handleSendForm} style={{ padding: '16px 32px' }}>{t("Request quote")}
                  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill="white" />
                  </svg>
                </Button>
              </div>


            </div>
          </div>
        </div>
      </section >
      <section className='dark pb50'>
        <div className='container1200'>
          <div className='column column-center'>
            <h2 className='text-center mb50 mob-mb-73 mw900 mob-mw341 mob-sato-26'>{t("How it works?")}</h2>
          </div>
          <div className='br30 mob-br20 gradient2 content-box mb40 mob-mb-20 padding-74-100 mob-padding-46-43'>
            <div className='row row-center mob-column'>
              <div className='mob-mb-20'>
                <h3 className='mb36 mob-font26 mb30'>{t("Change consumers security Behavior and reduce Risk")}</h3>
                <p style={{ maxWidth: '339px' }} className='mb58 font18-400 mob-font14 mob-mb-20'>{t("Cyberlift assigns short, gamified security training modules to increase engagement and build long-term security behavior. Our AI-powered engine personalizes and delivers frequent security training and simulations to educate your end consumers on the most advanced payment fraud tactics")}</p>

              </div>
              <div className=''>
                <img width="479" className='mob-hide' src="/images/threat-reporting-button.png" alt="" />
                <img width="342" style={{ marginTop: 60 }} className='hide mob-show' src="/images/mob-threat-reporting-button.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='dark'>
        <div className='container1300'>
          <div
            style={{ background: '#1D1D29' }}
            className='br30 content-box padding-74-100 mob-padding-46-43 mb40'>
            <div className='row space-between mob-column'>
              <div className='column'>
                <p className='font32 mb40 mob-font22'>{t("Personalize trainings with AI ")}</p>
                <p style={{ maxWidth: '469px' }} className='font18 mob-font14'>{t("Cyberlift's AI engine generates a unique profile for every user and automatically delivers the most relevant training based on language, location, and current fraud trends. Training modules are crafted by experts in 30+ languages and can be customized according to your specific rules. Our content library consists of 170 training modules covering the latest trends, such as Smishing, Vishing, Payment fraud, Social engineering, etc.")}</p>
              </div>
              <div className='img-container-28'>
                <img width="532" className='mob-img' src="/images/behavior-change.png" alt="" />
              </div>
            </div>
          </div>

          <div className='br30 mob-br20 gradient3 content-box mb80 mob-mb-40 padding-74-100 mob-padding-46-43'>
            <div className='row space-between mob-column'>
              <div className='column one-of-2 mob-fullwidth'>
                <h3 className='mb36 mob-font26 mb30'>{t("Increase engagement with gamification")}</h3>
                <p className='mb40 font18-400 mob-font14 mob-mb-20'>{t("Cyberlift rewards users with stars and levels to enhance engagement. You can customize it to integrate with your existing rewards and loyalty programs, such as cashback, points, or discounts.")}</p>
                <Spacer />

              </div>
              <div className='img-container-8'>
                <img width="505" className='mob-img mob-hide' src="/images/gamification-platform4-c.png" alt="" />
                <img width="375" className='mob-img hide mob-show' src="/images/gamification-platform4-mob.png" alt="" />
              </div>
            </div>
          </div>


          <div className='br30 mob-br20 dark-box-gradient mb40 mob-mb-20 padding-74-100 mob-padding-45-41 header-to-dark'>
            <div className='row space-between mob-column-reverse'>
              <div className='one-of-2 mob-fullwidth img-container-19'>
                <Intersector>
                  <div style={{ width: 394, height: 320, position: 'relative' }} className='mob-hide'>
                    <div className='sat-card sat-card-purple sat-animate-1'>
                      <img width="147" className='mob-img mob-hide' src="/images/sat3.png" alt="" />
                    </div>
                    <div className='sat-card sat-card-blue sat-animate-2'>
                      <img width="202" className='mob-img mob-hide' src="/images/sat2.png" alt="" />
                    </div>
                    <div className='sat-card sat-card-pink sat-animate-3'>
                      <img width="147" className='mob-img mob-hide' src="/images/sat1.png" alt="" />
                    </div>

                  </div>

                </Intersector>

                <img className='mob-img hide mob-show' src="/images/security-awarennes-cards-mobile.png" alt="" />
              </div>
              <div className='mob-mb-20 one-of-2 mob-fullwidth'>
                <h3 style={{ maxWidth: '419px' }} className='mb36 mob-font26 mob-font600 mb30'>{t("Train consumers with microlearning framework ")}</h3>
                <p className='mb58 font18-400 mob-font14 mob-mb-20'>{t("Cyberlift delivers short, bite-sized micro-trainings that reinforce good behavior and transform risks into engaging learning processes. Our method educates users through brief cards and quizzes.")}</p>

              </div>
            </div>
          </div>

          <div className='br30 pb40' style={{ background: 'linear-gradient(180deg, #19191D 0%, rgba(20, 20, 23, 0) 122.68%)' }}>
            <div className='column column-center padding-90-100 mob-padding-46-43'>
              <p className='font42 mob-font26 mb30 text-center'>{t("Orchestrate consumers vulnerabilities data with your Anti-fraud")}</p>
              <p className='font24 mob-font14 mob-mb-20 text-center' style={{ maxWidth: 760 }}>{t("Cyberlift leverages real-time analytics to help you quickly understand the impact of the Cyberlift platform, enabling you to promptly reduce risk. Automate and enhance your anti-fraud operations by managing data through the Cyberlift API. Transform your end consumers into a human firewall today.")}</p>
              <div className='img-container-16'>
                <img className='mob-img' src="/images/world-shield.png" width="100%" alt="" />

              </div>
            </div>
          </div>

          <div className='row row-center space-between mob-column padding-110 mob-padding-30-20'>
            <div className='column'>
              <p className='font46 mb20 mob-font-26 mob-text-center'>{t("Integration")}</p>
              <p className='font24 mob-font-14 mob-mb-40 mob-text-center' style={{ maxWidth: 480 }}>{t("Easy integration is available for both small businesses and large enterprises, leveraging advanced API integration and a sophisticated cloud infrastructure. Our system operates without collecting any personal information about your customers, utilizing anonymized identifiers for all processes.")}</p>
            </div>

            <img className='mob-img' src="/images/reg-updates.png" width={'530'} alt="" />

          </div>



        </div>
      </section>

      <section className='dark pb150 mob-pb-100'>



        <div className='container1300'>

          <div className={`br30 row space-between mob-column gradient4`}>
            <div className='column column-start mob-text-center mob-column-center promo-block'>
              <p className='mb36 sato36 font500 mob-font-24'>{t('Get started')}</p>
              <p style={{ color: '#CECEFF' }} className='mb40 mob-font14'>{t("Implement Cyberlift and Save on your potential losses.")}</p>
              <a href='#form' style={{ textDecoration: 'none' }} className={'gradient-button'}>{t('Get started')}
                <svg style={{ marginLeft: '20px' }} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967L8.75736 0.696699C8.46447 0.403806 7.98959 0.403806 7.6967 0.696699C7.40381 0.989593 7.40381 1.46447 7.6967 1.75736L11.9393 6L7.6967 10.2426C7.40381 10.5355 7.40381 11.0104 7.6967 11.3033C7.98959 11.5962 8.46447 11.5962 8.75736 11.3033L13.5303 6.53033ZM1 6.75L13 6.75V5.25L1 5.25L1 6.75Z" fill="#E3E3FF" />
                </svg>
              </a>

              <div className="hide mob-show" style={{ height: 30 }}></div>

            </div>
            <div className="get-started-home-orig"></div>

          </div>
        </div >


      </section>
      <Divider style={{ margin: 0, background: '#2F2F2F' }}></Divider >
      <Footer theme={THEMES.dark} />
    </>
  )
}
